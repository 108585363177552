// {*} Stretch
%stretch {
	&[stretch-x] {
		@include --stretch-x-layout;
	}
	&[stretch-y] {
		@include --stretch-y-layout;
	}
	&[stretch] {
		@include --stretch-layout;
	}
}

%text-align {
	&[text-align="left"] {
		text-align: left;
	}
	&[text-align="right"] {
		text-align: right;
	}
	&[text-align="center"] {
		text-align: center;
	}
}

// {*} Sizes
%sizes {
	@include --attributes-from-map($sizing__map, $property: "font-size", $this: true, $name: "size");
}

// {*} Spacing
%spacing {
	@include --spacing-selectors-from-map($this: true, $type: "class", $separator: "\\:");
	@include --spacing-selectors-from-map($this: true, $type: "class", $separator: "\\:", $children: true);
}

// {*} Colors
%colors {
	@include --attributes-from-map($palette__map, $property: "color", $this: true, $name: "color");
}

// {*} Themes
%themes {
	@include --theming-attributes($this: true, $append: ":not([outline])", $outline: false);
	@include --theming-attributes($this: true, $append: "[outline]", $outline: true);
}
