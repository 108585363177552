/** Viewport
 ------------- */
@mixin --viewport-x-layout {
	width: 100vw;
}
@mixin --viewport-y-layout {
	height: 100vh;
}
@mixin --viewport-layout {
	@include --viewport-x-layout;
	@include --viewport-y-layout;
}

/** Stretch
 ------------- */
@mixin --stretch-x-layout {
	width: 100%;
}
@mixin --stretch-y-layout {
	height: 100%;
}
@mixin --stretch-layout {
	@include --stretch-x-layout;
	@include --stretch-y-layout;
}

/** Absolute
 ------------- */
@mixin --absolute-center-x-layout($top: null, $bottom: null) {
	@include --position("absolute", $top: $top, $bottom: $bottom, $left: 50%);
	transform: translateX(-50%);
}
@mixin --absolute-center-y-layout($left: null, $right: null) {
	@include --position("absolute", $top: 50%, $left: $left, $right: $right);
	transform: translateY(-50%);
}
@mixin --absolute-center-layout {
	@include --position("absolute", $top: 50%, $left: 50%);
	transform: translate(-50%, -50%);
}

@mixin --fixed-center-x-layout($top: auto, $bottom: auto) {
	@include --position("fixed", $top: $top, $bottom: $bottom, $left: 50%);
	transform: translateX(-50%);
}
@mixin --fixed-center-y-layout($left: auto, $right: auto) {
	@include --position("fixed", $top: 50%, $left: $left, $right: $right);
	transform: translateY(-50%);
}
@mixin --fixed-center-layout {
	@include --position("fixed", $top: 50%, $left: 50%);
	transform: translate(-50%, -50%);
}

/** Margin
 ------------- */
@mixin --margin-horizontal-center($width) {
	@include --spacing("margin", "horizontal", auto);
	width: $width;
}

/** Containers
 --------------- */
@mixin --overlay-layout($color) {
	position: relative;

	&::after {
		content: "";
		@include --position("absolute", $top: 0, $left: 0);
		@include --stretch-layout;
		background-color: $color;
		border-radius: inherit;
	}
}
