@import "_extends";

* {
	@extend %sizes;
	@extend %spacing;
	@extend %stretch;
	@extend %text-align;
	@extend %colors;
}

div,
span,
button {
	@extend %themes;
}

image,
video {
	&[fit-cover] {
		@include --stretch-layout;
		object-fit: cover;
	}
	&[fit-fill] {
		@include --stretch-layout;
		object-fit: fill;
	}
}
