@import "_extends";

button {
	@include --spacing("padding", "vertical", "x1");
	@include --spacing("padding", "horizontal", "1x");
	border-radius: -radius("1z");

	// {*} Extends
	@extend %states;

	// {*} Animations
	&:not([disabled]) {
		&[animation*="raised"] {
			@include --raised-animation;
		}
		&[animation*="hovered"] {
			@include --themes-animation($hover: true);
		}
		&[animation*="active"] {
			@include --themes-animation($active: true);
		}
	}
}
