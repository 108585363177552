*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	@include --media-query("desktop-large", "up") {
		font-size: 65.625%; // 1rem = 10.5px
	}
	@include --media-query("desktop-small") {
		font-size: 62.5%; // 16px is the default font-size in browsers. 62.5% of 16px is 10px, which makes 1rem = 10px.
	}
	@include --media-query("tablet-landscape") {
		font-size: 56.25%; // 1rem = 9px
	}
	@include --media-query("tablet-portrait") {
		font-size: 53.12%; // 1rem = 8.5px;
	}
	@include --media-query("mobile") {
		font-size: 50%; // 1rem = 8px;
	}
}

body {
	box-sizing: border-box;
	height: 100%; // force the body tag to fill the height of the window (to stick the footer at the bottom of the page)
	text-align: left; // set an explicit initial text-align value so that we can later use the `inherit` value on tags like `<th>`
	font-family: -font-family("base");
	font-size: -font-size("1x");
	font-weight: -font-weight("regular");
	line-height: -line-height("1z");
	color: -color("font", "dark");
	background-color: -color("background", "light");
}

p {
	@include --spacing("margin", "bottom", "1x", $last: false);
	line-height: -line-height("2z");
}

a {
	&:link,
	&:visited {
		display: inline-block;
		text-decoration: none;
		color: inherit;
		fill: inherit;
	}
}

button {
	display: inline-block;
	background: none;
	border: none;
	border-radius: 0; // Remove the default `border-radius` that macOS Chrome adds.
	user-select: none;
	cursor: pointer;
}

button,
input,
textarea,
select,
video {
	@include --media-query("desktop-small", "up") {
		&:focus {
			outline: none;
		}
	}
}

button,
input,
textarea,
select,
optgroup {
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
}

textarea {
	resize: none;
}

img {
	width: 100%;
	height: auto;
}

img,
svg,
span {
	/*
   * By default, an img/svg/span is rendered inline, like a letter.
   * It sits on the same line that a, b, c and d sit on. There is space below that line for the descenders you find on letters like f, j, p and q.
   * This may result an extra space bellow the img/svg/span when it is being displayed as an inline inside a block
   * ---
   * @fix:  adjust the vertical-align to position it elsewhere (e.g. bottom) or change the display so it isn't inline.
   */
	vertical-align: bottom;
}

ol,
ul {
	padding-left: 40px;
	margin-bottom: 1rem;
}

b,
strong {
	font-weight: -font-weight("bold"); // Add the correct font weight in Chrome, Edge, and Safari
}

table {
	width: 100%;
	border-collapse: collapse; // Prevent double borders

	th {
		font-weight: -font-weight("bold");
	}
	th,
	td {
		@include --spacing("padding", "horizontal", "x3");
		@include --spacing("padding", "vertical", 0.4rem);
		border: -thick("1z") solid -palette("black");
		text-align: inherit;
	}
}
