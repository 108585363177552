// ----- Container ----- //

.u-page {
	&[padding] {
		@include --spacing("padding", "horizontal", "page");
		@include --spacing("padding", "vertical", "page");
	}
	&[padding="h"] {
		@include --spacing("padding", "horizontal", "page");
	}
	&[padding="v"] {
		@include --spacing("padding", "vertical", "page");
	}
	&[padding="t"] {
		@include --spacing("padding", "top", "page");
	}
	&[padding="b"] {
		@include --spacing("padding", "bottom", "page");
	}

	&[margin] {
		@include --spacing("margin", "horizontal", "page");
		@include --spacing("margin", "vertical", "page");
	}
	&[margin="h"] {
		@include --spacing("margin", "horizontal", "page");
	}
	&[margin="v"] {
		@include --spacing("margin", "vertical", "page");
	}
	&[margin="t"] {
		@include --spacing("margin", "top", "page");
	}
	&[margin="b"] {
		@include --spacing("margin", "bottom", "page");
	}
}

.u-segment {

}

// ----- Display ----- //

.u-hidden {
	display: none;
}
.u-inline {
	display: inline;
}
.u-block {
	display: block;
}
.u-inline-block {
	display: inline-block;
}

.u-invisible {
	visibility: hidden;
}
.u-visible {
	visibility: visible;
}

// {*} Flex
.u-flex {
	@include --flex-container;
}
.u-inline-flex {
	@include --inline-flex-container;
}

.u-flex-row {
	@include --flex-layout($direction: "row");
}
.u-flex-column {
	@include --flex-layout($direction: "column");
}

// ----- Typography ----- //

// {*} Font
.u-font-bold {
	font-weight: -font-weight("bold");
}
.u-font-italic {
	font-style: italic;
}

// {*} Text
.u-text-underlined {
	text-decoration: underline;
}
.u-text-superscript {
	vertical-align: super;
	font-size: smaller;
}
.u-text-subscript {
	vertical-align: sub;
	font-size: smaller;
}

// {*} Titling
.u-headline-primary {
	font-family: -font-family("primary");
	font-weight: -font-weight("bold");
	font-size: -font-size("6x");
	letter-spacing: -letter--spacing("2z");
	margin-top: -spacing("headlinePre");
	margin-bottom: -spacing("headline");
}
.u-headline-secondary {
	font-family: -font-family("secondary");
	font-weight: -font-weight("light");
	font-size: -font-size("5x");
	margin-top: -spacing("headlinePre");
	margin-bottom: -spacing("headline");
}
.u-headline-tertiary {
	font-family: -font-family("tertiary");
	font-weight: -font-weight("medium");
	font-size: -font-size("4x");
	margin-top: -spacing("headlinePre");
	margin-bottom: -spacing("headline");
}

.u-heading {
	font-family: -font-family("heading");
	font-weight: -font-weight("bold");
	font-size: -font-size("3x");
	margin-top: -spacing("headingPre");
	margin-bottom: -spacing("heading");
}
.u-subheading {
	font-family: -font-family("heading");
	font-weight: -font-weight("bold");
	font-size: -font-size("1x");
	margin-top: -spacing("headingPre");
	margin-bottom: -spacing("heading");
}

.u-caption-box {
	text-align: center;
	margin-bottom: -spacing("5x");
}

// – divider
.u-headline-divider {
	@include --spacing("margin", "vertical", "1x");
	position: relative;

	&::after {
		content: "";
		@include --absolute-center-x-layout;
		height: -thick("2z");
		width: 10rem;
		background: -palette("primary");
	}
}

// ----- Assets ----- //

// {*} Image
.u-img-responsive {
	@include --scaling(100%, 100%);
}

// {*} Currency
%currency {
	position: relative;
	&:before {
		content: "€";
		position: relative;
		left: 0;
	}
}
.EUR %currency:before {
	content: "€";
}
.USD %currency:before {
	content: "$";
}
.GBP %currency:before {
	content: "£";
}
.MAD %currency:before {
	content: "MAD";
}
