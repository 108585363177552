[nav-menu] {
	display: inline-block;
	color: -color("link", "inactive");
	fill: -color("link", "inactive");

	> * {
		@include --spacing("margin", "horizontal", "2x", $last: false);

		// ↓↓↓ Animations
		@include --underlined-animation;
	}
}
