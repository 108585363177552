/**
 * Flexbox Container
 * ---
 * • set up a container as flex displayed

 * @param 'display: flex' causes an element to generate a block-level flex container box
 * @param 'display: inline-flex' causes an element to generate a inline-level flex container box
 */
@mixin --flex-container {
	display: flex;
}
@mixin --inline-flex-container {
	display: inline-flex;
}

/**
 * Flex Layout
 * ---
 * • set up a flex container, define its direction, and align its content according to a given orientation
 *
 * @param {'row'|'column'} $direction
 * @param {'main'|'cross'} $axis
 * @param {'flex-start'|'flex-end'|'center'|'baseline'|'stretch'|'space-between'|'space-around'|'space-evenly'} $alignment
 * @param {'nowrap'|'wrap'} $wrap
 * @param {'block'|'inline'} $display
 */

/*
 	* @todo @mixin --flex-layout($direction: "row", $axis-alignment: ("main": "center"), $wrap: "nowrap", $display: "block")
	* @param {('main'|'cross': 'flex-start'|'flex-end'|'center'|'baseline'|'stretch'|'space-between'|'space-around'|'space-evenly')} $axis-alignment
	*/
@mixin --flex-layout($direction: "row", $axis: null, $alignment: null, $wrap: "nowrap", $display: "block") {
	$validator: -in-list-check($direction, ("row", "column"));
	$validator: -in-list-check($axis, ("main", "cross", null));
	$validator: -in-list-check($alignment, ("flex-start", "flex-end", "center", "baseline", "stretch", "space-between", "space-around", "space-evenly", null));
	$validator: -in-list-check($wrap, ("nowrap", "wrap"));
	$validator: -in-list-check($display, ("block", "inline"));

	@if ($display == "block") {
		@include --flex-container;
	} @else if ($display == "inline") {
		@include --inline-flex-container;
	}

	flex-direction: #{$direction};
	flex-wrap: #{$wrap};

	@if ($axis != null) and ($alignment != null) {
		@include --flex-align($axis, $alignment);
	}
}

/**
 * Flex Align
 * ---
 * • align flex contents along a given axis
 *
 * @param {'row'|'column'} $direction
 * @param {'main'|'cross'} $axis
 * @param {'flex-start'|'flex-end'|'center'|'baseline'|'stretch'|'space-between'|'space-around'|'space-evenly'} $value
 */
@mixin --flex-align($axis, $value) {
	$validator: -in-list-check($axis, ("main", "cross"));

	@if ($axis == "cross") {
		align-items: #{$value};
	} @else if ($axis == "main") {
		justify-content: #{$value};
	}
}

/**
 * Flexbox align self
 * ---
 * • the `align-self` property aligns a flex item along the cross axis.
 * • It makes possible to override the `align-items` value for specific flex items.
 *
 * @param {'flex-start'|'flex-end'|'center'|'baseline'|'stretch'} $alignment
 */

@mixin --flex-align-self($alignment: "stretch") {
	$validator: -in-list-check($alignment, ("flex-start", "flex-end", "center", "baseline", "stretch"));

	align-self: #{$alignment};
}

/**
 * Flexbox align content
 * ---
 * • while "align-items" is used to align flex items within a row in the "cross axis"
 * • the "align-content" property is used to align flex rows in the "cross axis"
 * • @note: this property only applies when there is more than one row of flex items in a flex container.
 *
 * @param {'flex-start'|'flex-end'|'center'|'stretch'|'space-between'|'space-around'|'space-evenly'} $value
 */

@mixin --flex-align-content($value: "stretch") {
	$validator: -in-list-check($alignment, ("flex-start", "flex-end", "center", "baseline", "stretch"));

	align-content: #{$value};
}
