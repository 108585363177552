// {*} States
%states {
	&[disabled] {
		@include --overlay-layout(-palette("gloomy", $opacity: "2z"));
		box-shadow: none;
		cursor: not-allowed;

		a {
			pointer-events: none;
		}
	}
}
