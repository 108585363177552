// {*} Karla
@font-face {
	font-family: Karla;
	src: url("/assets/fonts/Karla/Karla-Regular.ttf") format("truetype");
	font-weight: -font-weight("regular");
	font-style: normal;
}
@font-face {
	font-family: Karla;
	src: url("/assets/fonts/Karla/Karla-Bold.ttf") format("truetype");
	font-weight: -font-weight("bold");
	font-style: normal;
}

// {*} YatraOne
@font-face {
	font-family: YatraOne;
	src: url("/assets/fonts/YatraOne/YatraOne-Regular.ttf") format("truetype");
	font-weight: -font-weight("regular");
	font-style: normal;
}

// {*} JosefinSans
@font-face {
	font-family: JosefinSans;
	src: url("/assets/fonts/JosefinSans/JosefinSans-Light.ttf") format("truetype");
	font-weight: -font-weight("light");
	font-style: normal;
}

// {*} Quicksand
@font-face {
	font-family: Quicksand;
	src: url("/assets/fonts/Quicksand/Quicksand-Medium.ttf") format("truetype");
	font-weight: -font-weight("medium");
	font-style: normal;
}
