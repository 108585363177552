@charset "UTF-8";
/**
 * • Does `$map` has all `$keys` at first level
 * @param {map} $map
 * @param {arglist} $keys
 * @returns {boolean}
 */
/**
 * • Does `$map` has all `$keys` nested with each others
 * @param {map} $map
 * @param {arglist} $keys
 * @returns {boolean}
 */
/**
 * • Get a nested value within `$map`
 * @returns {number|string}
 */
/**
 * • Get a value within `$map`
 * @returns {number|string}
 */
/**
 * • Merge a collection of maps
 * @returns {map}
 */
/**
 * • Generate an incremental map
 * @returns {map}
 */
/**
 * • Generate a "zen" incremental map
 * "zen" is a unit for non-measurement properties
 *  ↪ letter-spacing & line-height
 *  ↪ border (thick & radius)
 *  ↪ opacity
 *
 * @returns {map}
 */
/**
 * • Generate a "xen" incremental map
 * "xen" is a unit for measurement properties:
 *  ↪ sizing
 *  ↪ scaling
 *  ↪ spacing
 *
 * @returns {map}
 */
/**
 * • is `$value` contained in `$list`
 * @returns {boolean}
 */
/**
 * • Return a string by concatenating `$list` items
 * @returns string
 */
/**
 * • Return a list by splitting  `$string`
 * @returns {list}
 */
/**
 * @param {'lighten'|'darken'} $shade
 */
/**
 * @param {boolean} $this – (& symbol) attach generated selector to its parent selector?
 * @param {'class'|'attribute'} $type – type of selector to generate
 * @param {string} $name – name of selector to generate
 * @param {string} $prefix – prefix to add to the selector
 * @param {string} $suffix – suffix to add to the selector
 * @param {string} $append – subselector to append to the css selector (e.g. '> *' to target children)
 */
/**
 * Return an error message depending on `$error_type`
 *
 * @return {string}
 */
/**
 * Check whether `$map` has all `$keys` at first level
 *
 * @returns {boolean}
 */
/**
 * Check whether `$map` has all `$keys` nested with each others
 *
 * @returns {boolean}
 */
/**
 * Return an error message depending on `$error_type`
 *
 * @returns string
 */
/**
 * @todo: must be a mixin, because function
 * Check whether `$value` is within `$allowed-values`.
 *
 * @returns {boolean}
 */
/**
 * @param {map} $map – the map from which the selectors will be generated.
 * @param {string} $property – the css property invoked when a generated selector is used
 * @param {boolean} $this – (& symbol) attach generated selectors to teir parent selector?
 * @param {'class'|'attribute'} $type– type of selectors to generate
 * @param {string} $name – name of selectors to generate
 * @param {string} $prefix – prefix to add to the selector
 * @param {string} $suffix – suffix to add to the selector
 * @param {string} $append – subselector to append to the css selector (e.g. '> *' to target children)
 * @param {boolean} $sides – should property sides be added?
 * @param {boolean} $exclLast – should exclude last selector instance?
 * @param {boolean} $children – should value apply to children?
 */
/**
 * @param {boolean} $this – (& symbol) attach generated selectors to teir parent selector?
 * @param {string} $name – name of selectors to generate
 */
/**
 * @param {boolean} $this – (& symbol) attach generated selectors to teir parent selector?
 * @param {string} $name – name of selectors to generate
 * @param {string} $append – string to append to the css selector (e.g. '> *' to target children)
 */
/**
 * @param {'margin'|'padding'} $property – the marge property invoked when a generated selector is used
 * @param {boolean} $this – (& symbol) attach generated selectors to their parent selector?
 * @param {'class'|'attribute'} $type – type of selectors to generate
 * @param {string} $name – name of selectors to generate
 * @param {string} $prefix – prefix to add to the selector
 * @param {string} $suffix – suffix to add to the selector
 * @param {string} $sides – should property sides be added?
 */
/**
 * @param {value|list} $spacing – spacing value or string or a list of two values (horizontal, vertical)
 * @param {boolean} – (& symbol) attach generated selectors to teir parent selector?
 * @param {'class'|'attribute'} $type – type of selectors to generate
 * @param {string} $prefix – prefix to add to the selector
 * @param {string} $suffix – suffix to add to the selector
 */
/**
 * Flexbox Container
 * ---
 * • set up a container as flex displayed

 * @param 'display: flex' causes an element to generate a block-level flex container box
 * @param 'display: inline-flex' causes an element to generate a inline-level flex container box
 */
/**
 * Flex Layout
 * ---
 * • set up a flex container, define its direction, and align its content according to a given orientation
 *
 * @param {'row'|'column'} $direction
 * @param {'main'|'cross'} $axis
 * @param {'flex-start'|'flex-end'|'center'|'baseline'|'stretch'|'space-between'|'space-around'|'space-evenly'} $alignment
 * @param {'nowrap'|'wrap'} $wrap
 * @param {'block'|'inline'} $display
 */
/*
 	* @todo @mixin --flex-layout($direction: "row", $axis-alignment: ("main": "center"), $wrap: "nowrap", $display: "block")
	* @param {('main'|'cross': 'flex-start'|'flex-end'|'center'|'baseline'|'stretch'|'space-between'|'space-around'|'space-evenly')} $axis-alignment
	*/
/**
 * Flex Align
 * ---
 * • align flex contents along a given axis
 *
 * @param {'row'|'column'} $direction
 * @param {'main'|'cross'} $axis
 * @param {'flex-start'|'flex-end'|'center'|'baseline'|'stretch'|'space-between'|'space-around'|'space-evenly'} $value
 */
/**
 * Flexbox align self
 * ---
 * • the `align-self` property aligns a flex item along the cross axis.
 * • It makes possible to override the `align-items` value for specific flex items.
 *
 * @param {'flex-start'|'flex-end'|'center'|'baseline'|'stretch'} $alignment
 */
/**
 * Flexbox align content
 * ---
 * • while "align-items" is used to align flex items within a row in the "cross axis"
 * • the "align-content" property is used to align flex rows in the "cross axis"
 * • @note: this property only applies when there is more than one row of flex items in a flex container.
 *
 * @param {'flex-start'|'flex-end'|'center'|'stretch'|'space-between'|'space-around'|'space-evenly'} $value
 */
/**
 * Property                                      | Description                                              | Default
 * ----------------------------------------------|----------------------------------------------------------|------------------
 * `columns-count`                               | The number of columns per row.                           | 1
 * `column-height`                               | The height of a column                                   | auto
 * `gutter`                                      | The space between two rows & two columns                 | 0px
 * `rows-gutter`                                 | The space between two rows                               | 0px
 * `columns-gutter`                              | The space between two columns                            | 0px
 */
/** Viewport
 ------------- */
/** Stretch
 ------------- */
/** Absolute
 ------------- */
/** Margin
 ------------- */
/** Containers
 --------------- */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

@media (min-width: 75em) {
  html {
    font-size: 65.625%;
  }
}
@media (min-width: 62em) and (max-width: 75em) {
  html {
    font-size: 62.5%;
  }
}
@media (min-width: 48em) and (max-width: 62em) {
  html {
    font-size: 56.25%;
  }
}
@media (min-width: 36em) and (max-width: 48em) {
  html {
    font-size: 53.12%;
  }
}
@media (min-width: 0) and (max-width: 36em) {
  html {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
  height: 100%;
  text-align: left;
  font-family: Karla, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.25;
  color: #3f4a58;
  background-color: white;
}

p {
  line-height: 1.5;
}
p:not(:last-child) {
  margin-bottom: 1rem;
}

a:link, a:visited {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  fill: inherit;
}

button {
  display: inline-block;
  background: none;
  border: none;
  border-radius: 0;
  user-select: none;
  cursor: pointer;
}

@media (min-width: 62em) {
  button:focus,
input:focus,
textarea:focus,
select:focus,
video:focus {
    outline: none;
  }
}

button,
input,
textarea,
select,
optgroup {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

textarea {
  resize: none;
}

img {
  width: 100%;
  height: auto;
}

img,
svg,
span {
  /*
    * By default, an img/svg/span is rendered inline, like a letter.
    * It sits on the same line that a, b, c and d sit on. There is space below that line for the descenders you find on letters like f, j, p and q.
    * This may result an extra space bellow the img/svg/span when it is being displayed as an inline inside a block
    * ---
    * @fix:  adjust the vertical-align to position it elsewhere (e.g. bottom) or change the display so it isn't inline.
    */
  vertical-align: bottom;
}

ol,
ul {
  padding-left: 40px;
  margin-bottom: 1rem;
}

b,
strong {
  font-weight: 600;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table th {
  font-weight: 600;
}
table th,
table td {
  border: 1px solid black;
  text-align: inherit;
}
table th,
table td {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
table th,
table td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

@font-face {
  font-family: Karla;
  src: url("/assets/fonts/Karla/Karla-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Karla;
  src: url("/assets/fonts/Karla/Karla-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: YatraOne;
  src: url("/assets/fonts/YatraOne/YatraOne-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: JosefinSans;
  src: url("/assets/fonts/JosefinSans/JosefinSans-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Quicksand;
  src: url("/assets/fonts/Quicksand/Quicksand-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
.u-page[padding] {
  padding-right: 5rem;
  padding-left: 5rem;
}
.u-page[padding] {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.u-page[padding=h] {
  padding-right: 5rem;
  padding-left: 5rem;
}
.u-page[padding=v] {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.u-page[padding=t] {
  padding-top: 5rem;
}
.u-page[padding=b] {
  padding-bottom: 5rem;
}
.u-page[margin] {
  margin-right: 5rem;
  margin-left: 5rem;
}
.u-page[margin] {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.u-page[margin=h] {
  margin-right: 5rem;
  margin-left: 5rem;
}
.u-page[margin=v] {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.u-page[margin=t] {
  margin-top: 5rem;
}
.u-page[margin=b] {
  margin-bottom: 5rem;
}

.u-hidden {
  display: none;
}

.u-inline {
  display: inline;
}

.u-block {
  display: block;
}

.u-inline-block {
  display: inline-block;
}

.u-invisible {
  visibility: hidden;
}

.u-visible {
  visibility: visible;
}

.u-flex {
  display: flex;
}

.u-inline-flex {
  display: inline-flex;
}

.u-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.u-flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.u-font-bold {
  font-weight: 600;
}

.u-font-italic {
  font-style: italic;
}

.u-text-underlined {
  text-decoration: underline;
}

.u-text-superscript {
  vertical-align: super;
  font-size: smaller;
}

.u-text-subscript {
  vertical-align: sub;
  font-size: smaller;
}

.u-headline-primary {
  font-family: YatraOne, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  font-weight: 600;
  font-size: 2.6rem;
  letter-spacing: 2px;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}

.u-headline-secondary {
  font-family: JosefinSans, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  font-weight: 200;
  font-size: 2.4rem;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}

.u-headline-tertiary {
  font-family: Quicksand, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}

.u-heading {
  font-family: Karla, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  font-weight: 600;
  font-size: 2rem;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

.u-subheading {
  font-family: Karla, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

.u-caption-box {
  text-align: center;
  margin-bottom: 5rem;
}

.u-headline-divider {
  position: relative;
}
.u-headline-divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.u-headline-divider::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 10rem;
  background: #2f7aff;
}

.u-img-responsive {
  width: 100%;
  height: 100%;
}

@media (min-width: 0) and (max-width: 36em) {
  [hiddenOnMobile] {
    display: none;
  }
}
@media (min-width: 36em) and (max-width: 48em) {
  [hiddenOnTabletPortrait] {
    display: none;
  }
}
@media (min-width: 48em) and (max-width: 62em) {
  [hiddenOnTabletLandscape] {
    display: none;
  }
}
@media (min-width: 62em) and (max-width: 75em) {
  [hiddenOnDesktopSmall] {
    display: none;
  }
}
@media (min-width: 75em) {
  [hiddenOnDesktopLarge] {
    display: none;
  }
}
@media (max-width: 36em) {
  [hiddenOnSmallScreens] {
    display: none;
  }
}
@media (min-width: 36em) and (max-width: 62em) {
  [hiddenOnMediumScreens] {
    display: none;
  }
}
@media (min-width: 62em) {
  [hiddenOnLargeScreens] {
    display: none;
  }
}
[stretch-x] {
  width: 100%;
}
[stretch-y] {
  height: 100%;
}
[stretch] {
  width: 100%;
  height: 100%;
}

[text-align=left] {
  text-align: left;
}
[text-align=right] {
  text-align: right;
}
[text-align=center] {
  text-align: center;
}

[size=x1] {
  font-size: 1.4rem;
}
[size=x2] {
  font-size: 1.2rem;
}
[size=x3] {
  font-size: 1rem;
}
[size="1x"] {
  font-size: 1.6rem;
}
[size="2x"] {
  font-size: 1.8rem;
}
[size="3x"] {
  font-size: 2rem;
}
[size="4x"] {
  font-size: 2.2rem;
}
[size="5x"] {
  font-size: 2.4rem;
}
[size="6x"] {
  font-size: 2.6rem;
}
[size="7x"] {
  font-size: 2.8rem;
}
[size="8x"] {
  font-size: 3rem;
}
[size="9x"] {
  font-size: 3.2rem;
}
[size="10x"] {
  font-size: 3.4rem;
}
[size="11x"] {
  font-size: 3.6rem;
}
[size="12x"] {
  font-size: 3.8rem;
}

.m\:x1 {
  margin: 0.75rem;
}
.m\:v\:x1 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.m\:h\:x1 {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.m\:t\:x1 {
  margin-top: 0.75rem;
}
.m\:b\:x1 {
  margin-bottom: 0.75rem;
}
.m\:r\:x1 {
  margin-right: 0.75rem;
}
.m\:l\:x1 {
  margin-left: 0.75rem;
}
.m\:x2 {
  margin: 0.5rem;
}
.m\:v\:x2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.m\:h\:x2 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.m\:t\:x2 {
  margin-top: 0.5rem;
}
.m\:b\:x2 {
  margin-bottom: 0.5rem;
}
.m\:r\:x2 {
  margin-right: 0.5rem;
}
.m\:l\:x2 {
  margin-left: 0.5rem;
}
.m\:x3 {
  margin: 0.25rem;
}
.m\:v\:x3 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.m\:h\:x3 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
.m\:t\:x3 {
  margin-top: 0.25rem;
}
.m\:b\:x3 {
  margin-bottom: 0.25rem;
}
.m\:r\:x3 {
  margin-right: 0.25rem;
}
.m\:l\:x3 {
  margin-left: 0.25rem;
}
.m\:1x {
  margin: 1rem;
}
.m\:v\:1x {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m\:h\:1x {
  margin-right: 1rem;
  margin-left: 1rem;
}
.m\:t\:1x {
  margin-top: 1rem;
}
.m\:b\:1x {
  margin-bottom: 1rem;
}
.m\:r\:1x {
  margin-right: 1rem;
}
.m\:l\:1x {
  margin-left: 1rem;
}
.m\:2x {
  margin: 2rem;
}
.m\:v\:2x {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.m\:h\:2x {
  margin-right: 2rem;
  margin-left: 2rem;
}
.m\:t\:2x {
  margin-top: 2rem;
}
.m\:b\:2x {
  margin-bottom: 2rem;
}
.m\:r\:2x {
  margin-right: 2rem;
}
.m\:l\:2x {
  margin-left: 2rem;
}
.m\:3x {
  margin: 3rem;
}
.m\:v\:3x {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.m\:h\:3x {
  margin-right: 3rem;
  margin-left: 3rem;
}
.m\:t\:3x {
  margin-top: 3rem;
}
.m\:b\:3x {
  margin-bottom: 3rem;
}
.m\:r\:3x {
  margin-right: 3rem;
}
.m\:l\:3x {
  margin-left: 3rem;
}
.m\:4x {
  margin: 4rem;
}
.m\:v\:4x {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.m\:h\:4x {
  margin-right: 4rem;
  margin-left: 4rem;
}
.m\:t\:4x {
  margin-top: 4rem;
}
.m\:b\:4x {
  margin-bottom: 4rem;
}
.m\:r\:4x {
  margin-right: 4rem;
}
.m\:l\:4x {
  margin-left: 4rem;
}
.m\:5x {
  margin: 5rem;
}
.m\:v\:5x {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.m\:h\:5x {
  margin-right: 5rem;
  margin-left: 5rem;
}
.m\:t\:5x {
  margin-top: 5rem;
}
.m\:b\:5x {
  margin-bottom: 5rem;
}
.m\:r\:5x {
  margin-right: 5rem;
}
.m\:l\:5x {
  margin-left: 5rem;
}
.m\:6x {
  margin: 6rem;
}
.m\:v\:6x {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.m\:h\:6x {
  margin-right: 6rem;
  margin-left: 6rem;
}
.m\:t\:6x {
  margin-top: 6rem;
}
.m\:b\:6x {
  margin-bottom: 6rem;
}
.m\:r\:6x {
  margin-right: 6rem;
}
.m\:l\:6x {
  margin-left: 6rem;
}
.m\:7x {
  margin: 7rem;
}
.m\:v\:7x {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.m\:h\:7x {
  margin-right: 7rem;
  margin-left: 7rem;
}
.m\:t\:7x {
  margin-top: 7rem;
}
.m\:b\:7x {
  margin-bottom: 7rem;
}
.m\:r\:7x {
  margin-right: 7rem;
}
.m\:l\:7x {
  margin-left: 7rem;
}
.m\:8x {
  margin: 8rem;
}
.m\:v\:8x {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.m\:h\:8x {
  margin-right: 8rem;
  margin-left: 8rem;
}
.m\:t\:8x {
  margin-top: 8rem;
}
.m\:b\:8x {
  margin-bottom: 8rem;
}
.m\:r\:8x {
  margin-right: 8rem;
}
.m\:l\:8x {
  margin-left: 8rem;
}
.m\:9x {
  margin: 9rem;
}
.m\:v\:9x {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.m\:h\:9x {
  margin-right: 9rem;
  margin-left: 9rem;
}
.m\:t\:9x {
  margin-top: 9rem;
}
.m\:b\:9x {
  margin-bottom: 9rem;
}
.m\:r\:9x {
  margin-right: 9rem;
}
.m\:l\:9x {
  margin-left: 9rem;
}
.m\:10x {
  margin: 10rem;
}
.m\:v\:10x {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.m\:h\:10x {
  margin-right: 10rem;
  margin-left: 10rem;
}
.m\:t\:10x {
  margin-top: 10rem;
}
.m\:b\:10x {
  margin-bottom: 10rem;
}
.m\:r\:10x {
  margin-right: 10rem;
}
.m\:l\:10x {
  margin-left: 10rem;
}
.m\:11x {
  margin: 11rem;
}
.m\:v\:11x {
  margin-top: 11rem;
  margin-bottom: 11rem;
}
.m\:h\:11x {
  margin-right: 11rem;
  margin-left: 11rem;
}
.m\:t\:11x {
  margin-top: 11rem;
}
.m\:b\:11x {
  margin-bottom: 11rem;
}
.m\:r\:11x {
  margin-right: 11rem;
}
.m\:l\:11x {
  margin-left: 11rem;
}
.m\:12x {
  margin: 12rem;
}
.m\:v\:12x {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.m\:h\:12x {
  margin-right: 12rem;
  margin-left: 12rem;
}
.m\:t\:12x {
  margin-top: 12rem;
}
.m\:b\:12x {
  margin-bottom: 12rem;
}
.m\:r\:12x {
  margin-right: 12rem;
}
.m\:l\:12x {
  margin-left: 12rem;
}
.m\:1xn {
  margin: 1.5rem;
}
.m\:v\:1xn {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.m\:h\:1xn {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.m\:t\:1xn {
  margin-top: 1.5rem;
}
.m\:b\:1xn {
  margin-bottom: 1.5rem;
}
.m\:r\:1xn {
  margin-right: 1.5rem;
}
.m\:l\:1xn {
  margin-left: 1.5rem;
}
.m\:2xn {
  margin: 2.5rem;
}
.m\:v\:2xn {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.m\:h\:2xn {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}
.m\:t\:2xn {
  margin-top: 2.5rem;
}
.m\:b\:2xn {
  margin-bottom: 2.5rem;
}
.m\:r\:2xn {
  margin-right: 2.5rem;
}
.m\:l\:2xn {
  margin-left: 2.5rem;
}
.m\:3xn {
  margin: 3.5rem;
}
.m\:v\:3xn {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.m\:h\:3xn {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}
.m\:t\:3xn {
  margin-top: 3.5rem;
}
.m\:b\:3xn {
  margin-bottom: 3.5rem;
}
.m\:r\:3xn {
  margin-right: 3.5rem;
}
.m\:l\:3xn {
  margin-left: 3.5rem;
}
.m\:4xn {
  margin: 4.5rem;
}
.m\:v\:4xn {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
.m\:h\:4xn {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}
.m\:t\:4xn {
  margin-top: 4.5rem;
}
.m\:b\:4xn {
  margin-bottom: 4.5rem;
}
.m\:r\:4xn {
  margin-right: 4.5rem;
}
.m\:l\:4xn {
  margin-left: 4.5rem;
}
.m\:5xn {
  margin: 5.5rem;
}
.m\:v\:5xn {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}
.m\:h\:5xn {
  margin-right: 5.5rem;
  margin-left: 5.5rem;
}
.m\:t\:5xn {
  margin-top: 5.5rem;
}
.m\:b\:5xn {
  margin-bottom: 5.5rem;
}
.m\:r\:5xn {
  margin-right: 5.5rem;
}
.m\:l\:5xn {
  margin-left: 5.5rem;
}
.m\:6xn {
  margin: 6.5rem;
}
.m\:v\:6xn {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}
.m\:h\:6xn {
  margin-right: 6.5rem;
  margin-left: 6.5rem;
}
.m\:t\:6xn {
  margin-top: 6.5rem;
}
.m\:b\:6xn {
  margin-bottom: 6.5rem;
}
.m\:r\:6xn {
  margin-right: 6.5rem;
}
.m\:l\:6xn {
  margin-left: 6.5rem;
}
.m\:7xn {
  margin: 7.5rem;
}
.m\:v\:7xn {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
.m\:h\:7xn {
  margin-right: 7.5rem;
  margin-left: 7.5rem;
}
.m\:t\:7xn {
  margin-top: 7.5rem;
}
.m\:b\:7xn {
  margin-bottom: 7.5rem;
}
.m\:r\:7xn {
  margin-right: 7.5rem;
}
.m\:l\:7xn {
  margin-left: 7.5rem;
}
.m\:8xn {
  margin: 8.5rem;
}
.m\:v\:8xn {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem;
}
.m\:h\:8xn {
  margin-right: 8.5rem;
  margin-left: 8.5rem;
}
.m\:t\:8xn {
  margin-top: 8.5rem;
}
.m\:b\:8xn {
  margin-bottom: 8.5rem;
}
.m\:r\:8xn {
  margin-right: 8.5rem;
}
.m\:l\:8xn {
  margin-left: 8.5rem;
}
.m\:9xn {
  margin: 9.5rem;
}
.m\:v\:9xn {
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
}
.m\:h\:9xn {
  margin-right: 9.5rem;
  margin-left: 9.5rem;
}
.m\:t\:9xn {
  margin-top: 9.5rem;
}
.m\:b\:9xn {
  margin-bottom: 9.5rem;
}
.m\:r\:9xn {
  margin-right: 9.5rem;
}
.m\:l\:9xn {
  margin-left: 9.5rem;
}
.m\:10xn {
  margin: 10.5rem;
}
.m\:v\:10xn {
  margin-top: 10.5rem;
  margin-bottom: 10.5rem;
}
.m\:h\:10xn {
  margin-right: 10.5rem;
  margin-left: 10.5rem;
}
.m\:t\:10xn {
  margin-top: 10.5rem;
}
.m\:b\:10xn {
  margin-bottom: 10.5rem;
}
.m\:r\:10xn {
  margin-right: 10.5rem;
}
.m\:l\:10xn {
  margin-left: 10.5rem;
}
.m\:11xn {
  margin: 11.5rem;
}
.m\:v\:11xn {
  margin-top: 11.5rem;
  margin-bottom: 11.5rem;
}
.m\:h\:11xn {
  margin-right: 11.5rem;
  margin-left: 11.5rem;
}
.m\:t\:11xn {
  margin-top: 11.5rem;
}
.m\:b\:11xn {
  margin-bottom: 11.5rem;
}
.m\:r\:11xn {
  margin-right: 11.5rem;
}
.m\:l\:11xn {
  margin-left: 11.5rem;
}
.m\:12xn {
  margin: 12.5rem;
}
.m\:v\:12xn {
  margin-top: 12.5rem;
  margin-bottom: 12.5rem;
}
.m\:h\:12xn {
  margin-right: 12.5rem;
  margin-left: 12.5rem;
}
.m\:t\:12xn {
  margin-top: 12.5rem;
}
.m\:b\:12xn {
  margin-bottom: 12.5rem;
}
.m\:r\:12xn {
  margin-right: 12.5rem;
}
.m\:l\:12xn {
  margin-left: 12.5rem;
}
.m\:none {
  margin: 0;
}
.m\:v\:none {
  margin-top: 0;
  margin-bottom: 0;
}
.m\:h\:none {
  margin-right: 0;
  margin-left: 0;
}
.m\:t\:none {
  margin-top: 0;
}
.m\:b\:none {
  margin-bottom: 0;
}
.m\:r\:none {
  margin-right: 0;
}
.m\:l\:none {
  margin-left: 0;
}
.m\:auto {
  margin: auto;
}
.m\:v\:auto {
  margin-top: auto;
  margin-bottom: auto;
}
.m\:h\:auto {
  margin-right: auto;
  margin-left: auto;
}
.m\:t\:auto {
  margin-top: auto;
}
.m\:b\:auto {
  margin-bottom: auto;
}
.m\:r\:auto {
  margin-right: auto;
}
.m\:l\:auto {
  margin-left: auto;
}
.m\:edge {
  margin: 5%;
}
.m\:v\:edge {
  margin-top: 5%;
  margin-bottom: 5%;
}
.m\:h\:edge {
  margin-right: 5%;
  margin-left: 5%;
}
.m\:t\:edge {
  margin-top: 5%;
}
.m\:b\:edge {
  margin-bottom: 5%;
}
.m\:r\:edge {
  margin-right: 5%;
}
.m\:l\:edge {
  margin-left: 5%;
}
.m\:page {
  margin: 5rem;
}
.m\:v\:page {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.m\:h\:page {
  margin-right: 5rem;
  margin-left: 5rem;
}
.m\:t\:page {
  margin-top: 5rem;
}
.m\:b\:page {
  margin-bottom: 5rem;
}
.m\:r\:page {
  margin-right: 5rem;
}
.m\:l\:page {
  margin-left: 5rem;
}
.m\:segment {
  margin: 3rem;
}
.m\:v\:segment {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.m\:h\:segment {
  margin-right: 3rem;
  margin-left: 3rem;
}
.m\:t\:segment {
  margin-top: 3rem;
}
.m\:b\:segment {
  margin-bottom: 3rem;
}
.m\:r\:segment {
  margin-right: 3rem;
}
.m\:l\:segment {
  margin-left: 3rem;
}
.m\:base {
  margin: 1rem;
}
.m\:v\:base {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m\:h\:base {
  margin-right: 1rem;
  margin-left: 1rem;
}
.m\:t\:base {
  margin-top: 1rem;
}
.m\:b\:base {
  margin-bottom: 1rem;
}
.m\:r\:base {
  margin-right: 1rem;
}
.m\:l\:base {
  margin-left: 1rem;
}
.m\:headlinePre {
  margin: 1rem;
}
.m\:v\:headlinePre {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m\:h\:headlinePre {
  margin-right: 1rem;
  margin-left: 1rem;
}
.m\:t\:headlinePre {
  margin-top: 1rem;
}
.m\:b\:headlinePre {
  margin-bottom: 1rem;
}
.m\:r\:headlinePre {
  margin-right: 1rem;
}
.m\:l\:headlinePre {
  margin-left: 1rem;
}
.m\:headline {
  margin: 1.25rem;
}
.m\:v\:headline {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.m\:h\:headline {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}
.m\:t\:headline {
  margin-top: 1.25rem;
}
.m\:b\:headline {
  margin-bottom: 1.25rem;
}
.m\:r\:headline {
  margin-right: 1.25rem;
}
.m\:l\:headline {
  margin-left: 1.25rem;
}
.m\:headingPre {
  margin: 0.75rem;
}
.m\:v\:headingPre {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.m\:h\:headingPre {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.m\:t\:headingPre {
  margin-top: 0.75rem;
}
.m\:b\:headingPre {
  margin-bottom: 0.75rem;
}
.m\:r\:headingPre {
  margin-right: 0.75rem;
}
.m\:l\:headingPre {
  margin-left: 0.75rem;
}
.m\:heading {
  margin: 1rem;
}
.m\:v\:heading {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m\:h\:heading {
  margin-right: 1rem;
  margin-left: 1rem;
}
.m\:t\:heading {
  margin-top: 1rem;
}
.m\:b\:heading {
  margin-bottom: 1rem;
}
.m\:r\:heading {
  margin-right: 1rem;
}
.m\:l\:heading {
  margin-left: 1rem;
}
.m\:x1\:\!last:not(:last-child) {
  margin: 0.75rem;
}
.m\:v\:x1\:\!last:not(:last-child) {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.m\:h\:x1\:\!last:not(:last-child) {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.m\:t\:x1\:\!last:not(:last-child) {
  margin-top: 0.75rem;
}
.m\:b\:x1\:\!last:not(:last-child) {
  margin-bottom: 0.75rem;
}
.m\:r\:x1\:\!last:not(:last-child) {
  margin-right: 0.75rem;
}
.m\:l\:x1\:\!last:not(:last-child) {
  margin-left: 0.75rem;
}
.m\:x2\:\!last:not(:last-child) {
  margin: 0.5rem;
}
.m\:v\:x2\:\!last:not(:last-child) {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.m\:h\:x2\:\!last:not(:last-child) {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.m\:t\:x2\:\!last:not(:last-child) {
  margin-top: 0.5rem;
}
.m\:b\:x2\:\!last:not(:last-child) {
  margin-bottom: 0.5rem;
}
.m\:r\:x2\:\!last:not(:last-child) {
  margin-right: 0.5rem;
}
.m\:l\:x2\:\!last:not(:last-child) {
  margin-left: 0.5rem;
}
.m\:x3\:\!last:not(:last-child) {
  margin: 0.25rem;
}
.m\:v\:x3\:\!last:not(:last-child) {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.m\:h\:x3\:\!last:not(:last-child) {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
.m\:t\:x3\:\!last:not(:last-child) {
  margin-top: 0.25rem;
}
.m\:b\:x3\:\!last:not(:last-child) {
  margin-bottom: 0.25rem;
}
.m\:r\:x3\:\!last:not(:last-child) {
  margin-right: 0.25rem;
}
.m\:l\:x3\:\!last:not(:last-child) {
  margin-left: 0.25rem;
}
.m\:1x\:\!last:not(:last-child) {
  margin: 1rem;
}
.m\:v\:1x\:\!last:not(:last-child) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m\:h\:1x\:\!last:not(:last-child) {
  margin-right: 1rem;
  margin-left: 1rem;
}
.m\:t\:1x\:\!last:not(:last-child) {
  margin-top: 1rem;
}
.m\:b\:1x\:\!last:not(:last-child) {
  margin-bottom: 1rem;
}
.m\:r\:1x\:\!last:not(:last-child) {
  margin-right: 1rem;
}
.m\:l\:1x\:\!last:not(:last-child) {
  margin-left: 1rem;
}
.m\:2x\:\!last:not(:last-child) {
  margin: 2rem;
}
.m\:v\:2x\:\!last:not(:last-child) {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.m\:h\:2x\:\!last:not(:last-child) {
  margin-right: 2rem;
  margin-left: 2rem;
}
.m\:t\:2x\:\!last:not(:last-child) {
  margin-top: 2rem;
}
.m\:b\:2x\:\!last:not(:last-child) {
  margin-bottom: 2rem;
}
.m\:r\:2x\:\!last:not(:last-child) {
  margin-right: 2rem;
}
.m\:l\:2x\:\!last:not(:last-child) {
  margin-left: 2rem;
}
.m\:3x\:\!last:not(:last-child) {
  margin: 3rem;
}
.m\:v\:3x\:\!last:not(:last-child) {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.m\:h\:3x\:\!last:not(:last-child) {
  margin-right: 3rem;
  margin-left: 3rem;
}
.m\:t\:3x\:\!last:not(:last-child) {
  margin-top: 3rem;
}
.m\:b\:3x\:\!last:not(:last-child) {
  margin-bottom: 3rem;
}
.m\:r\:3x\:\!last:not(:last-child) {
  margin-right: 3rem;
}
.m\:l\:3x\:\!last:not(:last-child) {
  margin-left: 3rem;
}
.m\:4x\:\!last:not(:last-child) {
  margin: 4rem;
}
.m\:v\:4x\:\!last:not(:last-child) {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.m\:h\:4x\:\!last:not(:last-child) {
  margin-right: 4rem;
  margin-left: 4rem;
}
.m\:t\:4x\:\!last:not(:last-child) {
  margin-top: 4rem;
}
.m\:b\:4x\:\!last:not(:last-child) {
  margin-bottom: 4rem;
}
.m\:r\:4x\:\!last:not(:last-child) {
  margin-right: 4rem;
}
.m\:l\:4x\:\!last:not(:last-child) {
  margin-left: 4rem;
}
.m\:5x\:\!last:not(:last-child) {
  margin: 5rem;
}
.m\:v\:5x\:\!last:not(:last-child) {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.m\:h\:5x\:\!last:not(:last-child) {
  margin-right: 5rem;
  margin-left: 5rem;
}
.m\:t\:5x\:\!last:not(:last-child) {
  margin-top: 5rem;
}
.m\:b\:5x\:\!last:not(:last-child) {
  margin-bottom: 5rem;
}
.m\:r\:5x\:\!last:not(:last-child) {
  margin-right: 5rem;
}
.m\:l\:5x\:\!last:not(:last-child) {
  margin-left: 5rem;
}
.m\:6x\:\!last:not(:last-child) {
  margin: 6rem;
}
.m\:v\:6x\:\!last:not(:last-child) {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.m\:h\:6x\:\!last:not(:last-child) {
  margin-right: 6rem;
  margin-left: 6rem;
}
.m\:t\:6x\:\!last:not(:last-child) {
  margin-top: 6rem;
}
.m\:b\:6x\:\!last:not(:last-child) {
  margin-bottom: 6rem;
}
.m\:r\:6x\:\!last:not(:last-child) {
  margin-right: 6rem;
}
.m\:l\:6x\:\!last:not(:last-child) {
  margin-left: 6rem;
}
.m\:7x\:\!last:not(:last-child) {
  margin: 7rem;
}
.m\:v\:7x\:\!last:not(:last-child) {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.m\:h\:7x\:\!last:not(:last-child) {
  margin-right: 7rem;
  margin-left: 7rem;
}
.m\:t\:7x\:\!last:not(:last-child) {
  margin-top: 7rem;
}
.m\:b\:7x\:\!last:not(:last-child) {
  margin-bottom: 7rem;
}
.m\:r\:7x\:\!last:not(:last-child) {
  margin-right: 7rem;
}
.m\:l\:7x\:\!last:not(:last-child) {
  margin-left: 7rem;
}
.m\:8x\:\!last:not(:last-child) {
  margin: 8rem;
}
.m\:v\:8x\:\!last:not(:last-child) {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.m\:h\:8x\:\!last:not(:last-child) {
  margin-right: 8rem;
  margin-left: 8rem;
}
.m\:t\:8x\:\!last:not(:last-child) {
  margin-top: 8rem;
}
.m\:b\:8x\:\!last:not(:last-child) {
  margin-bottom: 8rem;
}
.m\:r\:8x\:\!last:not(:last-child) {
  margin-right: 8rem;
}
.m\:l\:8x\:\!last:not(:last-child) {
  margin-left: 8rem;
}
.m\:9x\:\!last:not(:last-child) {
  margin: 9rem;
}
.m\:v\:9x\:\!last:not(:last-child) {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.m\:h\:9x\:\!last:not(:last-child) {
  margin-right: 9rem;
  margin-left: 9rem;
}
.m\:t\:9x\:\!last:not(:last-child) {
  margin-top: 9rem;
}
.m\:b\:9x\:\!last:not(:last-child) {
  margin-bottom: 9rem;
}
.m\:r\:9x\:\!last:not(:last-child) {
  margin-right: 9rem;
}
.m\:l\:9x\:\!last:not(:last-child) {
  margin-left: 9rem;
}
.m\:10x\:\!last:not(:last-child) {
  margin: 10rem;
}
.m\:v\:10x\:\!last:not(:last-child) {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.m\:h\:10x\:\!last:not(:last-child) {
  margin-right: 10rem;
  margin-left: 10rem;
}
.m\:t\:10x\:\!last:not(:last-child) {
  margin-top: 10rem;
}
.m\:b\:10x\:\!last:not(:last-child) {
  margin-bottom: 10rem;
}
.m\:r\:10x\:\!last:not(:last-child) {
  margin-right: 10rem;
}
.m\:l\:10x\:\!last:not(:last-child) {
  margin-left: 10rem;
}
.m\:11x\:\!last:not(:last-child) {
  margin: 11rem;
}
.m\:v\:11x\:\!last:not(:last-child) {
  margin-top: 11rem;
  margin-bottom: 11rem;
}
.m\:h\:11x\:\!last:not(:last-child) {
  margin-right: 11rem;
  margin-left: 11rem;
}
.m\:t\:11x\:\!last:not(:last-child) {
  margin-top: 11rem;
}
.m\:b\:11x\:\!last:not(:last-child) {
  margin-bottom: 11rem;
}
.m\:r\:11x\:\!last:not(:last-child) {
  margin-right: 11rem;
}
.m\:l\:11x\:\!last:not(:last-child) {
  margin-left: 11rem;
}
.m\:12x\:\!last:not(:last-child) {
  margin: 12rem;
}
.m\:v\:12x\:\!last:not(:last-child) {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.m\:h\:12x\:\!last:not(:last-child) {
  margin-right: 12rem;
  margin-left: 12rem;
}
.m\:t\:12x\:\!last:not(:last-child) {
  margin-top: 12rem;
}
.m\:b\:12x\:\!last:not(:last-child) {
  margin-bottom: 12rem;
}
.m\:r\:12x\:\!last:not(:last-child) {
  margin-right: 12rem;
}
.m\:l\:12x\:\!last:not(:last-child) {
  margin-left: 12rem;
}
.m\:1xn\:\!last:not(:last-child) {
  margin: 1.5rem;
}
.m\:v\:1xn\:\!last:not(:last-child) {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.m\:h\:1xn\:\!last:not(:last-child) {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.m\:t\:1xn\:\!last:not(:last-child) {
  margin-top: 1.5rem;
}
.m\:b\:1xn\:\!last:not(:last-child) {
  margin-bottom: 1.5rem;
}
.m\:r\:1xn\:\!last:not(:last-child) {
  margin-right: 1.5rem;
}
.m\:l\:1xn\:\!last:not(:last-child) {
  margin-left: 1.5rem;
}
.m\:2xn\:\!last:not(:last-child) {
  margin: 2.5rem;
}
.m\:v\:2xn\:\!last:not(:last-child) {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.m\:h\:2xn\:\!last:not(:last-child) {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}
.m\:t\:2xn\:\!last:not(:last-child) {
  margin-top: 2.5rem;
}
.m\:b\:2xn\:\!last:not(:last-child) {
  margin-bottom: 2.5rem;
}
.m\:r\:2xn\:\!last:not(:last-child) {
  margin-right: 2.5rem;
}
.m\:l\:2xn\:\!last:not(:last-child) {
  margin-left: 2.5rem;
}
.m\:3xn\:\!last:not(:last-child) {
  margin: 3.5rem;
}
.m\:v\:3xn\:\!last:not(:last-child) {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.m\:h\:3xn\:\!last:not(:last-child) {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}
.m\:t\:3xn\:\!last:not(:last-child) {
  margin-top: 3.5rem;
}
.m\:b\:3xn\:\!last:not(:last-child) {
  margin-bottom: 3.5rem;
}
.m\:r\:3xn\:\!last:not(:last-child) {
  margin-right: 3.5rem;
}
.m\:l\:3xn\:\!last:not(:last-child) {
  margin-left: 3.5rem;
}
.m\:4xn\:\!last:not(:last-child) {
  margin: 4.5rem;
}
.m\:v\:4xn\:\!last:not(:last-child) {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
.m\:h\:4xn\:\!last:not(:last-child) {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}
.m\:t\:4xn\:\!last:not(:last-child) {
  margin-top: 4.5rem;
}
.m\:b\:4xn\:\!last:not(:last-child) {
  margin-bottom: 4.5rem;
}
.m\:r\:4xn\:\!last:not(:last-child) {
  margin-right: 4.5rem;
}
.m\:l\:4xn\:\!last:not(:last-child) {
  margin-left: 4.5rem;
}
.m\:5xn\:\!last:not(:last-child) {
  margin: 5.5rem;
}
.m\:v\:5xn\:\!last:not(:last-child) {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}
.m\:h\:5xn\:\!last:not(:last-child) {
  margin-right: 5.5rem;
  margin-left: 5.5rem;
}
.m\:t\:5xn\:\!last:not(:last-child) {
  margin-top: 5.5rem;
}
.m\:b\:5xn\:\!last:not(:last-child) {
  margin-bottom: 5.5rem;
}
.m\:r\:5xn\:\!last:not(:last-child) {
  margin-right: 5.5rem;
}
.m\:l\:5xn\:\!last:not(:last-child) {
  margin-left: 5.5rem;
}
.m\:6xn\:\!last:not(:last-child) {
  margin: 6.5rem;
}
.m\:v\:6xn\:\!last:not(:last-child) {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}
.m\:h\:6xn\:\!last:not(:last-child) {
  margin-right: 6.5rem;
  margin-left: 6.5rem;
}
.m\:t\:6xn\:\!last:not(:last-child) {
  margin-top: 6.5rem;
}
.m\:b\:6xn\:\!last:not(:last-child) {
  margin-bottom: 6.5rem;
}
.m\:r\:6xn\:\!last:not(:last-child) {
  margin-right: 6.5rem;
}
.m\:l\:6xn\:\!last:not(:last-child) {
  margin-left: 6.5rem;
}
.m\:7xn\:\!last:not(:last-child) {
  margin: 7.5rem;
}
.m\:v\:7xn\:\!last:not(:last-child) {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
.m\:h\:7xn\:\!last:not(:last-child) {
  margin-right: 7.5rem;
  margin-left: 7.5rem;
}
.m\:t\:7xn\:\!last:not(:last-child) {
  margin-top: 7.5rem;
}
.m\:b\:7xn\:\!last:not(:last-child) {
  margin-bottom: 7.5rem;
}
.m\:r\:7xn\:\!last:not(:last-child) {
  margin-right: 7.5rem;
}
.m\:l\:7xn\:\!last:not(:last-child) {
  margin-left: 7.5rem;
}
.m\:8xn\:\!last:not(:last-child) {
  margin: 8.5rem;
}
.m\:v\:8xn\:\!last:not(:last-child) {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem;
}
.m\:h\:8xn\:\!last:not(:last-child) {
  margin-right: 8.5rem;
  margin-left: 8.5rem;
}
.m\:t\:8xn\:\!last:not(:last-child) {
  margin-top: 8.5rem;
}
.m\:b\:8xn\:\!last:not(:last-child) {
  margin-bottom: 8.5rem;
}
.m\:r\:8xn\:\!last:not(:last-child) {
  margin-right: 8.5rem;
}
.m\:l\:8xn\:\!last:not(:last-child) {
  margin-left: 8.5rem;
}
.m\:9xn\:\!last:not(:last-child) {
  margin: 9.5rem;
}
.m\:v\:9xn\:\!last:not(:last-child) {
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
}
.m\:h\:9xn\:\!last:not(:last-child) {
  margin-right: 9.5rem;
  margin-left: 9.5rem;
}
.m\:t\:9xn\:\!last:not(:last-child) {
  margin-top: 9.5rem;
}
.m\:b\:9xn\:\!last:not(:last-child) {
  margin-bottom: 9.5rem;
}
.m\:r\:9xn\:\!last:not(:last-child) {
  margin-right: 9.5rem;
}
.m\:l\:9xn\:\!last:not(:last-child) {
  margin-left: 9.5rem;
}
.m\:10xn\:\!last:not(:last-child) {
  margin: 10.5rem;
}
.m\:v\:10xn\:\!last:not(:last-child) {
  margin-top: 10.5rem;
  margin-bottom: 10.5rem;
}
.m\:h\:10xn\:\!last:not(:last-child) {
  margin-right: 10.5rem;
  margin-left: 10.5rem;
}
.m\:t\:10xn\:\!last:not(:last-child) {
  margin-top: 10.5rem;
}
.m\:b\:10xn\:\!last:not(:last-child) {
  margin-bottom: 10.5rem;
}
.m\:r\:10xn\:\!last:not(:last-child) {
  margin-right: 10.5rem;
}
.m\:l\:10xn\:\!last:not(:last-child) {
  margin-left: 10.5rem;
}
.m\:11xn\:\!last:not(:last-child) {
  margin: 11.5rem;
}
.m\:v\:11xn\:\!last:not(:last-child) {
  margin-top: 11.5rem;
  margin-bottom: 11.5rem;
}
.m\:h\:11xn\:\!last:not(:last-child) {
  margin-right: 11.5rem;
  margin-left: 11.5rem;
}
.m\:t\:11xn\:\!last:not(:last-child) {
  margin-top: 11.5rem;
}
.m\:b\:11xn\:\!last:not(:last-child) {
  margin-bottom: 11.5rem;
}
.m\:r\:11xn\:\!last:not(:last-child) {
  margin-right: 11.5rem;
}
.m\:l\:11xn\:\!last:not(:last-child) {
  margin-left: 11.5rem;
}
.m\:12xn\:\!last:not(:last-child) {
  margin: 12.5rem;
}
.m\:v\:12xn\:\!last:not(:last-child) {
  margin-top: 12.5rem;
  margin-bottom: 12.5rem;
}
.m\:h\:12xn\:\!last:not(:last-child) {
  margin-right: 12.5rem;
  margin-left: 12.5rem;
}
.m\:t\:12xn\:\!last:not(:last-child) {
  margin-top: 12.5rem;
}
.m\:b\:12xn\:\!last:not(:last-child) {
  margin-bottom: 12.5rem;
}
.m\:r\:12xn\:\!last:not(:last-child) {
  margin-right: 12.5rem;
}
.m\:l\:12xn\:\!last:not(:last-child) {
  margin-left: 12.5rem;
}
.m\:none\:\!last:not(:last-child) {
  margin: 0;
}
.m\:v\:none\:\!last:not(:last-child) {
  margin-top: 0;
  margin-bottom: 0;
}
.m\:h\:none\:\!last:not(:last-child) {
  margin-right: 0;
  margin-left: 0;
}
.m\:t\:none\:\!last:not(:last-child) {
  margin-top: 0;
}
.m\:b\:none\:\!last:not(:last-child) {
  margin-bottom: 0;
}
.m\:r\:none\:\!last:not(:last-child) {
  margin-right: 0;
}
.m\:l\:none\:\!last:not(:last-child) {
  margin-left: 0;
}
.m\:auto\:\!last:not(:last-child) {
  margin: auto;
}
.m\:v\:auto\:\!last:not(:last-child) {
  margin-top: auto;
  margin-bottom: auto;
}
.m\:h\:auto\:\!last:not(:last-child) {
  margin-right: auto;
  margin-left: auto;
}
.m\:t\:auto\:\!last:not(:last-child) {
  margin-top: auto;
}
.m\:b\:auto\:\!last:not(:last-child) {
  margin-bottom: auto;
}
.m\:r\:auto\:\!last:not(:last-child) {
  margin-right: auto;
}
.m\:l\:auto\:\!last:not(:last-child) {
  margin-left: auto;
}
.m\:edge\:\!last:not(:last-child) {
  margin: 5%;
}
.m\:v\:edge\:\!last:not(:last-child) {
  margin-top: 5%;
  margin-bottom: 5%;
}
.m\:h\:edge\:\!last:not(:last-child) {
  margin-right: 5%;
  margin-left: 5%;
}
.m\:t\:edge\:\!last:not(:last-child) {
  margin-top: 5%;
}
.m\:b\:edge\:\!last:not(:last-child) {
  margin-bottom: 5%;
}
.m\:r\:edge\:\!last:not(:last-child) {
  margin-right: 5%;
}
.m\:l\:edge\:\!last:not(:last-child) {
  margin-left: 5%;
}
.m\:page\:\!last:not(:last-child) {
  margin: 5rem;
}
.m\:v\:page\:\!last:not(:last-child) {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.m\:h\:page\:\!last:not(:last-child) {
  margin-right: 5rem;
  margin-left: 5rem;
}
.m\:t\:page\:\!last:not(:last-child) {
  margin-top: 5rem;
}
.m\:b\:page\:\!last:not(:last-child) {
  margin-bottom: 5rem;
}
.m\:r\:page\:\!last:not(:last-child) {
  margin-right: 5rem;
}
.m\:l\:page\:\!last:not(:last-child) {
  margin-left: 5rem;
}
.m\:segment\:\!last:not(:last-child) {
  margin: 3rem;
}
.m\:v\:segment\:\!last:not(:last-child) {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.m\:h\:segment\:\!last:not(:last-child) {
  margin-right: 3rem;
  margin-left: 3rem;
}
.m\:t\:segment\:\!last:not(:last-child) {
  margin-top: 3rem;
}
.m\:b\:segment\:\!last:not(:last-child) {
  margin-bottom: 3rem;
}
.m\:r\:segment\:\!last:not(:last-child) {
  margin-right: 3rem;
}
.m\:l\:segment\:\!last:not(:last-child) {
  margin-left: 3rem;
}
.m\:base\:\!last:not(:last-child) {
  margin: 1rem;
}
.m\:v\:base\:\!last:not(:last-child) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m\:h\:base\:\!last:not(:last-child) {
  margin-right: 1rem;
  margin-left: 1rem;
}
.m\:t\:base\:\!last:not(:last-child) {
  margin-top: 1rem;
}
.m\:b\:base\:\!last:not(:last-child) {
  margin-bottom: 1rem;
}
.m\:r\:base\:\!last:not(:last-child) {
  margin-right: 1rem;
}
.m\:l\:base\:\!last:not(:last-child) {
  margin-left: 1rem;
}
.m\:headlinePre\:\!last:not(:last-child) {
  margin: 1rem;
}
.m\:v\:headlinePre\:\!last:not(:last-child) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m\:h\:headlinePre\:\!last:not(:last-child) {
  margin-right: 1rem;
  margin-left: 1rem;
}
.m\:t\:headlinePre\:\!last:not(:last-child) {
  margin-top: 1rem;
}
.m\:b\:headlinePre\:\!last:not(:last-child) {
  margin-bottom: 1rem;
}
.m\:r\:headlinePre\:\!last:not(:last-child) {
  margin-right: 1rem;
}
.m\:l\:headlinePre\:\!last:not(:last-child) {
  margin-left: 1rem;
}
.m\:headline\:\!last:not(:last-child) {
  margin: 1.25rem;
}
.m\:v\:headline\:\!last:not(:last-child) {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.m\:h\:headline\:\!last:not(:last-child) {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}
.m\:t\:headline\:\!last:not(:last-child) {
  margin-top: 1.25rem;
}
.m\:b\:headline\:\!last:not(:last-child) {
  margin-bottom: 1.25rem;
}
.m\:r\:headline\:\!last:not(:last-child) {
  margin-right: 1.25rem;
}
.m\:l\:headline\:\!last:not(:last-child) {
  margin-left: 1.25rem;
}
.m\:headingPre\:\!last:not(:last-child) {
  margin: 0.75rem;
}
.m\:v\:headingPre\:\!last:not(:last-child) {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.m\:h\:headingPre\:\!last:not(:last-child) {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.m\:t\:headingPre\:\!last:not(:last-child) {
  margin-top: 0.75rem;
}
.m\:b\:headingPre\:\!last:not(:last-child) {
  margin-bottom: 0.75rem;
}
.m\:r\:headingPre\:\!last:not(:last-child) {
  margin-right: 0.75rem;
}
.m\:l\:headingPre\:\!last:not(:last-child) {
  margin-left: 0.75rem;
}
.m\:heading\:\!last:not(:last-child) {
  margin: 1rem;
}
.m\:v\:heading\:\!last:not(:last-child) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m\:h\:heading\:\!last:not(:last-child) {
  margin-right: 1rem;
  margin-left: 1rem;
}
.m\:t\:heading\:\!last:not(:last-child) {
  margin-top: 1rem;
}
.m\:b\:heading\:\!last:not(:last-child) {
  margin-bottom: 1rem;
}
.m\:r\:heading\:\!last:not(:last-child) {
  margin-right: 1rem;
}
.m\:l\:heading\:\!last:not(:last-child) {
  margin-left: 1rem;
}
.p\:x1 {
  padding: 0.75rem;
}
.p\:v\:x1 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.p\:h\:x1 {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.p\:t\:x1 {
  padding-top: 0.75rem;
}
.p\:b\:x1 {
  padding-bottom: 0.75rem;
}
.p\:r\:x1 {
  padding-right: 0.75rem;
}
.p\:l\:x1 {
  padding-left: 0.75rem;
}
.p\:x2 {
  padding: 0.5rem;
}
.p\:v\:x2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.p\:h\:x2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.p\:t\:x2 {
  padding-top: 0.5rem;
}
.p\:b\:x2 {
  padding-bottom: 0.5rem;
}
.p\:r\:x2 {
  padding-right: 0.5rem;
}
.p\:l\:x2 {
  padding-left: 0.5rem;
}
.p\:x3 {
  padding: 0.25rem;
}
.p\:v\:x3 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.p\:h\:x3 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
.p\:t\:x3 {
  padding-top: 0.25rem;
}
.p\:b\:x3 {
  padding-bottom: 0.25rem;
}
.p\:r\:x3 {
  padding-right: 0.25rem;
}
.p\:l\:x3 {
  padding-left: 0.25rem;
}
.p\:1x {
  padding: 1rem;
}
.p\:v\:1x {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.p\:h\:1x {
  padding-right: 1rem;
  padding-left: 1rem;
}
.p\:t\:1x {
  padding-top: 1rem;
}
.p\:b\:1x {
  padding-bottom: 1rem;
}
.p\:r\:1x {
  padding-right: 1rem;
}
.p\:l\:1x {
  padding-left: 1rem;
}
.p\:2x {
  padding: 2rem;
}
.p\:v\:2x {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.p\:h\:2x {
  padding-right: 2rem;
  padding-left: 2rem;
}
.p\:t\:2x {
  padding-top: 2rem;
}
.p\:b\:2x {
  padding-bottom: 2rem;
}
.p\:r\:2x {
  padding-right: 2rem;
}
.p\:l\:2x {
  padding-left: 2rem;
}
.p\:3x {
  padding: 3rem;
}
.p\:v\:3x {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.p\:h\:3x {
  padding-right: 3rem;
  padding-left: 3rem;
}
.p\:t\:3x {
  padding-top: 3rem;
}
.p\:b\:3x {
  padding-bottom: 3rem;
}
.p\:r\:3x {
  padding-right: 3rem;
}
.p\:l\:3x {
  padding-left: 3rem;
}
.p\:4x {
  padding: 4rem;
}
.p\:v\:4x {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.p\:h\:4x {
  padding-right: 4rem;
  padding-left: 4rem;
}
.p\:t\:4x {
  padding-top: 4rem;
}
.p\:b\:4x {
  padding-bottom: 4rem;
}
.p\:r\:4x {
  padding-right: 4rem;
}
.p\:l\:4x {
  padding-left: 4rem;
}
.p\:5x {
  padding: 5rem;
}
.p\:v\:5x {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.p\:h\:5x {
  padding-right: 5rem;
  padding-left: 5rem;
}
.p\:t\:5x {
  padding-top: 5rem;
}
.p\:b\:5x {
  padding-bottom: 5rem;
}
.p\:r\:5x {
  padding-right: 5rem;
}
.p\:l\:5x {
  padding-left: 5rem;
}
.p\:6x {
  padding: 6rem;
}
.p\:v\:6x {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.p\:h\:6x {
  padding-right: 6rem;
  padding-left: 6rem;
}
.p\:t\:6x {
  padding-top: 6rem;
}
.p\:b\:6x {
  padding-bottom: 6rem;
}
.p\:r\:6x {
  padding-right: 6rem;
}
.p\:l\:6x {
  padding-left: 6rem;
}
.p\:7x {
  padding: 7rem;
}
.p\:v\:7x {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.p\:h\:7x {
  padding-right: 7rem;
  padding-left: 7rem;
}
.p\:t\:7x {
  padding-top: 7rem;
}
.p\:b\:7x {
  padding-bottom: 7rem;
}
.p\:r\:7x {
  padding-right: 7rem;
}
.p\:l\:7x {
  padding-left: 7rem;
}
.p\:8x {
  padding: 8rem;
}
.p\:v\:8x {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.p\:h\:8x {
  padding-right: 8rem;
  padding-left: 8rem;
}
.p\:t\:8x {
  padding-top: 8rem;
}
.p\:b\:8x {
  padding-bottom: 8rem;
}
.p\:r\:8x {
  padding-right: 8rem;
}
.p\:l\:8x {
  padding-left: 8rem;
}
.p\:9x {
  padding: 9rem;
}
.p\:v\:9x {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.p\:h\:9x {
  padding-right: 9rem;
  padding-left: 9rem;
}
.p\:t\:9x {
  padding-top: 9rem;
}
.p\:b\:9x {
  padding-bottom: 9rem;
}
.p\:r\:9x {
  padding-right: 9rem;
}
.p\:l\:9x {
  padding-left: 9rem;
}
.p\:10x {
  padding: 10rem;
}
.p\:v\:10x {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.p\:h\:10x {
  padding-right: 10rem;
  padding-left: 10rem;
}
.p\:t\:10x {
  padding-top: 10rem;
}
.p\:b\:10x {
  padding-bottom: 10rem;
}
.p\:r\:10x {
  padding-right: 10rem;
}
.p\:l\:10x {
  padding-left: 10rem;
}
.p\:11x {
  padding: 11rem;
}
.p\:v\:11x {
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.p\:h\:11x {
  padding-right: 11rem;
  padding-left: 11rem;
}
.p\:t\:11x {
  padding-top: 11rem;
}
.p\:b\:11x {
  padding-bottom: 11rem;
}
.p\:r\:11x {
  padding-right: 11rem;
}
.p\:l\:11x {
  padding-left: 11rem;
}
.p\:12x {
  padding: 12rem;
}
.p\:v\:12x {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.p\:h\:12x {
  padding-right: 12rem;
  padding-left: 12rem;
}
.p\:t\:12x {
  padding-top: 12rem;
}
.p\:b\:12x {
  padding-bottom: 12rem;
}
.p\:r\:12x {
  padding-right: 12rem;
}
.p\:l\:12x {
  padding-left: 12rem;
}
.p\:1xn {
  padding: 1.5rem;
}
.p\:v\:1xn {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.p\:h\:1xn {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.p\:t\:1xn {
  padding-top: 1.5rem;
}
.p\:b\:1xn {
  padding-bottom: 1.5rem;
}
.p\:r\:1xn {
  padding-right: 1.5rem;
}
.p\:l\:1xn {
  padding-left: 1.5rem;
}
.p\:2xn {
  padding: 2.5rem;
}
.p\:v\:2xn {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.p\:h\:2xn {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.p\:t\:2xn {
  padding-top: 2.5rem;
}
.p\:b\:2xn {
  padding-bottom: 2.5rem;
}
.p\:r\:2xn {
  padding-right: 2.5rem;
}
.p\:l\:2xn {
  padding-left: 2.5rem;
}
.p\:3xn {
  padding: 3.5rem;
}
.p\:v\:3xn {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.p\:h\:3xn {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}
.p\:t\:3xn {
  padding-top: 3.5rem;
}
.p\:b\:3xn {
  padding-bottom: 3.5rem;
}
.p\:r\:3xn {
  padding-right: 3.5rem;
}
.p\:l\:3xn {
  padding-left: 3.5rem;
}
.p\:4xn {
  padding: 4.5rem;
}
.p\:v\:4xn {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
.p\:h\:4xn {
  padding-right: 4.5rem;
  padding-left: 4.5rem;
}
.p\:t\:4xn {
  padding-top: 4.5rem;
}
.p\:b\:4xn {
  padding-bottom: 4.5rem;
}
.p\:r\:4xn {
  padding-right: 4.5rem;
}
.p\:l\:4xn {
  padding-left: 4.5rem;
}
.p\:5xn {
  padding: 5.5rem;
}
.p\:v\:5xn {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}
.p\:h\:5xn {
  padding-right: 5.5rem;
  padding-left: 5.5rem;
}
.p\:t\:5xn {
  padding-top: 5.5rem;
}
.p\:b\:5xn {
  padding-bottom: 5.5rem;
}
.p\:r\:5xn {
  padding-right: 5.5rem;
}
.p\:l\:5xn {
  padding-left: 5.5rem;
}
.p\:6xn {
  padding: 6.5rem;
}
.p\:v\:6xn {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}
.p\:h\:6xn {
  padding-right: 6.5rem;
  padding-left: 6.5rem;
}
.p\:t\:6xn {
  padding-top: 6.5rem;
}
.p\:b\:6xn {
  padding-bottom: 6.5rem;
}
.p\:r\:6xn {
  padding-right: 6.5rem;
}
.p\:l\:6xn {
  padding-left: 6.5rem;
}
.p\:7xn {
  padding: 7.5rem;
}
.p\:v\:7xn {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.p\:h\:7xn {
  padding-right: 7.5rem;
  padding-left: 7.5rem;
}
.p\:t\:7xn {
  padding-top: 7.5rem;
}
.p\:b\:7xn {
  padding-bottom: 7.5rem;
}
.p\:r\:7xn {
  padding-right: 7.5rem;
}
.p\:l\:7xn {
  padding-left: 7.5rem;
}
.p\:8xn {
  padding: 8.5rem;
}
.p\:v\:8xn {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
}
.p\:h\:8xn {
  padding-right: 8.5rem;
  padding-left: 8.5rem;
}
.p\:t\:8xn {
  padding-top: 8.5rem;
}
.p\:b\:8xn {
  padding-bottom: 8.5rem;
}
.p\:r\:8xn {
  padding-right: 8.5rem;
}
.p\:l\:8xn {
  padding-left: 8.5rem;
}
.p\:9xn {
  padding: 9.5rem;
}
.p\:v\:9xn {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}
.p\:h\:9xn {
  padding-right: 9.5rem;
  padding-left: 9.5rem;
}
.p\:t\:9xn {
  padding-top: 9.5rem;
}
.p\:b\:9xn {
  padding-bottom: 9.5rem;
}
.p\:r\:9xn {
  padding-right: 9.5rem;
}
.p\:l\:9xn {
  padding-left: 9.5rem;
}
.p\:10xn {
  padding: 10.5rem;
}
.p\:v\:10xn {
  padding-top: 10.5rem;
  padding-bottom: 10.5rem;
}
.p\:h\:10xn {
  padding-right: 10.5rem;
  padding-left: 10.5rem;
}
.p\:t\:10xn {
  padding-top: 10.5rem;
}
.p\:b\:10xn {
  padding-bottom: 10.5rem;
}
.p\:r\:10xn {
  padding-right: 10.5rem;
}
.p\:l\:10xn {
  padding-left: 10.5rem;
}
.p\:11xn {
  padding: 11.5rem;
}
.p\:v\:11xn {
  padding-top: 11.5rem;
  padding-bottom: 11.5rem;
}
.p\:h\:11xn {
  padding-right: 11.5rem;
  padding-left: 11.5rem;
}
.p\:t\:11xn {
  padding-top: 11.5rem;
}
.p\:b\:11xn {
  padding-bottom: 11.5rem;
}
.p\:r\:11xn {
  padding-right: 11.5rem;
}
.p\:l\:11xn {
  padding-left: 11.5rem;
}
.p\:12xn {
  padding: 12.5rem;
}
.p\:v\:12xn {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}
.p\:h\:12xn {
  padding-right: 12.5rem;
  padding-left: 12.5rem;
}
.p\:t\:12xn {
  padding-top: 12.5rem;
}
.p\:b\:12xn {
  padding-bottom: 12.5rem;
}
.p\:r\:12xn {
  padding-right: 12.5rem;
}
.p\:l\:12xn {
  padding-left: 12.5rem;
}
.p\:none {
  padding: 0;
}
.p\:v\:none {
  padding-top: 0;
  padding-bottom: 0;
}
.p\:h\:none {
  padding-right: 0;
  padding-left: 0;
}
.p\:t\:none {
  padding-top: 0;
}
.p\:b\:none {
  padding-bottom: 0;
}
.p\:r\:none {
  padding-right: 0;
}
.p\:l\:none {
  padding-left: 0;
}
.p\:auto {
  padding: auto;
}
.p\:v\:auto {
  padding-top: auto;
  padding-bottom: auto;
}
.p\:h\:auto {
  padding-right: auto;
  padding-left: auto;
}
.p\:t\:auto {
  padding-top: auto;
}
.p\:b\:auto {
  padding-bottom: auto;
}
.p\:r\:auto {
  padding-right: auto;
}
.p\:l\:auto {
  padding-left: auto;
}
.p\:edge {
  padding: 5%;
}
.p\:v\:edge {
  padding-top: 5%;
  padding-bottom: 5%;
}
.p\:h\:edge {
  padding-right: 5%;
  padding-left: 5%;
}
.p\:t\:edge {
  padding-top: 5%;
}
.p\:b\:edge {
  padding-bottom: 5%;
}
.p\:r\:edge {
  padding-right: 5%;
}
.p\:l\:edge {
  padding-left: 5%;
}
.p\:page {
  padding: 5rem;
}
.p\:v\:page {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.p\:h\:page {
  padding-right: 5rem;
  padding-left: 5rem;
}
.p\:t\:page {
  padding-top: 5rem;
}
.p\:b\:page {
  padding-bottom: 5rem;
}
.p\:r\:page {
  padding-right: 5rem;
}
.p\:l\:page {
  padding-left: 5rem;
}
.p\:segment {
  padding: 3rem;
}
.p\:v\:segment {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.p\:h\:segment {
  padding-right: 3rem;
  padding-left: 3rem;
}
.p\:t\:segment {
  padding-top: 3rem;
}
.p\:b\:segment {
  padding-bottom: 3rem;
}
.p\:r\:segment {
  padding-right: 3rem;
}
.p\:l\:segment {
  padding-left: 3rem;
}
.p\:base {
  padding: 1rem;
}
.p\:v\:base {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.p\:h\:base {
  padding-right: 1rem;
  padding-left: 1rem;
}
.p\:t\:base {
  padding-top: 1rem;
}
.p\:b\:base {
  padding-bottom: 1rem;
}
.p\:r\:base {
  padding-right: 1rem;
}
.p\:l\:base {
  padding-left: 1rem;
}
.p\:headlinePre {
  padding: 1rem;
}
.p\:v\:headlinePre {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.p\:h\:headlinePre {
  padding-right: 1rem;
  padding-left: 1rem;
}
.p\:t\:headlinePre {
  padding-top: 1rem;
}
.p\:b\:headlinePre {
  padding-bottom: 1rem;
}
.p\:r\:headlinePre {
  padding-right: 1rem;
}
.p\:l\:headlinePre {
  padding-left: 1rem;
}
.p\:headline {
  padding: 1.25rem;
}
.p\:v\:headline {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.p\:h\:headline {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.p\:t\:headline {
  padding-top: 1.25rem;
}
.p\:b\:headline {
  padding-bottom: 1.25rem;
}
.p\:r\:headline {
  padding-right: 1.25rem;
}
.p\:l\:headline {
  padding-left: 1.25rem;
}
.p\:headingPre {
  padding: 0.75rem;
}
.p\:v\:headingPre {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.p\:h\:headingPre {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.p\:t\:headingPre {
  padding-top: 0.75rem;
}
.p\:b\:headingPre {
  padding-bottom: 0.75rem;
}
.p\:r\:headingPre {
  padding-right: 0.75rem;
}
.p\:l\:headingPre {
  padding-left: 0.75rem;
}
.p\:heading {
  padding: 1rem;
}
.p\:v\:heading {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.p\:h\:heading {
  padding-right: 1rem;
  padding-left: 1rem;
}
.p\:t\:heading {
  padding-top: 1rem;
}
.p\:b\:heading {
  padding-bottom: 1rem;
}
.p\:r\:heading {
  padding-right: 1rem;
}
.p\:l\:heading {
  padding-left: 1rem;
}
.p\:x1\:\!last:not(:last-child) {
  padding: 0.75rem;
}
.p\:v\:x1\:\!last:not(:last-child) {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.p\:h\:x1\:\!last:not(:last-child) {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.p\:t\:x1\:\!last:not(:last-child) {
  padding-top: 0.75rem;
}
.p\:b\:x1\:\!last:not(:last-child) {
  padding-bottom: 0.75rem;
}
.p\:r\:x1\:\!last:not(:last-child) {
  padding-right: 0.75rem;
}
.p\:l\:x1\:\!last:not(:last-child) {
  padding-left: 0.75rem;
}
.p\:x2\:\!last:not(:last-child) {
  padding: 0.5rem;
}
.p\:v\:x2\:\!last:not(:last-child) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.p\:h\:x2\:\!last:not(:last-child) {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.p\:t\:x2\:\!last:not(:last-child) {
  padding-top: 0.5rem;
}
.p\:b\:x2\:\!last:not(:last-child) {
  padding-bottom: 0.5rem;
}
.p\:r\:x2\:\!last:not(:last-child) {
  padding-right: 0.5rem;
}
.p\:l\:x2\:\!last:not(:last-child) {
  padding-left: 0.5rem;
}
.p\:x3\:\!last:not(:last-child) {
  padding: 0.25rem;
}
.p\:v\:x3\:\!last:not(:last-child) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.p\:h\:x3\:\!last:not(:last-child) {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
.p\:t\:x3\:\!last:not(:last-child) {
  padding-top: 0.25rem;
}
.p\:b\:x3\:\!last:not(:last-child) {
  padding-bottom: 0.25rem;
}
.p\:r\:x3\:\!last:not(:last-child) {
  padding-right: 0.25rem;
}
.p\:l\:x3\:\!last:not(:last-child) {
  padding-left: 0.25rem;
}
.p\:1x\:\!last:not(:last-child) {
  padding: 1rem;
}
.p\:v\:1x\:\!last:not(:last-child) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.p\:h\:1x\:\!last:not(:last-child) {
  padding-right: 1rem;
  padding-left: 1rem;
}
.p\:t\:1x\:\!last:not(:last-child) {
  padding-top: 1rem;
}
.p\:b\:1x\:\!last:not(:last-child) {
  padding-bottom: 1rem;
}
.p\:r\:1x\:\!last:not(:last-child) {
  padding-right: 1rem;
}
.p\:l\:1x\:\!last:not(:last-child) {
  padding-left: 1rem;
}
.p\:2x\:\!last:not(:last-child) {
  padding: 2rem;
}
.p\:v\:2x\:\!last:not(:last-child) {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.p\:h\:2x\:\!last:not(:last-child) {
  padding-right: 2rem;
  padding-left: 2rem;
}
.p\:t\:2x\:\!last:not(:last-child) {
  padding-top: 2rem;
}
.p\:b\:2x\:\!last:not(:last-child) {
  padding-bottom: 2rem;
}
.p\:r\:2x\:\!last:not(:last-child) {
  padding-right: 2rem;
}
.p\:l\:2x\:\!last:not(:last-child) {
  padding-left: 2rem;
}
.p\:3x\:\!last:not(:last-child) {
  padding: 3rem;
}
.p\:v\:3x\:\!last:not(:last-child) {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.p\:h\:3x\:\!last:not(:last-child) {
  padding-right: 3rem;
  padding-left: 3rem;
}
.p\:t\:3x\:\!last:not(:last-child) {
  padding-top: 3rem;
}
.p\:b\:3x\:\!last:not(:last-child) {
  padding-bottom: 3rem;
}
.p\:r\:3x\:\!last:not(:last-child) {
  padding-right: 3rem;
}
.p\:l\:3x\:\!last:not(:last-child) {
  padding-left: 3rem;
}
.p\:4x\:\!last:not(:last-child) {
  padding: 4rem;
}
.p\:v\:4x\:\!last:not(:last-child) {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.p\:h\:4x\:\!last:not(:last-child) {
  padding-right: 4rem;
  padding-left: 4rem;
}
.p\:t\:4x\:\!last:not(:last-child) {
  padding-top: 4rem;
}
.p\:b\:4x\:\!last:not(:last-child) {
  padding-bottom: 4rem;
}
.p\:r\:4x\:\!last:not(:last-child) {
  padding-right: 4rem;
}
.p\:l\:4x\:\!last:not(:last-child) {
  padding-left: 4rem;
}
.p\:5x\:\!last:not(:last-child) {
  padding: 5rem;
}
.p\:v\:5x\:\!last:not(:last-child) {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.p\:h\:5x\:\!last:not(:last-child) {
  padding-right: 5rem;
  padding-left: 5rem;
}
.p\:t\:5x\:\!last:not(:last-child) {
  padding-top: 5rem;
}
.p\:b\:5x\:\!last:not(:last-child) {
  padding-bottom: 5rem;
}
.p\:r\:5x\:\!last:not(:last-child) {
  padding-right: 5rem;
}
.p\:l\:5x\:\!last:not(:last-child) {
  padding-left: 5rem;
}
.p\:6x\:\!last:not(:last-child) {
  padding: 6rem;
}
.p\:v\:6x\:\!last:not(:last-child) {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.p\:h\:6x\:\!last:not(:last-child) {
  padding-right: 6rem;
  padding-left: 6rem;
}
.p\:t\:6x\:\!last:not(:last-child) {
  padding-top: 6rem;
}
.p\:b\:6x\:\!last:not(:last-child) {
  padding-bottom: 6rem;
}
.p\:r\:6x\:\!last:not(:last-child) {
  padding-right: 6rem;
}
.p\:l\:6x\:\!last:not(:last-child) {
  padding-left: 6rem;
}
.p\:7x\:\!last:not(:last-child) {
  padding: 7rem;
}
.p\:v\:7x\:\!last:not(:last-child) {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.p\:h\:7x\:\!last:not(:last-child) {
  padding-right: 7rem;
  padding-left: 7rem;
}
.p\:t\:7x\:\!last:not(:last-child) {
  padding-top: 7rem;
}
.p\:b\:7x\:\!last:not(:last-child) {
  padding-bottom: 7rem;
}
.p\:r\:7x\:\!last:not(:last-child) {
  padding-right: 7rem;
}
.p\:l\:7x\:\!last:not(:last-child) {
  padding-left: 7rem;
}
.p\:8x\:\!last:not(:last-child) {
  padding: 8rem;
}
.p\:v\:8x\:\!last:not(:last-child) {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.p\:h\:8x\:\!last:not(:last-child) {
  padding-right: 8rem;
  padding-left: 8rem;
}
.p\:t\:8x\:\!last:not(:last-child) {
  padding-top: 8rem;
}
.p\:b\:8x\:\!last:not(:last-child) {
  padding-bottom: 8rem;
}
.p\:r\:8x\:\!last:not(:last-child) {
  padding-right: 8rem;
}
.p\:l\:8x\:\!last:not(:last-child) {
  padding-left: 8rem;
}
.p\:9x\:\!last:not(:last-child) {
  padding: 9rem;
}
.p\:v\:9x\:\!last:not(:last-child) {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.p\:h\:9x\:\!last:not(:last-child) {
  padding-right: 9rem;
  padding-left: 9rem;
}
.p\:t\:9x\:\!last:not(:last-child) {
  padding-top: 9rem;
}
.p\:b\:9x\:\!last:not(:last-child) {
  padding-bottom: 9rem;
}
.p\:r\:9x\:\!last:not(:last-child) {
  padding-right: 9rem;
}
.p\:l\:9x\:\!last:not(:last-child) {
  padding-left: 9rem;
}
.p\:10x\:\!last:not(:last-child) {
  padding: 10rem;
}
.p\:v\:10x\:\!last:not(:last-child) {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.p\:h\:10x\:\!last:not(:last-child) {
  padding-right: 10rem;
  padding-left: 10rem;
}
.p\:t\:10x\:\!last:not(:last-child) {
  padding-top: 10rem;
}
.p\:b\:10x\:\!last:not(:last-child) {
  padding-bottom: 10rem;
}
.p\:r\:10x\:\!last:not(:last-child) {
  padding-right: 10rem;
}
.p\:l\:10x\:\!last:not(:last-child) {
  padding-left: 10rem;
}
.p\:11x\:\!last:not(:last-child) {
  padding: 11rem;
}
.p\:v\:11x\:\!last:not(:last-child) {
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.p\:h\:11x\:\!last:not(:last-child) {
  padding-right: 11rem;
  padding-left: 11rem;
}
.p\:t\:11x\:\!last:not(:last-child) {
  padding-top: 11rem;
}
.p\:b\:11x\:\!last:not(:last-child) {
  padding-bottom: 11rem;
}
.p\:r\:11x\:\!last:not(:last-child) {
  padding-right: 11rem;
}
.p\:l\:11x\:\!last:not(:last-child) {
  padding-left: 11rem;
}
.p\:12x\:\!last:not(:last-child) {
  padding: 12rem;
}
.p\:v\:12x\:\!last:not(:last-child) {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.p\:h\:12x\:\!last:not(:last-child) {
  padding-right: 12rem;
  padding-left: 12rem;
}
.p\:t\:12x\:\!last:not(:last-child) {
  padding-top: 12rem;
}
.p\:b\:12x\:\!last:not(:last-child) {
  padding-bottom: 12rem;
}
.p\:r\:12x\:\!last:not(:last-child) {
  padding-right: 12rem;
}
.p\:l\:12x\:\!last:not(:last-child) {
  padding-left: 12rem;
}
.p\:1xn\:\!last:not(:last-child) {
  padding: 1.5rem;
}
.p\:v\:1xn\:\!last:not(:last-child) {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.p\:h\:1xn\:\!last:not(:last-child) {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.p\:t\:1xn\:\!last:not(:last-child) {
  padding-top: 1.5rem;
}
.p\:b\:1xn\:\!last:not(:last-child) {
  padding-bottom: 1.5rem;
}
.p\:r\:1xn\:\!last:not(:last-child) {
  padding-right: 1.5rem;
}
.p\:l\:1xn\:\!last:not(:last-child) {
  padding-left: 1.5rem;
}
.p\:2xn\:\!last:not(:last-child) {
  padding: 2.5rem;
}
.p\:v\:2xn\:\!last:not(:last-child) {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.p\:h\:2xn\:\!last:not(:last-child) {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.p\:t\:2xn\:\!last:not(:last-child) {
  padding-top: 2.5rem;
}
.p\:b\:2xn\:\!last:not(:last-child) {
  padding-bottom: 2.5rem;
}
.p\:r\:2xn\:\!last:not(:last-child) {
  padding-right: 2.5rem;
}
.p\:l\:2xn\:\!last:not(:last-child) {
  padding-left: 2.5rem;
}
.p\:3xn\:\!last:not(:last-child) {
  padding: 3.5rem;
}
.p\:v\:3xn\:\!last:not(:last-child) {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.p\:h\:3xn\:\!last:not(:last-child) {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}
.p\:t\:3xn\:\!last:not(:last-child) {
  padding-top: 3.5rem;
}
.p\:b\:3xn\:\!last:not(:last-child) {
  padding-bottom: 3.5rem;
}
.p\:r\:3xn\:\!last:not(:last-child) {
  padding-right: 3.5rem;
}
.p\:l\:3xn\:\!last:not(:last-child) {
  padding-left: 3.5rem;
}
.p\:4xn\:\!last:not(:last-child) {
  padding: 4.5rem;
}
.p\:v\:4xn\:\!last:not(:last-child) {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
.p\:h\:4xn\:\!last:not(:last-child) {
  padding-right: 4.5rem;
  padding-left: 4.5rem;
}
.p\:t\:4xn\:\!last:not(:last-child) {
  padding-top: 4.5rem;
}
.p\:b\:4xn\:\!last:not(:last-child) {
  padding-bottom: 4.5rem;
}
.p\:r\:4xn\:\!last:not(:last-child) {
  padding-right: 4.5rem;
}
.p\:l\:4xn\:\!last:not(:last-child) {
  padding-left: 4.5rem;
}
.p\:5xn\:\!last:not(:last-child) {
  padding: 5.5rem;
}
.p\:v\:5xn\:\!last:not(:last-child) {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}
.p\:h\:5xn\:\!last:not(:last-child) {
  padding-right: 5.5rem;
  padding-left: 5.5rem;
}
.p\:t\:5xn\:\!last:not(:last-child) {
  padding-top: 5.5rem;
}
.p\:b\:5xn\:\!last:not(:last-child) {
  padding-bottom: 5.5rem;
}
.p\:r\:5xn\:\!last:not(:last-child) {
  padding-right: 5.5rem;
}
.p\:l\:5xn\:\!last:not(:last-child) {
  padding-left: 5.5rem;
}
.p\:6xn\:\!last:not(:last-child) {
  padding: 6.5rem;
}
.p\:v\:6xn\:\!last:not(:last-child) {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}
.p\:h\:6xn\:\!last:not(:last-child) {
  padding-right: 6.5rem;
  padding-left: 6.5rem;
}
.p\:t\:6xn\:\!last:not(:last-child) {
  padding-top: 6.5rem;
}
.p\:b\:6xn\:\!last:not(:last-child) {
  padding-bottom: 6.5rem;
}
.p\:r\:6xn\:\!last:not(:last-child) {
  padding-right: 6.5rem;
}
.p\:l\:6xn\:\!last:not(:last-child) {
  padding-left: 6.5rem;
}
.p\:7xn\:\!last:not(:last-child) {
  padding: 7.5rem;
}
.p\:v\:7xn\:\!last:not(:last-child) {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.p\:h\:7xn\:\!last:not(:last-child) {
  padding-right: 7.5rem;
  padding-left: 7.5rem;
}
.p\:t\:7xn\:\!last:not(:last-child) {
  padding-top: 7.5rem;
}
.p\:b\:7xn\:\!last:not(:last-child) {
  padding-bottom: 7.5rem;
}
.p\:r\:7xn\:\!last:not(:last-child) {
  padding-right: 7.5rem;
}
.p\:l\:7xn\:\!last:not(:last-child) {
  padding-left: 7.5rem;
}
.p\:8xn\:\!last:not(:last-child) {
  padding: 8.5rem;
}
.p\:v\:8xn\:\!last:not(:last-child) {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
}
.p\:h\:8xn\:\!last:not(:last-child) {
  padding-right: 8.5rem;
  padding-left: 8.5rem;
}
.p\:t\:8xn\:\!last:not(:last-child) {
  padding-top: 8.5rem;
}
.p\:b\:8xn\:\!last:not(:last-child) {
  padding-bottom: 8.5rem;
}
.p\:r\:8xn\:\!last:not(:last-child) {
  padding-right: 8.5rem;
}
.p\:l\:8xn\:\!last:not(:last-child) {
  padding-left: 8.5rem;
}
.p\:9xn\:\!last:not(:last-child) {
  padding: 9.5rem;
}
.p\:v\:9xn\:\!last:not(:last-child) {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}
.p\:h\:9xn\:\!last:not(:last-child) {
  padding-right: 9.5rem;
  padding-left: 9.5rem;
}
.p\:t\:9xn\:\!last:not(:last-child) {
  padding-top: 9.5rem;
}
.p\:b\:9xn\:\!last:not(:last-child) {
  padding-bottom: 9.5rem;
}
.p\:r\:9xn\:\!last:not(:last-child) {
  padding-right: 9.5rem;
}
.p\:l\:9xn\:\!last:not(:last-child) {
  padding-left: 9.5rem;
}
.p\:10xn\:\!last:not(:last-child) {
  padding: 10.5rem;
}
.p\:v\:10xn\:\!last:not(:last-child) {
  padding-top: 10.5rem;
  padding-bottom: 10.5rem;
}
.p\:h\:10xn\:\!last:not(:last-child) {
  padding-right: 10.5rem;
  padding-left: 10.5rem;
}
.p\:t\:10xn\:\!last:not(:last-child) {
  padding-top: 10.5rem;
}
.p\:b\:10xn\:\!last:not(:last-child) {
  padding-bottom: 10.5rem;
}
.p\:r\:10xn\:\!last:not(:last-child) {
  padding-right: 10.5rem;
}
.p\:l\:10xn\:\!last:not(:last-child) {
  padding-left: 10.5rem;
}
.p\:11xn\:\!last:not(:last-child) {
  padding: 11.5rem;
}
.p\:v\:11xn\:\!last:not(:last-child) {
  padding-top: 11.5rem;
  padding-bottom: 11.5rem;
}
.p\:h\:11xn\:\!last:not(:last-child) {
  padding-right: 11.5rem;
  padding-left: 11.5rem;
}
.p\:t\:11xn\:\!last:not(:last-child) {
  padding-top: 11.5rem;
}
.p\:b\:11xn\:\!last:not(:last-child) {
  padding-bottom: 11.5rem;
}
.p\:r\:11xn\:\!last:not(:last-child) {
  padding-right: 11.5rem;
}
.p\:l\:11xn\:\!last:not(:last-child) {
  padding-left: 11.5rem;
}
.p\:12xn\:\!last:not(:last-child) {
  padding: 12.5rem;
}
.p\:v\:12xn\:\!last:not(:last-child) {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}
.p\:h\:12xn\:\!last:not(:last-child) {
  padding-right: 12.5rem;
  padding-left: 12.5rem;
}
.p\:t\:12xn\:\!last:not(:last-child) {
  padding-top: 12.5rem;
}
.p\:b\:12xn\:\!last:not(:last-child) {
  padding-bottom: 12.5rem;
}
.p\:r\:12xn\:\!last:not(:last-child) {
  padding-right: 12.5rem;
}
.p\:l\:12xn\:\!last:not(:last-child) {
  padding-left: 12.5rem;
}
.p\:none\:\!last:not(:last-child) {
  padding: 0;
}
.p\:v\:none\:\!last:not(:last-child) {
  padding-top: 0;
  padding-bottom: 0;
}
.p\:h\:none\:\!last:not(:last-child) {
  padding-right: 0;
  padding-left: 0;
}
.p\:t\:none\:\!last:not(:last-child) {
  padding-top: 0;
}
.p\:b\:none\:\!last:not(:last-child) {
  padding-bottom: 0;
}
.p\:r\:none\:\!last:not(:last-child) {
  padding-right: 0;
}
.p\:l\:none\:\!last:not(:last-child) {
  padding-left: 0;
}
.p\:auto\:\!last:not(:last-child) {
  padding: auto;
}
.p\:v\:auto\:\!last:not(:last-child) {
  padding-top: auto;
  padding-bottom: auto;
}
.p\:h\:auto\:\!last:not(:last-child) {
  padding-right: auto;
  padding-left: auto;
}
.p\:t\:auto\:\!last:not(:last-child) {
  padding-top: auto;
}
.p\:b\:auto\:\!last:not(:last-child) {
  padding-bottom: auto;
}
.p\:r\:auto\:\!last:not(:last-child) {
  padding-right: auto;
}
.p\:l\:auto\:\!last:not(:last-child) {
  padding-left: auto;
}
.p\:edge\:\!last:not(:last-child) {
  padding: 5%;
}
.p\:v\:edge\:\!last:not(:last-child) {
  padding-top: 5%;
  padding-bottom: 5%;
}
.p\:h\:edge\:\!last:not(:last-child) {
  padding-right: 5%;
  padding-left: 5%;
}
.p\:t\:edge\:\!last:not(:last-child) {
  padding-top: 5%;
}
.p\:b\:edge\:\!last:not(:last-child) {
  padding-bottom: 5%;
}
.p\:r\:edge\:\!last:not(:last-child) {
  padding-right: 5%;
}
.p\:l\:edge\:\!last:not(:last-child) {
  padding-left: 5%;
}
.p\:page\:\!last:not(:last-child) {
  padding: 5rem;
}
.p\:v\:page\:\!last:not(:last-child) {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.p\:h\:page\:\!last:not(:last-child) {
  padding-right: 5rem;
  padding-left: 5rem;
}
.p\:t\:page\:\!last:not(:last-child) {
  padding-top: 5rem;
}
.p\:b\:page\:\!last:not(:last-child) {
  padding-bottom: 5rem;
}
.p\:r\:page\:\!last:not(:last-child) {
  padding-right: 5rem;
}
.p\:l\:page\:\!last:not(:last-child) {
  padding-left: 5rem;
}
.p\:segment\:\!last:not(:last-child) {
  padding: 3rem;
}
.p\:v\:segment\:\!last:not(:last-child) {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.p\:h\:segment\:\!last:not(:last-child) {
  padding-right: 3rem;
  padding-left: 3rem;
}
.p\:t\:segment\:\!last:not(:last-child) {
  padding-top: 3rem;
}
.p\:b\:segment\:\!last:not(:last-child) {
  padding-bottom: 3rem;
}
.p\:r\:segment\:\!last:not(:last-child) {
  padding-right: 3rem;
}
.p\:l\:segment\:\!last:not(:last-child) {
  padding-left: 3rem;
}
.p\:base\:\!last:not(:last-child) {
  padding: 1rem;
}
.p\:v\:base\:\!last:not(:last-child) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.p\:h\:base\:\!last:not(:last-child) {
  padding-right: 1rem;
  padding-left: 1rem;
}
.p\:t\:base\:\!last:not(:last-child) {
  padding-top: 1rem;
}
.p\:b\:base\:\!last:not(:last-child) {
  padding-bottom: 1rem;
}
.p\:r\:base\:\!last:not(:last-child) {
  padding-right: 1rem;
}
.p\:l\:base\:\!last:not(:last-child) {
  padding-left: 1rem;
}
.p\:headlinePre\:\!last:not(:last-child) {
  padding: 1rem;
}
.p\:v\:headlinePre\:\!last:not(:last-child) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.p\:h\:headlinePre\:\!last:not(:last-child) {
  padding-right: 1rem;
  padding-left: 1rem;
}
.p\:t\:headlinePre\:\!last:not(:last-child) {
  padding-top: 1rem;
}
.p\:b\:headlinePre\:\!last:not(:last-child) {
  padding-bottom: 1rem;
}
.p\:r\:headlinePre\:\!last:not(:last-child) {
  padding-right: 1rem;
}
.p\:l\:headlinePre\:\!last:not(:last-child) {
  padding-left: 1rem;
}
.p\:headline\:\!last:not(:last-child) {
  padding: 1.25rem;
}
.p\:v\:headline\:\!last:not(:last-child) {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.p\:h\:headline\:\!last:not(:last-child) {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.p\:t\:headline\:\!last:not(:last-child) {
  padding-top: 1.25rem;
}
.p\:b\:headline\:\!last:not(:last-child) {
  padding-bottom: 1.25rem;
}
.p\:r\:headline\:\!last:not(:last-child) {
  padding-right: 1.25rem;
}
.p\:l\:headline\:\!last:not(:last-child) {
  padding-left: 1.25rem;
}
.p\:headingPre\:\!last:not(:last-child) {
  padding: 0.75rem;
}
.p\:v\:headingPre\:\!last:not(:last-child) {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.p\:h\:headingPre\:\!last:not(:last-child) {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.p\:t\:headingPre\:\!last:not(:last-child) {
  padding-top: 0.75rem;
}
.p\:b\:headingPre\:\!last:not(:last-child) {
  padding-bottom: 0.75rem;
}
.p\:r\:headingPre\:\!last:not(:last-child) {
  padding-right: 0.75rem;
}
.p\:l\:headingPre\:\!last:not(:last-child) {
  padding-left: 0.75rem;
}
.p\:heading\:\!last:not(:last-child) {
  padding: 1rem;
}
.p\:v\:heading\:\!last:not(:last-child) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.p\:h\:heading\:\!last:not(:last-child) {
  padding-right: 1rem;
  padding-left: 1rem;
}
.p\:t\:heading\:\!last:not(:last-child) {
  padding-top: 1rem;
}
.p\:b\:heading\:\!last:not(:last-child) {
  padding-bottom: 1rem;
}
.p\:r\:heading\:\!last:not(:last-child) {
  padding-right: 1rem;
}
.p\:l\:heading\:\!last:not(:last-child) {
  padding-left: 1rem;
}
.children\:m\:x1 > * {
  margin: 0.75rem;
}
.children\:m\:v\:x1 > * {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.children\:m\:h\:x1 > * {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.children\:m\:t\:x1 > * {
  margin-top: 0.75rem;
}
.children\:m\:b\:x1 > * {
  margin-bottom: 0.75rem;
}
.children\:m\:r\:x1 > * {
  margin-right: 0.75rem;
}
.children\:m\:l\:x1 > * {
  margin-left: 0.75rem;
}
.children\:m\:x2 > * {
  margin: 0.5rem;
}
.children\:m\:v\:x2 > * {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.children\:m\:h\:x2 > * {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.children\:m\:t\:x2 > * {
  margin-top: 0.5rem;
}
.children\:m\:b\:x2 > * {
  margin-bottom: 0.5rem;
}
.children\:m\:r\:x2 > * {
  margin-right: 0.5rem;
}
.children\:m\:l\:x2 > * {
  margin-left: 0.5rem;
}
.children\:m\:x3 > * {
  margin: 0.25rem;
}
.children\:m\:v\:x3 > * {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.children\:m\:h\:x3 > * {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
.children\:m\:t\:x3 > * {
  margin-top: 0.25rem;
}
.children\:m\:b\:x3 > * {
  margin-bottom: 0.25rem;
}
.children\:m\:r\:x3 > * {
  margin-right: 0.25rem;
}
.children\:m\:l\:x3 > * {
  margin-left: 0.25rem;
}
.children\:m\:1x > * {
  margin: 1rem;
}
.children\:m\:v\:1x > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.children\:m\:h\:1x > * {
  margin-right: 1rem;
  margin-left: 1rem;
}
.children\:m\:t\:1x > * {
  margin-top: 1rem;
}
.children\:m\:b\:1x > * {
  margin-bottom: 1rem;
}
.children\:m\:r\:1x > * {
  margin-right: 1rem;
}
.children\:m\:l\:1x > * {
  margin-left: 1rem;
}
.children\:m\:2x > * {
  margin: 2rem;
}
.children\:m\:v\:2x > * {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.children\:m\:h\:2x > * {
  margin-right: 2rem;
  margin-left: 2rem;
}
.children\:m\:t\:2x > * {
  margin-top: 2rem;
}
.children\:m\:b\:2x > * {
  margin-bottom: 2rem;
}
.children\:m\:r\:2x > * {
  margin-right: 2rem;
}
.children\:m\:l\:2x > * {
  margin-left: 2rem;
}
.children\:m\:3x > * {
  margin: 3rem;
}
.children\:m\:v\:3x > * {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.children\:m\:h\:3x > * {
  margin-right: 3rem;
  margin-left: 3rem;
}
.children\:m\:t\:3x > * {
  margin-top: 3rem;
}
.children\:m\:b\:3x > * {
  margin-bottom: 3rem;
}
.children\:m\:r\:3x > * {
  margin-right: 3rem;
}
.children\:m\:l\:3x > * {
  margin-left: 3rem;
}
.children\:m\:4x > * {
  margin: 4rem;
}
.children\:m\:v\:4x > * {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.children\:m\:h\:4x > * {
  margin-right: 4rem;
  margin-left: 4rem;
}
.children\:m\:t\:4x > * {
  margin-top: 4rem;
}
.children\:m\:b\:4x > * {
  margin-bottom: 4rem;
}
.children\:m\:r\:4x > * {
  margin-right: 4rem;
}
.children\:m\:l\:4x > * {
  margin-left: 4rem;
}
.children\:m\:5x > * {
  margin: 5rem;
}
.children\:m\:v\:5x > * {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.children\:m\:h\:5x > * {
  margin-right: 5rem;
  margin-left: 5rem;
}
.children\:m\:t\:5x > * {
  margin-top: 5rem;
}
.children\:m\:b\:5x > * {
  margin-bottom: 5rem;
}
.children\:m\:r\:5x > * {
  margin-right: 5rem;
}
.children\:m\:l\:5x > * {
  margin-left: 5rem;
}
.children\:m\:6x > * {
  margin: 6rem;
}
.children\:m\:v\:6x > * {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.children\:m\:h\:6x > * {
  margin-right: 6rem;
  margin-left: 6rem;
}
.children\:m\:t\:6x > * {
  margin-top: 6rem;
}
.children\:m\:b\:6x > * {
  margin-bottom: 6rem;
}
.children\:m\:r\:6x > * {
  margin-right: 6rem;
}
.children\:m\:l\:6x > * {
  margin-left: 6rem;
}
.children\:m\:7x > * {
  margin: 7rem;
}
.children\:m\:v\:7x > * {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.children\:m\:h\:7x > * {
  margin-right: 7rem;
  margin-left: 7rem;
}
.children\:m\:t\:7x > * {
  margin-top: 7rem;
}
.children\:m\:b\:7x > * {
  margin-bottom: 7rem;
}
.children\:m\:r\:7x > * {
  margin-right: 7rem;
}
.children\:m\:l\:7x > * {
  margin-left: 7rem;
}
.children\:m\:8x > * {
  margin: 8rem;
}
.children\:m\:v\:8x > * {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.children\:m\:h\:8x > * {
  margin-right: 8rem;
  margin-left: 8rem;
}
.children\:m\:t\:8x > * {
  margin-top: 8rem;
}
.children\:m\:b\:8x > * {
  margin-bottom: 8rem;
}
.children\:m\:r\:8x > * {
  margin-right: 8rem;
}
.children\:m\:l\:8x > * {
  margin-left: 8rem;
}
.children\:m\:9x > * {
  margin: 9rem;
}
.children\:m\:v\:9x > * {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.children\:m\:h\:9x > * {
  margin-right: 9rem;
  margin-left: 9rem;
}
.children\:m\:t\:9x > * {
  margin-top: 9rem;
}
.children\:m\:b\:9x > * {
  margin-bottom: 9rem;
}
.children\:m\:r\:9x > * {
  margin-right: 9rem;
}
.children\:m\:l\:9x > * {
  margin-left: 9rem;
}
.children\:m\:10x > * {
  margin: 10rem;
}
.children\:m\:v\:10x > * {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.children\:m\:h\:10x > * {
  margin-right: 10rem;
  margin-left: 10rem;
}
.children\:m\:t\:10x > * {
  margin-top: 10rem;
}
.children\:m\:b\:10x > * {
  margin-bottom: 10rem;
}
.children\:m\:r\:10x > * {
  margin-right: 10rem;
}
.children\:m\:l\:10x > * {
  margin-left: 10rem;
}
.children\:m\:11x > * {
  margin: 11rem;
}
.children\:m\:v\:11x > * {
  margin-top: 11rem;
  margin-bottom: 11rem;
}
.children\:m\:h\:11x > * {
  margin-right: 11rem;
  margin-left: 11rem;
}
.children\:m\:t\:11x > * {
  margin-top: 11rem;
}
.children\:m\:b\:11x > * {
  margin-bottom: 11rem;
}
.children\:m\:r\:11x > * {
  margin-right: 11rem;
}
.children\:m\:l\:11x > * {
  margin-left: 11rem;
}
.children\:m\:12x > * {
  margin: 12rem;
}
.children\:m\:v\:12x > * {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.children\:m\:h\:12x > * {
  margin-right: 12rem;
  margin-left: 12rem;
}
.children\:m\:t\:12x > * {
  margin-top: 12rem;
}
.children\:m\:b\:12x > * {
  margin-bottom: 12rem;
}
.children\:m\:r\:12x > * {
  margin-right: 12rem;
}
.children\:m\:l\:12x > * {
  margin-left: 12rem;
}
.children\:m\:1xn > * {
  margin: 1.5rem;
}
.children\:m\:v\:1xn > * {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.children\:m\:h\:1xn > * {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.children\:m\:t\:1xn > * {
  margin-top: 1.5rem;
}
.children\:m\:b\:1xn > * {
  margin-bottom: 1.5rem;
}
.children\:m\:r\:1xn > * {
  margin-right: 1.5rem;
}
.children\:m\:l\:1xn > * {
  margin-left: 1.5rem;
}
.children\:m\:2xn > * {
  margin: 2.5rem;
}
.children\:m\:v\:2xn > * {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.children\:m\:h\:2xn > * {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}
.children\:m\:t\:2xn > * {
  margin-top: 2.5rem;
}
.children\:m\:b\:2xn > * {
  margin-bottom: 2.5rem;
}
.children\:m\:r\:2xn > * {
  margin-right: 2.5rem;
}
.children\:m\:l\:2xn > * {
  margin-left: 2.5rem;
}
.children\:m\:3xn > * {
  margin: 3.5rem;
}
.children\:m\:v\:3xn > * {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.children\:m\:h\:3xn > * {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}
.children\:m\:t\:3xn > * {
  margin-top: 3.5rem;
}
.children\:m\:b\:3xn > * {
  margin-bottom: 3.5rem;
}
.children\:m\:r\:3xn > * {
  margin-right: 3.5rem;
}
.children\:m\:l\:3xn > * {
  margin-left: 3.5rem;
}
.children\:m\:4xn > * {
  margin: 4.5rem;
}
.children\:m\:v\:4xn > * {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
.children\:m\:h\:4xn > * {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}
.children\:m\:t\:4xn > * {
  margin-top: 4.5rem;
}
.children\:m\:b\:4xn > * {
  margin-bottom: 4.5rem;
}
.children\:m\:r\:4xn > * {
  margin-right: 4.5rem;
}
.children\:m\:l\:4xn > * {
  margin-left: 4.5rem;
}
.children\:m\:5xn > * {
  margin: 5.5rem;
}
.children\:m\:v\:5xn > * {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}
.children\:m\:h\:5xn > * {
  margin-right: 5.5rem;
  margin-left: 5.5rem;
}
.children\:m\:t\:5xn > * {
  margin-top: 5.5rem;
}
.children\:m\:b\:5xn > * {
  margin-bottom: 5.5rem;
}
.children\:m\:r\:5xn > * {
  margin-right: 5.5rem;
}
.children\:m\:l\:5xn > * {
  margin-left: 5.5rem;
}
.children\:m\:6xn > * {
  margin: 6.5rem;
}
.children\:m\:v\:6xn > * {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}
.children\:m\:h\:6xn > * {
  margin-right: 6.5rem;
  margin-left: 6.5rem;
}
.children\:m\:t\:6xn > * {
  margin-top: 6.5rem;
}
.children\:m\:b\:6xn > * {
  margin-bottom: 6.5rem;
}
.children\:m\:r\:6xn > * {
  margin-right: 6.5rem;
}
.children\:m\:l\:6xn > * {
  margin-left: 6.5rem;
}
.children\:m\:7xn > * {
  margin: 7.5rem;
}
.children\:m\:v\:7xn > * {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
.children\:m\:h\:7xn > * {
  margin-right: 7.5rem;
  margin-left: 7.5rem;
}
.children\:m\:t\:7xn > * {
  margin-top: 7.5rem;
}
.children\:m\:b\:7xn > * {
  margin-bottom: 7.5rem;
}
.children\:m\:r\:7xn > * {
  margin-right: 7.5rem;
}
.children\:m\:l\:7xn > * {
  margin-left: 7.5rem;
}
.children\:m\:8xn > * {
  margin: 8.5rem;
}
.children\:m\:v\:8xn > * {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem;
}
.children\:m\:h\:8xn > * {
  margin-right: 8.5rem;
  margin-left: 8.5rem;
}
.children\:m\:t\:8xn > * {
  margin-top: 8.5rem;
}
.children\:m\:b\:8xn > * {
  margin-bottom: 8.5rem;
}
.children\:m\:r\:8xn > * {
  margin-right: 8.5rem;
}
.children\:m\:l\:8xn > * {
  margin-left: 8.5rem;
}
.children\:m\:9xn > * {
  margin: 9.5rem;
}
.children\:m\:v\:9xn > * {
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
}
.children\:m\:h\:9xn > * {
  margin-right: 9.5rem;
  margin-left: 9.5rem;
}
.children\:m\:t\:9xn > * {
  margin-top: 9.5rem;
}
.children\:m\:b\:9xn > * {
  margin-bottom: 9.5rem;
}
.children\:m\:r\:9xn > * {
  margin-right: 9.5rem;
}
.children\:m\:l\:9xn > * {
  margin-left: 9.5rem;
}
.children\:m\:10xn > * {
  margin: 10.5rem;
}
.children\:m\:v\:10xn > * {
  margin-top: 10.5rem;
  margin-bottom: 10.5rem;
}
.children\:m\:h\:10xn > * {
  margin-right: 10.5rem;
  margin-left: 10.5rem;
}
.children\:m\:t\:10xn > * {
  margin-top: 10.5rem;
}
.children\:m\:b\:10xn > * {
  margin-bottom: 10.5rem;
}
.children\:m\:r\:10xn > * {
  margin-right: 10.5rem;
}
.children\:m\:l\:10xn > * {
  margin-left: 10.5rem;
}
.children\:m\:11xn > * {
  margin: 11.5rem;
}
.children\:m\:v\:11xn > * {
  margin-top: 11.5rem;
  margin-bottom: 11.5rem;
}
.children\:m\:h\:11xn > * {
  margin-right: 11.5rem;
  margin-left: 11.5rem;
}
.children\:m\:t\:11xn > * {
  margin-top: 11.5rem;
}
.children\:m\:b\:11xn > * {
  margin-bottom: 11.5rem;
}
.children\:m\:r\:11xn > * {
  margin-right: 11.5rem;
}
.children\:m\:l\:11xn > * {
  margin-left: 11.5rem;
}
.children\:m\:12xn > * {
  margin: 12.5rem;
}
.children\:m\:v\:12xn > * {
  margin-top: 12.5rem;
  margin-bottom: 12.5rem;
}
.children\:m\:h\:12xn > * {
  margin-right: 12.5rem;
  margin-left: 12.5rem;
}
.children\:m\:t\:12xn > * {
  margin-top: 12.5rem;
}
.children\:m\:b\:12xn > * {
  margin-bottom: 12.5rem;
}
.children\:m\:r\:12xn > * {
  margin-right: 12.5rem;
}
.children\:m\:l\:12xn > * {
  margin-left: 12.5rem;
}
.children\:m\:none > * {
  margin: 0;
}
.children\:m\:v\:none > * {
  margin-top: 0;
  margin-bottom: 0;
}
.children\:m\:h\:none > * {
  margin-right: 0;
  margin-left: 0;
}
.children\:m\:t\:none > * {
  margin-top: 0;
}
.children\:m\:b\:none > * {
  margin-bottom: 0;
}
.children\:m\:r\:none > * {
  margin-right: 0;
}
.children\:m\:l\:none > * {
  margin-left: 0;
}
.children\:m\:auto > * {
  margin: auto;
}
.children\:m\:v\:auto > * {
  margin-top: auto;
  margin-bottom: auto;
}
.children\:m\:h\:auto > * {
  margin-right: auto;
  margin-left: auto;
}
.children\:m\:t\:auto > * {
  margin-top: auto;
}
.children\:m\:b\:auto > * {
  margin-bottom: auto;
}
.children\:m\:r\:auto > * {
  margin-right: auto;
}
.children\:m\:l\:auto > * {
  margin-left: auto;
}
.children\:m\:edge > * {
  margin: 5%;
}
.children\:m\:v\:edge > * {
  margin-top: 5%;
  margin-bottom: 5%;
}
.children\:m\:h\:edge > * {
  margin-right: 5%;
  margin-left: 5%;
}
.children\:m\:t\:edge > * {
  margin-top: 5%;
}
.children\:m\:b\:edge > * {
  margin-bottom: 5%;
}
.children\:m\:r\:edge > * {
  margin-right: 5%;
}
.children\:m\:l\:edge > * {
  margin-left: 5%;
}
.children\:m\:page > * {
  margin: 5rem;
}
.children\:m\:v\:page > * {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.children\:m\:h\:page > * {
  margin-right: 5rem;
  margin-left: 5rem;
}
.children\:m\:t\:page > * {
  margin-top: 5rem;
}
.children\:m\:b\:page > * {
  margin-bottom: 5rem;
}
.children\:m\:r\:page > * {
  margin-right: 5rem;
}
.children\:m\:l\:page > * {
  margin-left: 5rem;
}
.children\:m\:segment > * {
  margin: 3rem;
}
.children\:m\:v\:segment > * {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.children\:m\:h\:segment > * {
  margin-right: 3rem;
  margin-left: 3rem;
}
.children\:m\:t\:segment > * {
  margin-top: 3rem;
}
.children\:m\:b\:segment > * {
  margin-bottom: 3rem;
}
.children\:m\:r\:segment > * {
  margin-right: 3rem;
}
.children\:m\:l\:segment > * {
  margin-left: 3rem;
}
.children\:m\:base > * {
  margin: 1rem;
}
.children\:m\:v\:base > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.children\:m\:h\:base > * {
  margin-right: 1rem;
  margin-left: 1rem;
}
.children\:m\:t\:base > * {
  margin-top: 1rem;
}
.children\:m\:b\:base > * {
  margin-bottom: 1rem;
}
.children\:m\:r\:base > * {
  margin-right: 1rem;
}
.children\:m\:l\:base > * {
  margin-left: 1rem;
}
.children\:m\:headlinePre > * {
  margin: 1rem;
}
.children\:m\:v\:headlinePre > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.children\:m\:h\:headlinePre > * {
  margin-right: 1rem;
  margin-left: 1rem;
}
.children\:m\:t\:headlinePre > * {
  margin-top: 1rem;
}
.children\:m\:b\:headlinePre > * {
  margin-bottom: 1rem;
}
.children\:m\:r\:headlinePre > * {
  margin-right: 1rem;
}
.children\:m\:l\:headlinePre > * {
  margin-left: 1rem;
}
.children\:m\:headline > * {
  margin: 1.25rem;
}
.children\:m\:v\:headline > * {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.children\:m\:h\:headline > * {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}
.children\:m\:t\:headline > * {
  margin-top: 1.25rem;
}
.children\:m\:b\:headline > * {
  margin-bottom: 1.25rem;
}
.children\:m\:r\:headline > * {
  margin-right: 1.25rem;
}
.children\:m\:l\:headline > * {
  margin-left: 1.25rem;
}
.children\:m\:headingPre > * {
  margin: 0.75rem;
}
.children\:m\:v\:headingPre > * {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.children\:m\:h\:headingPre > * {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.children\:m\:t\:headingPre > * {
  margin-top: 0.75rem;
}
.children\:m\:b\:headingPre > * {
  margin-bottom: 0.75rem;
}
.children\:m\:r\:headingPre > * {
  margin-right: 0.75rem;
}
.children\:m\:l\:headingPre > * {
  margin-left: 0.75rem;
}
.children\:m\:heading > * {
  margin: 1rem;
}
.children\:m\:v\:heading > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.children\:m\:h\:heading > * {
  margin-right: 1rem;
  margin-left: 1rem;
}
.children\:m\:t\:heading > * {
  margin-top: 1rem;
}
.children\:m\:b\:heading > * {
  margin-bottom: 1rem;
}
.children\:m\:r\:heading > * {
  margin-right: 1rem;
}
.children\:m\:l\:heading > * {
  margin-left: 1rem;
}
.children\:m\:x1\:\!last > *:not(:last-child) {
  margin: 0.75rem;
}
.children\:m\:v\:x1\:\!last > *:not(:last-child) {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.children\:m\:h\:x1\:\!last > *:not(:last-child) {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.children\:m\:t\:x1\:\!last > *:not(:last-child) {
  margin-top: 0.75rem;
}
.children\:m\:b\:x1\:\!last > *:not(:last-child) {
  margin-bottom: 0.75rem;
}
.children\:m\:r\:x1\:\!last > *:not(:last-child) {
  margin-right: 0.75rem;
}
.children\:m\:l\:x1\:\!last > *:not(:last-child) {
  margin-left: 0.75rem;
}
.children\:m\:x2\:\!last > *:not(:last-child) {
  margin: 0.5rem;
}
.children\:m\:v\:x2\:\!last > *:not(:last-child) {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.children\:m\:h\:x2\:\!last > *:not(:last-child) {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.children\:m\:t\:x2\:\!last > *:not(:last-child) {
  margin-top: 0.5rem;
}
.children\:m\:b\:x2\:\!last > *:not(:last-child) {
  margin-bottom: 0.5rem;
}
.children\:m\:r\:x2\:\!last > *:not(:last-child) {
  margin-right: 0.5rem;
}
.children\:m\:l\:x2\:\!last > *:not(:last-child) {
  margin-left: 0.5rem;
}
.children\:m\:x3\:\!last > *:not(:last-child) {
  margin: 0.25rem;
}
.children\:m\:v\:x3\:\!last > *:not(:last-child) {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.children\:m\:h\:x3\:\!last > *:not(:last-child) {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
.children\:m\:t\:x3\:\!last > *:not(:last-child) {
  margin-top: 0.25rem;
}
.children\:m\:b\:x3\:\!last > *:not(:last-child) {
  margin-bottom: 0.25rem;
}
.children\:m\:r\:x3\:\!last > *:not(:last-child) {
  margin-right: 0.25rem;
}
.children\:m\:l\:x3\:\!last > *:not(:last-child) {
  margin-left: 0.25rem;
}
.children\:m\:1x\:\!last > *:not(:last-child) {
  margin: 1rem;
}
.children\:m\:v\:1x\:\!last > *:not(:last-child) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.children\:m\:h\:1x\:\!last > *:not(:last-child) {
  margin-right: 1rem;
  margin-left: 1rem;
}
.children\:m\:t\:1x\:\!last > *:not(:last-child) {
  margin-top: 1rem;
}
.children\:m\:b\:1x\:\!last > *:not(:last-child) {
  margin-bottom: 1rem;
}
.children\:m\:r\:1x\:\!last > *:not(:last-child) {
  margin-right: 1rem;
}
.children\:m\:l\:1x\:\!last > *:not(:last-child) {
  margin-left: 1rem;
}
.children\:m\:2x\:\!last > *:not(:last-child) {
  margin: 2rem;
}
.children\:m\:v\:2x\:\!last > *:not(:last-child) {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.children\:m\:h\:2x\:\!last > *:not(:last-child) {
  margin-right: 2rem;
  margin-left: 2rem;
}
.children\:m\:t\:2x\:\!last > *:not(:last-child) {
  margin-top: 2rem;
}
.children\:m\:b\:2x\:\!last > *:not(:last-child) {
  margin-bottom: 2rem;
}
.children\:m\:r\:2x\:\!last > *:not(:last-child) {
  margin-right: 2rem;
}
.children\:m\:l\:2x\:\!last > *:not(:last-child) {
  margin-left: 2rem;
}
.children\:m\:3x\:\!last > *:not(:last-child) {
  margin: 3rem;
}
.children\:m\:v\:3x\:\!last > *:not(:last-child) {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.children\:m\:h\:3x\:\!last > *:not(:last-child) {
  margin-right: 3rem;
  margin-left: 3rem;
}
.children\:m\:t\:3x\:\!last > *:not(:last-child) {
  margin-top: 3rem;
}
.children\:m\:b\:3x\:\!last > *:not(:last-child) {
  margin-bottom: 3rem;
}
.children\:m\:r\:3x\:\!last > *:not(:last-child) {
  margin-right: 3rem;
}
.children\:m\:l\:3x\:\!last > *:not(:last-child) {
  margin-left: 3rem;
}
.children\:m\:4x\:\!last > *:not(:last-child) {
  margin: 4rem;
}
.children\:m\:v\:4x\:\!last > *:not(:last-child) {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.children\:m\:h\:4x\:\!last > *:not(:last-child) {
  margin-right: 4rem;
  margin-left: 4rem;
}
.children\:m\:t\:4x\:\!last > *:not(:last-child) {
  margin-top: 4rem;
}
.children\:m\:b\:4x\:\!last > *:not(:last-child) {
  margin-bottom: 4rem;
}
.children\:m\:r\:4x\:\!last > *:not(:last-child) {
  margin-right: 4rem;
}
.children\:m\:l\:4x\:\!last > *:not(:last-child) {
  margin-left: 4rem;
}
.children\:m\:5x\:\!last > *:not(:last-child) {
  margin: 5rem;
}
.children\:m\:v\:5x\:\!last > *:not(:last-child) {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.children\:m\:h\:5x\:\!last > *:not(:last-child) {
  margin-right: 5rem;
  margin-left: 5rem;
}
.children\:m\:t\:5x\:\!last > *:not(:last-child) {
  margin-top: 5rem;
}
.children\:m\:b\:5x\:\!last > *:not(:last-child) {
  margin-bottom: 5rem;
}
.children\:m\:r\:5x\:\!last > *:not(:last-child) {
  margin-right: 5rem;
}
.children\:m\:l\:5x\:\!last > *:not(:last-child) {
  margin-left: 5rem;
}
.children\:m\:6x\:\!last > *:not(:last-child) {
  margin: 6rem;
}
.children\:m\:v\:6x\:\!last > *:not(:last-child) {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.children\:m\:h\:6x\:\!last > *:not(:last-child) {
  margin-right: 6rem;
  margin-left: 6rem;
}
.children\:m\:t\:6x\:\!last > *:not(:last-child) {
  margin-top: 6rem;
}
.children\:m\:b\:6x\:\!last > *:not(:last-child) {
  margin-bottom: 6rem;
}
.children\:m\:r\:6x\:\!last > *:not(:last-child) {
  margin-right: 6rem;
}
.children\:m\:l\:6x\:\!last > *:not(:last-child) {
  margin-left: 6rem;
}
.children\:m\:7x\:\!last > *:not(:last-child) {
  margin: 7rem;
}
.children\:m\:v\:7x\:\!last > *:not(:last-child) {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.children\:m\:h\:7x\:\!last > *:not(:last-child) {
  margin-right: 7rem;
  margin-left: 7rem;
}
.children\:m\:t\:7x\:\!last > *:not(:last-child) {
  margin-top: 7rem;
}
.children\:m\:b\:7x\:\!last > *:not(:last-child) {
  margin-bottom: 7rem;
}
.children\:m\:r\:7x\:\!last > *:not(:last-child) {
  margin-right: 7rem;
}
.children\:m\:l\:7x\:\!last > *:not(:last-child) {
  margin-left: 7rem;
}
.children\:m\:8x\:\!last > *:not(:last-child) {
  margin: 8rem;
}
.children\:m\:v\:8x\:\!last > *:not(:last-child) {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.children\:m\:h\:8x\:\!last > *:not(:last-child) {
  margin-right: 8rem;
  margin-left: 8rem;
}
.children\:m\:t\:8x\:\!last > *:not(:last-child) {
  margin-top: 8rem;
}
.children\:m\:b\:8x\:\!last > *:not(:last-child) {
  margin-bottom: 8rem;
}
.children\:m\:r\:8x\:\!last > *:not(:last-child) {
  margin-right: 8rem;
}
.children\:m\:l\:8x\:\!last > *:not(:last-child) {
  margin-left: 8rem;
}
.children\:m\:9x\:\!last > *:not(:last-child) {
  margin: 9rem;
}
.children\:m\:v\:9x\:\!last > *:not(:last-child) {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.children\:m\:h\:9x\:\!last > *:not(:last-child) {
  margin-right: 9rem;
  margin-left: 9rem;
}
.children\:m\:t\:9x\:\!last > *:not(:last-child) {
  margin-top: 9rem;
}
.children\:m\:b\:9x\:\!last > *:not(:last-child) {
  margin-bottom: 9rem;
}
.children\:m\:r\:9x\:\!last > *:not(:last-child) {
  margin-right: 9rem;
}
.children\:m\:l\:9x\:\!last > *:not(:last-child) {
  margin-left: 9rem;
}
.children\:m\:10x\:\!last > *:not(:last-child) {
  margin: 10rem;
}
.children\:m\:v\:10x\:\!last > *:not(:last-child) {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.children\:m\:h\:10x\:\!last > *:not(:last-child) {
  margin-right: 10rem;
  margin-left: 10rem;
}
.children\:m\:t\:10x\:\!last > *:not(:last-child) {
  margin-top: 10rem;
}
.children\:m\:b\:10x\:\!last > *:not(:last-child) {
  margin-bottom: 10rem;
}
.children\:m\:r\:10x\:\!last > *:not(:last-child) {
  margin-right: 10rem;
}
.children\:m\:l\:10x\:\!last > *:not(:last-child) {
  margin-left: 10rem;
}
.children\:m\:11x\:\!last > *:not(:last-child) {
  margin: 11rem;
}
.children\:m\:v\:11x\:\!last > *:not(:last-child) {
  margin-top: 11rem;
  margin-bottom: 11rem;
}
.children\:m\:h\:11x\:\!last > *:not(:last-child) {
  margin-right: 11rem;
  margin-left: 11rem;
}
.children\:m\:t\:11x\:\!last > *:not(:last-child) {
  margin-top: 11rem;
}
.children\:m\:b\:11x\:\!last > *:not(:last-child) {
  margin-bottom: 11rem;
}
.children\:m\:r\:11x\:\!last > *:not(:last-child) {
  margin-right: 11rem;
}
.children\:m\:l\:11x\:\!last > *:not(:last-child) {
  margin-left: 11rem;
}
.children\:m\:12x\:\!last > *:not(:last-child) {
  margin: 12rem;
}
.children\:m\:v\:12x\:\!last > *:not(:last-child) {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.children\:m\:h\:12x\:\!last > *:not(:last-child) {
  margin-right: 12rem;
  margin-left: 12rem;
}
.children\:m\:t\:12x\:\!last > *:not(:last-child) {
  margin-top: 12rem;
}
.children\:m\:b\:12x\:\!last > *:not(:last-child) {
  margin-bottom: 12rem;
}
.children\:m\:r\:12x\:\!last > *:not(:last-child) {
  margin-right: 12rem;
}
.children\:m\:l\:12x\:\!last > *:not(:last-child) {
  margin-left: 12rem;
}
.children\:m\:1xn\:\!last > *:not(:last-child) {
  margin: 1.5rem;
}
.children\:m\:v\:1xn\:\!last > *:not(:last-child) {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.children\:m\:h\:1xn\:\!last > *:not(:last-child) {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.children\:m\:t\:1xn\:\!last > *:not(:last-child) {
  margin-top: 1.5rem;
}
.children\:m\:b\:1xn\:\!last > *:not(:last-child) {
  margin-bottom: 1.5rem;
}
.children\:m\:r\:1xn\:\!last > *:not(:last-child) {
  margin-right: 1.5rem;
}
.children\:m\:l\:1xn\:\!last > *:not(:last-child) {
  margin-left: 1.5rem;
}
.children\:m\:2xn\:\!last > *:not(:last-child) {
  margin: 2.5rem;
}
.children\:m\:v\:2xn\:\!last > *:not(:last-child) {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.children\:m\:h\:2xn\:\!last > *:not(:last-child) {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}
.children\:m\:t\:2xn\:\!last > *:not(:last-child) {
  margin-top: 2.5rem;
}
.children\:m\:b\:2xn\:\!last > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.children\:m\:r\:2xn\:\!last > *:not(:last-child) {
  margin-right: 2.5rem;
}
.children\:m\:l\:2xn\:\!last > *:not(:last-child) {
  margin-left: 2.5rem;
}
.children\:m\:3xn\:\!last > *:not(:last-child) {
  margin: 3.5rem;
}
.children\:m\:v\:3xn\:\!last > *:not(:last-child) {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.children\:m\:h\:3xn\:\!last > *:not(:last-child) {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}
.children\:m\:t\:3xn\:\!last > *:not(:last-child) {
  margin-top: 3.5rem;
}
.children\:m\:b\:3xn\:\!last > *:not(:last-child) {
  margin-bottom: 3.5rem;
}
.children\:m\:r\:3xn\:\!last > *:not(:last-child) {
  margin-right: 3.5rem;
}
.children\:m\:l\:3xn\:\!last > *:not(:last-child) {
  margin-left: 3.5rem;
}
.children\:m\:4xn\:\!last > *:not(:last-child) {
  margin: 4.5rem;
}
.children\:m\:v\:4xn\:\!last > *:not(:last-child) {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
.children\:m\:h\:4xn\:\!last > *:not(:last-child) {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}
.children\:m\:t\:4xn\:\!last > *:not(:last-child) {
  margin-top: 4.5rem;
}
.children\:m\:b\:4xn\:\!last > *:not(:last-child) {
  margin-bottom: 4.5rem;
}
.children\:m\:r\:4xn\:\!last > *:not(:last-child) {
  margin-right: 4.5rem;
}
.children\:m\:l\:4xn\:\!last > *:not(:last-child) {
  margin-left: 4.5rem;
}
.children\:m\:5xn\:\!last > *:not(:last-child) {
  margin: 5.5rem;
}
.children\:m\:v\:5xn\:\!last > *:not(:last-child) {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}
.children\:m\:h\:5xn\:\!last > *:not(:last-child) {
  margin-right: 5.5rem;
  margin-left: 5.5rem;
}
.children\:m\:t\:5xn\:\!last > *:not(:last-child) {
  margin-top: 5.5rem;
}
.children\:m\:b\:5xn\:\!last > *:not(:last-child) {
  margin-bottom: 5.5rem;
}
.children\:m\:r\:5xn\:\!last > *:not(:last-child) {
  margin-right: 5.5rem;
}
.children\:m\:l\:5xn\:\!last > *:not(:last-child) {
  margin-left: 5.5rem;
}
.children\:m\:6xn\:\!last > *:not(:last-child) {
  margin: 6.5rem;
}
.children\:m\:v\:6xn\:\!last > *:not(:last-child) {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}
.children\:m\:h\:6xn\:\!last > *:not(:last-child) {
  margin-right: 6.5rem;
  margin-left: 6.5rem;
}
.children\:m\:t\:6xn\:\!last > *:not(:last-child) {
  margin-top: 6.5rem;
}
.children\:m\:b\:6xn\:\!last > *:not(:last-child) {
  margin-bottom: 6.5rem;
}
.children\:m\:r\:6xn\:\!last > *:not(:last-child) {
  margin-right: 6.5rem;
}
.children\:m\:l\:6xn\:\!last > *:not(:last-child) {
  margin-left: 6.5rem;
}
.children\:m\:7xn\:\!last > *:not(:last-child) {
  margin: 7.5rem;
}
.children\:m\:v\:7xn\:\!last > *:not(:last-child) {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
.children\:m\:h\:7xn\:\!last > *:not(:last-child) {
  margin-right: 7.5rem;
  margin-left: 7.5rem;
}
.children\:m\:t\:7xn\:\!last > *:not(:last-child) {
  margin-top: 7.5rem;
}
.children\:m\:b\:7xn\:\!last > *:not(:last-child) {
  margin-bottom: 7.5rem;
}
.children\:m\:r\:7xn\:\!last > *:not(:last-child) {
  margin-right: 7.5rem;
}
.children\:m\:l\:7xn\:\!last > *:not(:last-child) {
  margin-left: 7.5rem;
}
.children\:m\:8xn\:\!last > *:not(:last-child) {
  margin: 8.5rem;
}
.children\:m\:v\:8xn\:\!last > *:not(:last-child) {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem;
}
.children\:m\:h\:8xn\:\!last > *:not(:last-child) {
  margin-right: 8.5rem;
  margin-left: 8.5rem;
}
.children\:m\:t\:8xn\:\!last > *:not(:last-child) {
  margin-top: 8.5rem;
}
.children\:m\:b\:8xn\:\!last > *:not(:last-child) {
  margin-bottom: 8.5rem;
}
.children\:m\:r\:8xn\:\!last > *:not(:last-child) {
  margin-right: 8.5rem;
}
.children\:m\:l\:8xn\:\!last > *:not(:last-child) {
  margin-left: 8.5rem;
}
.children\:m\:9xn\:\!last > *:not(:last-child) {
  margin: 9.5rem;
}
.children\:m\:v\:9xn\:\!last > *:not(:last-child) {
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
}
.children\:m\:h\:9xn\:\!last > *:not(:last-child) {
  margin-right: 9.5rem;
  margin-left: 9.5rem;
}
.children\:m\:t\:9xn\:\!last > *:not(:last-child) {
  margin-top: 9.5rem;
}
.children\:m\:b\:9xn\:\!last > *:not(:last-child) {
  margin-bottom: 9.5rem;
}
.children\:m\:r\:9xn\:\!last > *:not(:last-child) {
  margin-right: 9.5rem;
}
.children\:m\:l\:9xn\:\!last > *:not(:last-child) {
  margin-left: 9.5rem;
}
.children\:m\:10xn\:\!last > *:not(:last-child) {
  margin: 10.5rem;
}
.children\:m\:v\:10xn\:\!last > *:not(:last-child) {
  margin-top: 10.5rem;
  margin-bottom: 10.5rem;
}
.children\:m\:h\:10xn\:\!last > *:not(:last-child) {
  margin-right: 10.5rem;
  margin-left: 10.5rem;
}
.children\:m\:t\:10xn\:\!last > *:not(:last-child) {
  margin-top: 10.5rem;
}
.children\:m\:b\:10xn\:\!last > *:not(:last-child) {
  margin-bottom: 10.5rem;
}
.children\:m\:r\:10xn\:\!last > *:not(:last-child) {
  margin-right: 10.5rem;
}
.children\:m\:l\:10xn\:\!last > *:not(:last-child) {
  margin-left: 10.5rem;
}
.children\:m\:11xn\:\!last > *:not(:last-child) {
  margin: 11.5rem;
}
.children\:m\:v\:11xn\:\!last > *:not(:last-child) {
  margin-top: 11.5rem;
  margin-bottom: 11.5rem;
}
.children\:m\:h\:11xn\:\!last > *:not(:last-child) {
  margin-right: 11.5rem;
  margin-left: 11.5rem;
}
.children\:m\:t\:11xn\:\!last > *:not(:last-child) {
  margin-top: 11.5rem;
}
.children\:m\:b\:11xn\:\!last > *:not(:last-child) {
  margin-bottom: 11.5rem;
}
.children\:m\:r\:11xn\:\!last > *:not(:last-child) {
  margin-right: 11.5rem;
}
.children\:m\:l\:11xn\:\!last > *:not(:last-child) {
  margin-left: 11.5rem;
}
.children\:m\:12xn\:\!last > *:not(:last-child) {
  margin: 12.5rem;
}
.children\:m\:v\:12xn\:\!last > *:not(:last-child) {
  margin-top: 12.5rem;
  margin-bottom: 12.5rem;
}
.children\:m\:h\:12xn\:\!last > *:not(:last-child) {
  margin-right: 12.5rem;
  margin-left: 12.5rem;
}
.children\:m\:t\:12xn\:\!last > *:not(:last-child) {
  margin-top: 12.5rem;
}
.children\:m\:b\:12xn\:\!last > *:not(:last-child) {
  margin-bottom: 12.5rem;
}
.children\:m\:r\:12xn\:\!last > *:not(:last-child) {
  margin-right: 12.5rem;
}
.children\:m\:l\:12xn\:\!last > *:not(:last-child) {
  margin-left: 12.5rem;
}
.children\:m\:none\:\!last > *:not(:last-child) {
  margin: 0;
}
.children\:m\:v\:none\:\!last > *:not(:last-child) {
  margin-top: 0;
  margin-bottom: 0;
}
.children\:m\:h\:none\:\!last > *:not(:last-child) {
  margin-right: 0;
  margin-left: 0;
}
.children\:m\:t\:none\:\!last > *:not(:last-child) {
  margin-top: 0;
}
.children\:m\:b\:none\:\!last > *:not(:last-child) {
  margin-bottom: 0;
}
.children\:m\:r\:none\:\!last > *:not(:last-child) {
  margin-right: 0;
}
.children\:m\:l\:none\:\!last > *:not(:last-child) {
  margin-left: 0;
}
.children\:m\:auto\:\!last > *:not(:last-child) {
  margin: auto;
}
.children\:m\:v\:auto\:\!last > *:not(:last-child) {
  margin-top: auto;
  margin-bottom: auto;
}
.children\:m\:h\:auto\:\!last > *:not(:last-child) {
  margin-right: auto;
  margin-left: auto;
}
.children\:m\:t\:auto\:\!last > *:not(:last-child) {
  margin-top: auto;
}
.children\:m\:b\:auto\:\!last > *:not(:last-child) {
  margin-bottom: auto;
}
.children\:m\:r\:auto\:\!last > *:not(:last-child) {
  margin-right: auto;
}
.children\:m\:l\:auto\:\!last > *:not(:last-child) {
  margin-left: auto;
}
.children\:m\:edge\:\!last > *:not(:last-child) {
  margin: 5%;
}
.children\:m\:v\:edge\:\!last > *:not(:last-child) {
  margin-top: 5%;
  margin-bottom: 5%;
}
.children\:m\:h\:edge\:\!last > *:not(:last-child) {
  margin-right: 5%;
  margin-left: 5%;
}
.children\:m\:t\:edge\:\!last > *:not(:last-child) {
  margin-top: 5%;
}
.children\:m\:b\:edge\:\!last > *:not(:last-child) {
  margin-bottom: 5%;
}
.children\:m\:r\:edge\:\!last > *:not(:last-child) {
  margin-right: 5%;
}
.children\:m\:l\:edge\:\!last > *:not(:last-child) {
  margin-left: 5%;
}
.children\:m\:page\:\!last > *:not(:last-child) {
  margin: 5rem;
}
.children\:m\:v\:page\:\!last > *:not(:last-child) {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.children\:m\:h\:page\:\!last > *:not(:last-child) {
  margin-right: 5rem;
  margin-left: 5rem;
}
.children\:m\:t\:page\:\!last > *:not(:last-child) {
  margin-top: 5rem;
}
.children\:m\:b\:page\:\!last > *:not(:last-child) {
  margin-bottom: 5rem;
}
.children\:m\:r\:page\:\!last > *:not(:last-child) {
  margin-right: 5rem;
}
.children\:m\:l\:page\:\!last > *:not(:last-child) {
  margin-left: 5rem;
}
.children\:m\:segment\:\!last > *:not(:last-child) {
  margin: 3rem;
}
.children\:m\:v\:segment\:\!last > *:not(:last-child) {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.children\:m\:h\:segment\:\!last > *:not(:last-child) {
  margin-right: 3rem;
  margin-left: 3rem;
}
.children\:m\:t\:segment\:\!last > *:not(:last-child) {
  margin-top: 3rem;
}
.children\:m\:b\:segment\:\!last > *:not(:last-child) {
  margin-bottom: 3rem;
}
.children\:m\:r\:segment\:\!last > *:not(:last-child) {
  margin-right: 3rem;
}
.children\:m\:l\:segment\:\!last > *:not(:last-child) {
  margin-left: 3rem;
}
.children\:m\:base\:\!last > *:not(:last-child) {
  margin: 1rem;
}
.children\:m\:v\:base\:\!last > *:not(:last-child) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.children\:m\:h\:base\:\!last > *:not(:last-child) {
  margin-right: 1rem;
  margin-left: 1rem;
}
.children\:m\:t\:base\:\!last > *:not(:last-child) {
  margin-top: 1rem;
}
.children\:m\:b\:base\:\!last > *:not(:last-child) {
  margin-bottom: 1rem;
}
.children\:m\:r\:base\:\!last > *:not(:last-child) {
  margin-right: 1rem;
}
.children\:m\:l\:base\:\!last > *:not(:last-child) {
  margin-left: 1rem;
}
.children\:m\:headlinePre\:\!last > *:not(:last-child) {
  margin: 1rem;
}
.children\:m\:v\:headlinePre\:\!last > *:not(:last-child) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.children\:m\:h\:headlinePre\:\!last > *:not(:last-child) {
  margin-right: 1rem;
  margin-left: 1rem;
}
.children\:m\:t\:headlinePre\:\!last > *:not(:last-child) {
  margin-top: 1rem;
}
.children\:m\:b\:headlinePre\:\!last > *:not(:last-child) {
  margin-bottom: 1rem;
}
.children\:m\:r\:headlinePre\:\!last > *:not(:last-child) {
  margin-right: 1rem;
}
.children\:m\:l\:headlinePre\:\!last > *:not(:last-child) {
  margin-left: 1rem;
}
.children\:m\:headline\:\!last > *:not(:last-child) {
  margin: 1.25rem;
}
.children\:m\:v\:headline\:\!last > *:not(:last-child) {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.children\:m\:h\:headline\:\!last > *:not(:last-child) {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}
.children\:m\:t\:headline\:\!last > *:not(:last-child) {
  margin-top: 1.25rem;
}
.children\:m\:b\:headline\:\!last > *:not(:last-child) {
  margin-bottom: 1.25rem;
}
.children\:m\:r\:headline\:\!last > *:not(:last-child) {
  margin-right: 1.25rem;
}
.children\:m\:l\:headline\:\!last > *:not(:last-child) {
  margin-left: 1.25rem;
}
.children\:m\:headingPre\:\!last > *:not(:last-child) {
  margin: 0.75rem;
}
.children\:m\:v\:headingPre\:\!last > *:not(:last-child) {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.children\:m\:h\:headingPre\:\!last > *:not(:last-child) {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.children\:m\:t\:headingPre\:\!last > *:not(:last-child) {
  margin-top: 0.75rem;
}
.children\:m\:b\:headingPre\:\!last > *:not(:last-child) {
  margin-bottom: 0.75rem;
}
.children\:m\:r\:headingPre\:\!last > *:not(:last-child) {
  margin-right: 0.75rem;
}
.children\:m\:l\:headingPre\:\!last > *:not(:last-child) {
  margin-left: 0.75rem;
}
.children\:m\:heading\:\!last > *:not(:last-child) {
  margin: 1rem;
}
.children\:m\:v\:heading\:\!last > *:not(:last-child) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.children\:m\:h\:heading\:\!last > *:not(:last-child) {
  margin-right: 1rem;
  margin-left: 1rem;
}
.children\:m\:t\:heading\:\!last > *:not(:last-child) {
  margin-top: 1rem;
}
.children\:m\:b\:heading\:\!last > *:not(:last-child) {
  margin-bottom: 1rem;
}
.children\:m\:r\:heading\:\!last > *:not(:last-child) {
  margin-right: 1rem;
}
.children\:m\:l\:heading\:\!last > *:not(:last-child) {
  margin-left: 1rem;
}
.children\:p\:x1 > * {
  padding: 0.75rem;
}
.children\:p\:v\:x1 > * {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.children\:p\:h\:x1 > * {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.children\:p\:t\:x1 > * {
  padding-top: 0.75rem;
}
.children\:p\:b\:x1 > * {
  padding-bottom: 0.75rem;
}
.children\:p\:r\:x1 > * {
  padding-right: 0.75rem;
}
.children\:p\:l\:x1 > * {
  padding-left: 0.75rem;
}
.children\:p\:x2 > * {
  padding: 0.5rem;
}
.children\:p\:v\:x2 > * {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.children\:p\:h\:x2 > * {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.children\:p\:t\:x2 > * {
  padding-top: 0.5rem;
}
.children\:p\:b\:x2 > * {
  padding-bottom: 0.5rem;
}
.children\:p\:r\:x2 > * {
  padding-right: 0.5rem;
}
.children\:p\:l\:x2 > * {
  padding-left: 0.5rem;
}
.children\:p\:x3 > * {
  padding: 0.25rem;
}
.children\:p\:v\:x3 > * {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.children\:p\:h\:x3 > * {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
.children\:p\:t\:x3 > * {
  padding-top: 0.25rem;
}
.children\:p\:b\:x3 > * {
  padding-bottom: 0.25rem;
}
.children\:p\:r\:x3 > * {
  padding-right: 0.25rem;
}
.children\:p\:l\:x3 > * {
  padding-left: 0.25rem;
}
.children\:p\:1x > * {
  padding: 1rem;
}
.children\:p\:v\:1x > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.children\:p\:h\:1x > * {
  padding-right: 1rem;
  padding-left: 1rem;
}
.children\:p\:t\:1x > * {
  padding-top: 1rem;
}
.children\:p\:b\:1x > * {
  padding-bottom: 1rem;
}
.children\:p\:r\:1x > * {
  padding-right: 1rem;
}
.children\:p\:l\:1x > * {
  padding-left: 1rem;
}
.children\:p\:2x > * {
  padding: 2rem;
}
.children\:p\:v\:2x > * {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.children\:p\:h\:2x > * {
  padding-right: 2rem;
  padding-left: 2rem;
}
.children\:p\:t\:2x > * {
  padding-top: 2rem;
}
.children\:p\:b\:2x > * {
  padding-bottom: 2rem;
}
.children\:p\:r\:2x > * {
  padding-right: 2rem;
}
.children\:p\:l\:2x > * {
  padding-left: 2rem;
}
.children\:p\:3x > * {
  padding: 3rem;
}
.children\:p\:v\:3x > * {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.children\:p\:h\:3x > * {
  padding-right: 3rem;
  padding-left: 3rem;
}
.children\:p\:t\:3x > * {
  padding-top: 3rem;
}
.children\:p\:b\:3x > * {
  padding-bottom: 3rem;
}
.children\:p\:r\:3x > * {
  padding-right: 3rem;
}
.children\:p\:l\:3x > * {
  padding-left: 3rem;
}
.children\:p\:4x > * {
  padding: 4rem;
}
.children\:p\:v\:4x > * {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.children\:p\:h\:4x > * {
  padding-right: 4rem;
  padding-left: 4rem;
}
.children\:p\:t\:4x > * {
  padding-top: 4rem;
}
.children\:p\:b\:4x > * {
  padding-bottom: 4rem;
}
.children\:p\:r\:4x > * {
  padding-right: 4rem;
}
.children\:p\:l\:4x > * {
  padding-left: 4rem;
}
.children\:p\:5x > * {
  padding: 5rem;
}
.children\:p\:v\:5x > * {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.children\:p\:h\:5x > * {
  padding-right: 5rem;
  padding-left: 5rem;
}
.children\:p\:t\:5x > * {
  padding-top: 5rem;
}
.children\:p\:b\:5x > * {
  padding-bottom: 5rem;
}
.children\:p\:r\:5x > * {
  padding-right: 5rem;
}
.children\:p\:l\:5x > * {
  padding-left: 5rem;
}
.children\:p\:6x > * {
  padding: 6rem;
}
.children\:p\:v\:6x > * {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.children\:p\:h\:6x > * {
  padding-right: 6rem;
  padding-left: 6rem;
}
.children\:p\:t\:6x > * {
  padding-top: 6rem;
}
.children\:p\:b\:6x > * {
  padding-bottom: 6rem;
}
.children\:p\:r\:6x > * {
  padding-right: 6rem;
}
.children\:p\:l\:6x > * {
  padding-left: 6rem;
}
.children\:p\:7x > * {
  padding: 7rem;
}
.children\:p\:v\:7x > * {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.children\:p\:h\:7x > * {
  padding-right: 7rem;
  padding-left: 7rem;
}
.children\:p\:t\:7x > * {
  padding-top: 7rem;
}
.children\:p\:b\:7x > * {
  padding-bottom: 7rem;
}
.children\:p\:r\:7x > * {
  padding-right: 7rem;
}
.children\:p\:l\:7x > * {
  padding-left: 7rem;
}
.children\:p\:8x > * {
  padding: 8rem;
}
.children\:p\:v\:8x > * {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.children\:p\:h\:8x > * {
  padding-right: 8rem;
  padding-left: 8rem;
}
.children\:p\:t\:8x > * {
  padding-top: 8rem;
}
.children\:p\:b\:8x > * {
  padding-bottom: 8rem;
}
.children\:p\:r\:8x > * {
  padding-right: 8rem;
}
.children\:p\:l\:8x > * {
  padding-left: 8rem;
}
.children\:p\:9x > * {
  padding: 9rem;
}
.children\:p\:v\:9x > * {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.children\:p\:h\:9x > * {
  padding-right: 9rem;
  padding-left: 9rem;
}
.children\:p\:t\:9x > * {
  padding-top: 9rem;
}
.children\:p\:b\:9x > * {
  padding-bottom: 9rem;
}
.children\:p\:r\:9x > * {
  padding-right: 9rem;
}
.children\:p\:l\:9x > * {
  padding-left: 9rem;
}
.children\:p\:10x > * {
  padding: 10rem;
}
.children\:p\:v\:10x > * {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.children\:p\:h\:10x > * {
  padding-right: 10rem;
  padding-left: 10rem;
}
.children\:p\:t\:10x > * {
  padding-top: 10rem;
}
.children\:p\:b\:10x > * {
  padding-bottom: 10rem;
}
.children\:p\:r\:10x > * {
  padding-right: 10rem;
}
.children\:p\:l\:10x > * {
  padding-left: 10rem;
}
.children\:p\:11x > * {
  padding: 11rem;
}
.children\:p\:v\:11x > * {
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.children\:p\:h\:11x > * {
  padding-right: 11rem;
  padding-left: 11rem;
}
.children\:p\:t\:11x > * {
  padding-top: 11rem;
}
.children\:p\:b\:11x > * {
  padding-bottom: 11rem;
}
.children\:p\:r\:11x > * {
  padding-right: 11rem;
}
.children\:p\:l\:11x > * {
  padding-left: 11rem;
}
.children\:p\:12x > * {
  padding: 12rem;
}
.children\:p\:v\:12x > * {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.children\:p\:h\:12x > * {
  padding-right: 12rem;
  padding-left: 12rem;
}
.children\:p\:t\:12x > * {
  padding-top: 12rem;
}
.children\:p\:b\:12x > * {
  padding-bottom: 12rem;
}
.children\:p\:r\:12x > * {
  padding-right: 12rem;
}
.children\:p\:l\:12x > * {
  padding-left: 12rem;
}
.children\:p\:1xn > * {
  padding: 1.5rem;
}
.children\:p\:v\:1xn > * {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.children\:p\:h\:1xn > * {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.children\:p\:t\:1xn > * {
  padding-top: 1.5rem;
}
.children\:p\:b\:1xn > * {
  padding-bottom: 1.5rem;
}
.children\:p\:r\:1xn > * {
  padding-right: 1.5rem;
}
.children\:p\:l\:1xn > * {
  padding-left: 1.5rem;
}
.children\:p\:2xn > * {
  padding: 2.5rem;
}
.children\:p\:v\:2xn > * {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.children\:p\:h\:2xn > * {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.children\:p\:t\:2xn > * {
  padding-top: 2.5rem;
}
.children\:p\:b\:2xn > * {
  padding-bottom: 2.5rem;
}
.children\:p\:r\:2xn > * {
  padding-right: 2.5rem;
}
.children\:p\:l\:2xn > * {
  padding-left: 2.5rem;
}
.children\:p\:3xn > * {
  padding: 3.5rem;
}
.children\:p\:v\:3xn > * {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.children\:p\:h\:3xn > * {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}
.children\:p\:t\:3xn > * {
  padding-top: 3.5rem;
}
.children\:p\:b\:3xn > * {
  padding-bottom: 3.5rem;
}
.children\:p\:r\:3xn > * {
  padding-right: 3.5rem;
}
.children\:p\:l\:3xn > * {
  padding-left: 3.5rem;
}
.children\:p\:4xn > * {
  padding: 4.5rem;
}
.children\:p\:v\:4xn > * {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
.children\:p\:h\:4xn > * {
  padding-right: 4.5rem;
  padding-left: 4.5rem;
}
.children\:p\:t\:4xn > * {
  padding-top: 4.5rem;
}
.children\:p\:b\:4xn > * {
  padding-bottom: 4.5rem;
}
.children\:p\:r\:4xn > * {
  padding-right: 4.5rem;
}
.children\:p\:l\:4xn > * {
  padding-left: 4.5rem;
}
.children\:p\:5xn > * {
  padding: 5.5rem;
}
.children\:p\:v\:5xn > * {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}
.children\:p\:h\:5xn > * {
  padding-right: 5.5rem;
  padding-left: 5.5rem;
}
.children\:p\:t\:5xn > * {
  padding-top: 5.5rem;
}
.children\:p\:b\:5xn > * {
  padding-bottom: 5.5rem;
}
.children\:p\:r\:5xn > * {
  padding-right: 5.5rem;
}
.children\:p\:l\:5xn > * {
  padding-left: 5.5rem;
}
.children\:p\:6xn > * {
  padding: 6.5rem;
}
.children\:p\:v\:6xn > * {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}
.children\:p\:h\:6xn > * {
  padding-right: 6.5rem;
  padding-left: 6.5rem;
}
.children\:p\:t\:6xn > * {
  padding-top: 6.5rem;
}
.children\:p\:b\:6xn > * {
  padding-bottom: 6.5rem;
}
.children\:p\:r\:6xn > * {
  padding-right: 6.5rem;
}
.children\:p\:l\:6xn > * {
  padding-left: 6.5rem;
}
.children\:p\:7xn > * {
  padding: 7.5rem;
}
.children\:p\:v\:7xn > * {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.children\:p\:h\:7xn > * {
  padding-right: 7.5rem;
  padding-left: 7.5rem;
}
.children\:p\:t\:7xn > * {
  padding-top: 7.5rem;
}
.children\:p\:b\:7xn > * {
  padding-bottom: 7.5rem;
}
.children\:p\:r\:7xn > * {
  padding-right: 7.5rem;
}
.children\:p\:l\:7xn > * {
  padding-left: 7.5rem;
}
.children\:p\:8xn > * {
  padding: 8.5rem;
}
.children\:p\:v\:8xn > * {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
}
.children\:p\:h\:8xn > * {
  padding-right: 8.5rem;
  padding-left: 8.5rem;
}
.children\:p\:t\:8xn > * {
  padding-top: 8.5rem;
}
.children\:p\:b\:8xn > * {
  padding-bottom: 8.5rem;
}
.children\:p\:r\:8xn > * {
  padding-right: 8.5rem;
}
.children\:p\:l\:8xn > * {
  padding-left: 8.5rem;
}
.children\:p\:9xn > * {
  padding: 9.5rem;
}
.children\:p\:v\:9xn > * {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}
.children\:p\:h\:9xn > * {
  padding-right: 9.5rem;
  padding-left: 9.5rem;
}
.children\:p\:t\:9xn > * {
  padding-top: 9.5rem;
}
.children\:p\:b\:9xn > * {
  padding-bottom: 9.5rem;
}
.children\:p\:r\:9xn > * {
  padding-right: 9.5rem;
}
.children\:p\:l\:9xn > * {
  padding-left: 9.5rem;
}
.children\:p\:10xn > * {
  padding: 10.5rem;
}
.children\:p\:v\:10xn > * {
  padding-top: 10.5rem;
  padding-bottom: 10.5rem;
}
.children\:p\:h\:10xn > * {
  padding-right: 10.5rem;
  padding-left: 10.5rem;
}
.children\:p\:t\:10xn > * {
  padding-top: 10.5rem;
}
.children\:p\:b\:10xn > * {
  padding-bottom: 10.5rem;
}
.children\:p\:r\:10xn > * {
  padding-right: 10.5rem;
}
.children\:p\:l\:10xn > * {
  padding-left: 10.5rem;
}
.children\:p\:11xn > * {
  padding: 11.5rem;
}
.children\:p\:v\:11xn > * {
  padding-top: 11.5rem;
  padding-bottom: 11.5rem;
}
.children\:p\:h\:11xn > * {
  padding-right: 11.5rem;
  padding-left: 11.5rem;
}
.children\:p\:t\:11xn > * {
  padding-top: 11.5rem;
}
.children\:p\:b\:11xn > * {
  padding-bottom: 11.5rem;
}
.children\:p\:r\:11xn > * {
  padding-right: 11.5rem;
}
.children\:p\:l\:11xn > * {
  padding-left: 11.5rem;
}
.children\:p\:12xn > * {
  padding: 12.5rem;
}
.children\:p\:v\:12xn > * {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}
.children\:p\:h\:12xn > * {
  padding-right: 12.5rem;
  padding-left: 12.5rem;
}
.children\:p\:t\:12xn > * {
  padding-top: 12.5rem;
}
.children\:p\:b\:12xn > * {
  padding-bottom: 12.5rem;
}
.children\:p\:r\:12xn > * {
  padding-right: 12.5rem;
}
.children\:p\:l\:12xn > * {
  padding-left: 12.5rem;
}
.children\:p\:none > * {
  padding: 0;
}
.children\:p\:v\:none > * {
  padding-top: 0;
  padding-bottom: 0;
}
.children\:p\:h\:none > * {
  padding-right: 0;
  padding-left: 0;
}
.children\:p\:t\:none > * {
  padding-top: 0;
}
.children\:p\:b\:none > * {
  padding-bottom: 0;
}
.children\:p\:r\:none > * {
  padding-right: 0;
}
.children\:p\:l\:none > * {
  padding-left: 0;
}
.children\:p\:auto > * {
  padding: auto;
}
.children\:p\:v\:auto > * {
  padding-top: auto;
  padding-bottom: auto;
}
.children\:p\:h\:auto > * {
  padding-right: auto;
  padding-left: auto;
}
.children\:p\:t\:auto > * {
  padding-top: auto;
}
.children\:p\:b\:auto > * {
  padding-bottom: auto;
}
.children\:p\:r\:auto > * {
  padding-right: auto;
}
.children\:p\:l\:auto > * {
  padding-left: auto;
}
.children\:p\:edge > * {
  padding: 5%;
}
.children\:p\:v\:edge > * {
  padding-top: 5%;
  padding-bottom: 5%;
}
.children\:p\:h\:edge > * {
  padding-right: 5%;
  padding-left: 5%;
}
.children\:p\:t\:edge > * {
  padding-top: 5%;
}
.children\:p\:b\:edge > * {
  padding-bottom: 5%;
}
.children\:p\:r\:edge > * {
  padding-right: 5%;
}
.children\:p\:l\:edge > * {
  padding-left: 5%;
}
.children\:p\:page > * {
  padding: 5rem;
}
.children\:p\:v\:page > * {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.children\:p\:h\:page > * {
  padding-right: 5rem;
  padding-left: 5rem;
}
.children\:p\:t\:page > * {
  padding-top: 5rem;
}
.children\:p\:b\:page > * {
  padding-bottom: 5rem;
}
.children\:p\:r\:page > * {
  padding-right: 5rem;
}
.children\:p\:l\:page > * {
  padding-left: 5rem;
}
.children\:p\:segment > * {
  padding: 3rem;
}
.children\:p\:v\:segment > * {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.children\:p\:h\:segment > * {
  padding-right: 3rem;
  padding-left: 3rem;
}
.children\:p\:t\:segment > * {
  padding-top: 3rem;
}
.children\:p\:b\:segment > * {
  padding-bottom: 3rem;
}
.children\:p\:r\:segment > * {
  padding-right: 3rem;
}
.children\:p\:l\:segment > * {
  padding-left: 3rem;
}
.children\:p\:base > * {
  padding: 1rem;
}
.children\:p\:v\:base > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.children\:p\:h\:base > * {
  padding-right: 1rem;
  padding-left: 1rem;
}
.children\:p\:t\:base > * {
  padding-top: 1rem;
}
.children\:p\:b\:base > * {
  padding-bottom: 1rem;
}
.children\:p\:r\:base > * {
  padding-right: 1rem;
}
.children\:p\:l\:base > * {
  padding-left: 1rem;
}
.children\:p\:headlinePre > * {
  padding: 1rem;
}
.children\:p\:v\:headlinePre > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.children\:p\:h\:headlinePre > * {
  padding-right: 1rem;
  padding-left: 1rem;
}
.children\:p\:t\:headlinePre > * {
  padding-top: 1rem;
}
.children\:p\:b\:headlinePre > * {
  padding-bottom: 1rem;
}
.children\:p\:r\:headlinePre > * {
  padding-right: 1rem;
}
.children\:p\:l\:headlinePre > * {
  padding-left: 1rem;
}
.children\:p\:headline > * {
  padding: 1.25rem;
}
.children\:p\:v\:headline > * {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.children\:p\:h\:headline > * {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.children\:p\:t\:headline > * {
  padding-top: 1.25rem;
}
.children\:p\:b\:headline > * {
  padding-bottom: 1.25rem;
}
.children\:p\:r\:headline > * {
  padding-right: 1.25rem;
}
.children\:p\:l\:headline > * {
  padding-left: 1.25rem;
}
.children\:p\:headingPre > * {
  padding: 0.75rem;
}
.children\:p\:v\:headingPre > * {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.children\:p\:h\:headingPre > * {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.children\:p\:t\:headingPre > * {
  padding-top: 0.75rem;
}
.children\:p\:b\:headingPre > * {
  padding-bottom: 0.75rem;
}
.children\:p\:r\:headingPre > * {
  padding-right: 0.75rem;
}
.children\:p\:l\:headingPre > * {
  padding-left: 0.75rem;
}
.children\:p\:heading > * {
  padding: 1rem;
}
.children\:p\:v\:heading > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.children\:p\:h\:heading > * {
  padding-right: 1rem;
  padding-left: 1rem;
}
.children\:p\:t\:heading > * {
  padding-top: 1rem;
}
.children\:p\:b\:heading > * {
  padding-bottom: 1rem;
}
.children\:p\:r\:heading > * {
  padding-right: 1rem;
}
.children\:p\:l\:heading > * {
  padding-left: 1rem;
}
.children\:p\:x1\:\!last > *:not(:last-child) {
  padding: 0.75rem;
}
.children\:p\:v\:x1\:\!last > *:not(:last-child) {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.children\:p\:h\:x1\:\!last > *:not(:last-child) {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.children\:p\:t\:x1\:\!last > *:not(:last-child) {
  padding-top: 0.75rem;
}
.children\:p\:b\:x1\:\!last > *:not(:last-child) {
  padding-bottom: 0.75rem;
}
.children\:p\:r\:x1\:\!last > *:not(:last-child) {
  padding-right: 0.75rem;
}
.children\:p\:l\:x1\:\!last > *:not(:last-child) {
  padding-left: 0.75rem;
}
.children\:p\:x2\:\!last > *:not(:last-child) {
  padding: 0.5rem;
}
.children\:p\:v\:x2\:\!last > *:not(:last-child) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.children\:p\:h\:x2\:\!last > *:not(:last-child) {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.children\:p\:t\:x2\:\!last > *:not(:last-child) {
  padding-top: 0.5rem;
}
.children\:p\:b\:x2\:\!last > *:not(:last-child) {
  padding-bottom: 0.5rem;
}
.children\:p\:r\:x2\:\!last > *:not(:last-child) {
  padding-right: 0.5rem;
}
.children\:p\:l\:x2\:\!last > *:not(:last-child) {
  padding-left: 0.5rem;
}
.children\:p\:x3\:\!last > *:not(:last-child) {
  padding: 0.25rem;
}
.children\:p\:v\:x3\:\!last > *:not(:last-child) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.children\:p\:h\:x3\:\!last > *:not(:last-child) {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
.children\:p\:t\:x3\:\!last > *:not(:last-child) {
  padding-top: 0.25rem;
}
.children\:p\:b\:x3\:\!last > *:not(:last-child) {
  padding-bottom: 0.25rem;
}
.children\:p\:r\:x3\:\!last > *:not(:last-child) {
  padding-right: 0.25rem;
}
.children\:p\:l\:x3\:\!last > *:not(:last-child) {
  padding-left: 0.25rem;
}
.children\:p\:1x\:\!last > *:not(:last-child) {
  padding: 1rem;
}
.children\:p\:v\:1x\:\!last > *:not(:last-child) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.children\:p\:h\:1x\:\!last > *:not(:last-child) {
  padding-right: 1rem;
  padding-left: 1rem;
}
.children\:p\:t\:1x\:\!last > *:not(:last-child) {
  padding-top: 1rem;
}
.children\:p\:b\:1x\:\!last > *:not(:last-child) {
  padding-bottom: 1rem;
}
.children\:p\:r\:1x\:\!last > *:not(:last-child) {
  padding-right: 1rem;
}
.children\:p\:l\:1x\:\!last > *:not(:last-child) {
  padding-left: 1rem;
}
.children\:p\:2x\:\!last > *:not(:last-child) {
  padding: 2rem;
}
.children\:p\:v\:2x\:\!last > *:not(:last-child) {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.children\:p\:h\:2x\:\!last > *:not(:last-child) {
  padding-right: 2rem;
  padding-left: 2rem;
}
.children\:p\:t\:2x\:\!last > *:not(:last-child) {
  padding-top: 2rem;
}
.children\:p\:b\:2x\:\!last > *:not(:last-child) {
  padding-bottom: 2rem;
}
.children\:p\:r\:2x\:\!last > *:not(:last-child) {
  padding-right: 2rem;
}
.children\:p\:l\:2x\:\!last > *:not(:last-child) {
  padding-left: 2rem;
}
.children\:p\:3x\:\!last > *:not(:last-child) {
  padding: 3rem;
}
.children\:p\:v\:3x\:\!last > *:not(:last-child) {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.children\:p\:h\:3x\:\!last > *:not(:last-child) {
  padding-right: 3rem;
  padding-left: 3rem;
}
.children\:p\:t\:3x\:\!last > *:not(:last-child) {
  padding-top: 3rem;
}
.children\:p\:b\:3x\:\!last > *:not(:last-child) {
  padding-bottom: 3rem;
}
.children\:p\:r\:3x\:\!last > *:not(:last-child) {
  padding-right: 3rem;
}
.children\:p\:l\:3x\:\!last > *:not(:last-child) {
  padding-left: 3rem;
}
.children\:p\:4x\:\!last > *:not(:last-child) {
  padding: 4rem;
}
.children\:p\:v\:4x\:\!last > *:not(:last-child) {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.children\:p\:h\:4x\:\!last > *:not(:last-child) {
  padding-right: 4rem;
  padding-left: 4rem;
}
.children\:p\:t\:4x\:\!last > *:not(:last-child) {
  padding-top: 4rem;
}
.children\:p\:b\:4x\:\!last > *:not(:last-child) {
  padding-bottom: 4rem;
}
.children\:p\:r\:4x\:\!last > *:not(:last-child) {
  padding-right: 4rem;
}
.children\:p\:l\:4x\:\!last > *:not(:last-child) {
  padding-left: 4rem;
}
.children\:p\:5x\:\!last > *:not(:last-child) {
  padding: 5rem;
}
.children\:p\:v\:5x\:\!last > *:not(:last-child) {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.children\:p\:h\:5x\:\!last > *:not(:last-child) {
  padding-right: 5rem;
  padding-left: 5rem;
}
.children\:p\:t\:5x\:\!last > *:not(:last-child) {
  padding-top: 5rem;
}
.children\:p\:b\:5x\:\!last > *:not(:last-child) {
  padding-bottom: 5rem;
}
.children\:p\:r\:5x\:\!last > *:not(:last-child) {
  padding-right: 5rem;
}
.children\:p\:l\:5x\:\!last > *:not(:last-child) {
  padding-left: 5rem;
}
.children\:p\:6x\:\!last > *:not(:last-child) {
  padding: 6rem;
}
.children\:p\:v\:6x\:\!last > *:not(:last-child) {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.children\:p\:h\:6x\:\!last > *:not(:last-child) {
  padding-right: 6rem;
  padding-left: 6rem;
}
.children\:p\:t\:6x\:\!last > *:not(:last-child) {
  padding-top: 6rem;
}
.children\:p\:b\:6x\:\!last > *:not(:last-child) {
  padding-bottom: 6rem;
}
.children\:p\:r\:6x\:\!last > *:not(:last-child) {
  padding-right: 6rem;
}
.children\:p\:l\:6x\:\!last > *:not(:last-child) {
  padding-left: 6rem;
}
.children\:p\:7x\:\!last > *:not(:last-child) {
  padding: 7rem;
}
.children\:p\:v\:7x\:\!last > *:not(:last-child) {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.children\:p\:h\:7x\:\!last > *:not(:last-child) {
  padding-right: 7rem;
  padding-left: 7rem;
}
.children\:p\:t\:7x\:\!last > *:not(:last-child) {
  padding-top: 7rem;
}
.children\:p\:b\:7x\:\!last > *:not(:last-child) {
  padding-bottom: 7rem;
}
.children\:p\:r\:7x\:\!last > *:not(:last-child) {
  padding-right: 7rem;
}
.children\:p\:l\:7x\:\!last > *:not(:last-child) {
  padding-left: 7rem;
}
.children\:p\:8x\:\!last > *:not(:last-child) {
  padding: 8rem;
}
.children\:p\:v\:8x\:\!last > *:not(:last-child) {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.children\:p\:h\:8x\:\!last > *:not(:last-child) {
  padding-right: 8rem;
  padding-left: 8rem;
}
.children\:p\:t\:8x\:\!last > *:not(:last-child) {
  padding-top: 8rem;
}
.children\:p\:b\:8x\:\!last > *:not(:last-child) {
  padding-bottom: 8rem;
}
.children\:p\:r\:8x\:\!last > *:not(:last-child) {
  padding-right: 8rem;
}
.children\:p\:l\:8x\:\!last > *:not(:last-child) {
  padding-left: 8rem;
}
.children\:p\:9x\:\!last > *:not(:last-child) {
  padding: 9rem;
}
.children\:p\:v\:9x\:\!last > *:not(:last-child) {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.children\:p\:h\:9x\:\!last > *:not(:last-child) {
  padding-right: 9rem;
  padding-left: 9rem;
}
.children\:p\:t\:9x\:\!last > *:not(:last-child) {
  padding-top: 9rem;
}
.children\:p\:b\:9x\:\!last > *:not(:last-child) {
  padding-bottom: 9rem;
}
.children\:p\:r\:9x\:\!last > *:not(:last-child) {
  padding-right: 9rem;
}
.children\:p\:l\:9x\:\!last > *:not(:last-child) {
  padding-left: 9rem;
}
.children\:p\:10x\:\!last > *:not(:last-child) {
  padding: 10rem;
}
.children\:p\:v\:10x\:\!last > *:not(:last-child) {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.children\:p\:h\:10x\:\!last > *:not(:last-child) {
  padding-right: 10rem;
  padding-left: 10rem;
}
.children\:p\:t\:10x\:\!last > *:not(:last-child) {
  padding-top: 10rem;
}
.children\:p\:b\:10x\:\!last > *:not(:last-child) {
  padding-bottom: 10rem;
}
.children\:p\:r\:10x\:\!last > *:not(:last-child) {
  padding-right: 10rem;
}
.children\:p\:l\:10x\:\!last > *:not(:last-child) {
  padding-left: 10rem;
}
.children\:p\:11x\:\!last > *:not(:last-child) {
  padding: 11rem;
}
.children\:p\:v\:11x\:\!last > *:not(:last-child) {
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.children\:p\:h\:11x\:\!last > *:not(:last-child) {
  padding-right: 11rem;
  padding-left: 11rem;
}
.children\:p\:t\:11x\:\!last > *:not(:last-child) {
  padding-top: 11rem;
}
.children\:p\:b\:11x\:\!last > *:not(:last-child) {
  padding-bottom: 11rem;
}
.children\:p\:r\:11x\:\!last > *:not(:last-child) {
  padding-right: 11rem;
}
.children\:p\:l\:11x\:\!last > *:not(:last-child) {
  padding-left: 11rem;
}
.children\:p\:12x\:\!last > *:not(:last-child) {
  padding: 12rem;
}
.children\:p\:v\:12x\:\!last > *:not(:last-child) {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.children\:p\:h\:12x\:\!last > *:not(:last-child) {
  padding-right: 12rem;
  padding-left: 12rem;
}
.children\:p\:t\:12x\:\!last > *:not(:last-child) {
  padding-top: 12rem;
}
.children\:p\:b\:12x\:\!last > *:not(:last-child) {
  padding-bottom: 12rem;
}
.children\:p\:r\:12x\:\!last > *:not(:last-child) {
  padding-right: 12rem;
}
.children\:p\:l\:12x\:\!last > *:not(:last-child) {
  padding-left: 12rem;
}
.children\:p\:1xn\:\!last > *:not(:last-child) {
  padding: 1.5rem;
}
.children\:p\:v\:1xn\:\!last > *:not(:last-child) {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.children\:p\:h\:1xn\:\!last > *:not(:last-child) {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.children\:p\:t\:1xn\:\!last > *:not(:last-child) {
  padding-top: 1.5rem;
}
.children\:p\:b\:1xn\:\!last > *:not(:last-child) {
  padding-bottom: 1.5rem;
}
.children\:p\:r\:1xn\:\!last > *:not(:last-child) {
  padding-right: 1.5rem;
}
.children\:p\:l\:1xn\:\!last > *:not(:last-child) {
  padding-left: 1.5rem;
}
.children\:p\:2xn\:\!last > *:not(:last-child) {
  padding: 2.5rem;
}
.children\:p\:v\:2xn\:\!last > *:not(:last-child) {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.children\:p\:h\:2xn\:\!last > *:not(:last-child) {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.children\:p\:t\:2xn\:\!last > *:not(:last-child) {
  padding-top: 2.5rem;
}
.children\:p\:b\:2xn\:\!last > *:not(:last-child) {
  padding-bottom: 2.5rem;
}
.children\:p\:r\:2xn\:\!last > *:not(:last-child) {
  padding-right: 2.5rem;
}
.children\:p\:l\:2xn\:\!last > *:not(:last-child) {
  padding-left: 2.5rem;
}
.children\:p\:3xn\:\!last > *:not(:last-child) {
  padding: 3.5rem;
}
.children\:p\:v\:3xn\:\!last > *:not(:last-child) {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.children\:p\:h\:3xn\:\!last > *:not(:last-child) {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}
.children\:p\:t\:3xn\:\!last > *:not(:last-child) {
  padding-top: 3.5rem;
}
.children\:p\:b\:3xn\:\!last > *:not(:last-child) {
  padding-bottom: 3.5rem;
}
.children\:p\:r\:3xn\:\!last > *:not(:last-child) {
  padding-right: 3.5rem;
}
.children\:p\:l\:3xn\:\!last > *:not(:last-child) {
  padding-left: 3.5rem;
}
.children\:p\:4xn\:\!last > *:not(:last-child) {
  padding: 4.5rem;
}
.children\:p\:v\:4xn\:\!last > *:not(:last-child) {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
.children\:p\:h\:4xn\:\!last > *:not(:last-child) {
  padding-right: 4.5rem;
  padding-left: 4.5rem;
}
.children\:p\:t\:4xn\:\!last > *:not(:last-child) {
  padding-top: 4.5rem;
}
.children\:p\:b\:4xn\:\!last > *:not(:last-child) {
  padding-bottom: 4.5rem;
}
.children\:p\:r\:4xn\:\!last > *:not(:last-child) {
  padding-right: 4.5rem;
}
.children\:p\:l\:4xn\:\!last > *:not(:last-child) {
  padding-left: 4.5rem;
}
.children\:p\:5xn\:\!last > *:not(:last-child) {
  padding: 5.5rem;
}
.children\:p\:v\:5xn\:\!last > *:not(:last-child) {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}
.children\:p\:h\:5xn\:\!last > *:not(:last-child) {
  padding-right: 5.5rem;
  padding-left: 5.5rem;
}
.children\:p\:t\:5xn\:\!last > *:not(:last-child) {
  padding-top: 5.5rem;
}
.children\:p\:b\:5xn\:\!last > *:not(:last-child) {
  padding-bottom: 5.5rem;
}
.children\:p\:r\:5xn\:\!last > *:not(:last-child) {
  padding-right: 5.5rem;
}
.children\:p\:l\:5xn\:\!last > *:not(:last-child) {
  padding-left: 5.5rem;
}
.children\:p\:6xn\:\!last > *:not(:last-child) {
  padding: 6.5rem;
}
.children\:p\:v\:6xn\:\!last > *:not(:last-child) {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}
.children\:p\:h\:6xn\:\!last > *:not(:last-child) {
  padding-right: 6.5rem;
  padding-left: 6.5rem;
}
.children\:p\:t\:6xn\:\!last > *:not(:last-child) {
  padding-top: 6.5rem;
}
.children\:p\:b\:6xn\:\!last > *:not(:last-child) {
  padding-bottom: 6.5rem;
}
.children\:p\:r\:6xn\:\!last > *:not(:last-child) {
  padding-right: 6.5rem;
}
.children\:p\:l\:6xn\:\!last > *:not(:last-child) {
  padding-left: 6.5rem;
}
.children\:p\:7xn\:\!last > *:not(:last-child) {
  padding: 7.5rem;
}
.children\:p\:v\:7xn\:\!last > *:not(:last-child) {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.children\:p\:h\:7xn\:\!last > *:not(:last-child) {
  padding-right: 7.5rem;
  padding-left: 7.5rem;
}
.children\:p\:t\:7xn\:\!last > *:not(:last-child) {
  padding-top: 7.5rem;
}
.children\:p\:b\:7xn\:\!last > *:not(:last-child) {
  padding-bottom: 7.5rem;
}
.children\:p\:r\:7xn\:\!last > *:not(:last-child) {
  padding-right: 7.5rem;
}
.children\:p\:l\:7xn\:\!last > *:not(:last-child) {
  padding-left: 7.5rem;
}
.children\:p\:8xn\:\!last > *:not(:last-child) {
  padding: 8.5rem;
}
.children\:p\:v\:8xn\:\!last > *:not(:last-child) {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
}
.children\:p\:h\:8xn\:\!last > *:not(:last-child) {
  padding-right: 8.5rem;
  padding-left: 8.5rem;
}
.children\:p\:t\:8xn\:\!last > *:not(:last-child) {
  padding-top: 8.5rem;
}
.children\:p\:b\:8xn\:\!last > *:not(:last-child) {
  padding-bottom: 8.5rem;
}
.children\:p\:r\:8xn\:\!last > *:not(:last-child) {
  padding-right: 8.5rem;
}
.children\:p\:l\:8xn\:\!last > *:not(:last-child) {
  padding-left: 8.5rem;
}
.children\:p\:9xn\:\!last > *:not(:last-child) {
  padding: 9.5rem;
}
.children\:p\:v\:9xn\:\!last > *:not(:last-child) {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}
.children\:p\:h\:9xn\:\!last > *:not(:last-child) {
  padding-right: 9.5rem;
  padding-left: 9.5rem;
}
.children\:p\:t\:9xn\:\!last > *:not(:last-child) {
  padding-top: 9.5rem;
}
.children\:p\:b\:9xn\:\!last > *:not(:last-child) {
  padding-bottom: 9.5rem;
}
.children\:p\:r\:9xn\:\!last > *:not(:last-child) {
  padding-right: 9.5rem;
}
.children\:p\:l\:9xn\:\!last > *:not(:last-child) {
  padding-left: 9.5rem;
}
.children\:p\:10xn\:\!last > *:not(:last-child) {
  padding: 10.5rem;
}
.children\:p\:v\:10xn\:\!last > *:not(:last-child) {
  padding-top: 10.5rem;
  padding-bottom: 10.5rem;
}
.children\:p\:h\:10xn\:\!last > *:not(:last-child) {
  padding-right: 10.5rem;
  padding-left: 10.5rem;
}
.children\:p\:t\:10xn\:\!last > *:not(:last-child) {
  padding-top: 10.5rem;
}
.children\:p\:b\:10xn\:\!last > *:not(:last-child) {
  padding-bottom: 10.5rem;
}
.children\:p\:r\:10xn\:\!last > *:not(:last-child) {
  padding-right: 10.5rem;
}
.children\:p\:l\:10xn\:\!last > *:not(:last-child) {
  padding-left: 10.5rem;
}
.children\:p\:11xn\:\!last > *:not(:last-child) {
  padding: 11.5rem;
}
.children\:p\:v\:11xn\:\!last > *:not(:last-child) {
  padding-top: 11.5rem;
  padding-bottom: 11.5rem;
}
.children\:p\:h\:11xn\:\!last > *:not(:last-child) {
  padding-right: 11.5rem;
  padding-left: 11.5rem;
}
.children\:p\:t\:11xn\:\!last > *:not(:last-child) {
  padding-top: 11.5rem;
}
.children\:p\:b\:11xn\:\!last > *:not(:last-child) {
  padding-bottom: 11.5rem;
}
.children\:p\:r\:11xn\:\!last > *:not(:last-child) {
  padding-right: 11.5rem;
}
.children\:p\:l\:11xn\:\!last > *:not(:last-child) {
  padding-left: 11.5rem;
}
.children\:p\:12xn\:\!last > *:not(:last-child) {
  padding: 12.5rem;
}
.children\:p\:v\:12xn\:\!last > *:not(:last-child) {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}
.children\:p\:h\:12xn\:\!last > *:not(:last-child) {
  padding-right: 12.5rem;
  padding-left: 12.5rem;
}
.children\:p\:t\:12xn\:\!last > *:not(:last-child) {
  padding-top: 12.5rem;
}
.children\:p\:b\:12xn\:\!last > *:not(:last-child) {
  padding-bottom: 12.5rem;
}
.children\:p\:r\:12xn\:\!last > *:not(:last-child) {
  padding-right: 12.5rem;
}
.children\:p\:l\:12xn\:\!last > *:not(:last-child) {
  padding-left: 12.5rem;
}
.children\:p\:none\:\!last > *:not(:last-child) {
  padding: 0;
}
.children\:p\:v\:none\:\!last > *:not(:last-child) {
  padding-top: 0;
  padding-bottom: 0;
}
.children\:p\:h\:none\:\!last > *:not(:last-child) {
  padding-right: 0;
  padding-left: 0;
}
.children\:p\:t\:none\:\!last > *:not(:last-child) {
  padding-top: 0;
}
.children\:p\:b\:none\:\!last > *:not(:last-child) {
  padding-bottom: 0;
}
.children\:p\:r\:none\:\!last > *:not(:last-child) {
  padding-right: 0;
}
.children\:p\:l\:none\:\!last > *:not(:last-child) {
  padding-left: 0;
}
.children\:p\:auto\:\!last > *:not(:last-child) {
  padding: auto;
}
.children\:p\:v\:auto\:\!last > *:not(:last-child) {
  padding-top: auto;
  padding-bottom: auto;
}
.children\:p\:h\:auto\:\!last > *:not(:last-child) {
  padding-right: auto;
  padding-left: auto;
}
.children\:p\:t\:auto\:\!last > *:not(:last-child) {
  padding-top: auto;
}
.children\:p\:b\:auto\:\!last > *:not(:last-child) {
  padding-bottom: auto;
}
.children\:p\:r\:auto\:\!last > *:not(:last-child) {
  padding-right: auto;
}
.children\:p\:l\:auto\:\!last > *:not(:last-child) {
  padding-left: auto;
}
.children\:p\:edge\:\!last > *:not(:last-child) {
  padding: 5%;
}
.children\:p\:v\:edge\:\!last > *:not(:last-child) {
  padding-top: 5%;
  padding-bottom: 5%;
}
.children\:p\:h\:edge\:\!last > *:not(:last-child) {
  padding-right: 5%;
  padding-left: 5%;
}
.children\:p\:t\:edge\:\!last > *:not(:last-child) {
  padding-top: 5%;
}
.children\:p\:b\:edge\:\!last > *:not(:last-child) {
  padding-bottom: 5%;
}
.children\:p\:r\:edge\:\!last > *:not(:last-child) {
  padding-right: 5%;
}
.children\:p\:l\:edge\:\!last > *:not(:last-child) {
  padding-left: 5%;
}
.children\:p\:page\:\!last > *:not(:last-child) {
  padding: 5rem;
}
.children\:p\:v\:page\:\!last > *:not(:last-child) {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.children\:p\:h\:page\:\!last > *:not(:last-child) {
  padding-right: 5rem;
  padding-left: 5rem;
}
.children\:p\:t\:page\:\!last > *:not(:last-child) {
  padding-top: 5rem;
}
.children\:p\:b\:page\:\!last > *:not(:last-child) {
  padding-bottom: 5rem;
}
.children\:p\:r\:page\:\!last > *:not(:last-child) {
  padding-right: 5rem;
}
.children\:p\:l\:page\:\!last > *:not(:last-child) {
  padding-left: 5rem;
}
.children\:p\:segment\:\!last > *:not(:last-child) {
  padding: 3rem;
}
.children\:p\:v\:segment\:\!last > *:not(:last-child) {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.children\:p\:h\:segment\:\!last > *:not(:last-child) {
  padding-right: 3rem;
  padding-left: 3rem;
}
.children\:p\:t\:segment\:\!last > *:not(:last-child) {
  padding-top: 3rem;
}
.children\:p\:b\:segment\:\!last > *:not(:last-child) {
  padding-bottom: 3rem;
}
.children\:p\:r\:segment\:\!last > *:not(:last-child) {
  padding-right: 3rem;
}
.children\:p\:l\:segment\:\!last > *:not(:last-child) {
  padding-left: 3rem;
}
.children\:p\:base\:\!last > *:not(:last-child) {
  padding: 1rem;
}
.children\:p\:v\:base\:\!last > *:not(:last-child) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.children\:p\:h\:base\:\!last > *:not(:last-child) {
  padding-right: 1rem;
  padding-left: 1rem;
}
.children\:p\:t\:base\:\!last > *:not(:last-child) {
  padding-top: 1rem;
}
.children\:p\:b\:base\:\!last > *:not(:last-child) {
  padding-bottom: 1rem;
}
.children\:p\:r\:base\:\!last > *:not(:last-child) {
  padding-right: 1rem;
}
.children\:p\:l\:base\:\!last > *:not(:last-child) {
  padding-left: 1rem;
}
.children\:p\:headlinePre\:\!last > *:not(:last-child) {
  padding: 1rem;
}
.children\:p\:v\:headlinePre\:\!last > *:not(:last-child) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.children\:p\:h\:headlinePre\:\!last > *:not(:last-child) {
  padding-right: 1rem;
  padding-left: 1rem;
}
.children\:p\:t\:headlinePre\:\!last > *:not(:last-child) {
  padding-top: 1rem;
}
.children\:p\:b\:headlinePre\:\!last > *:not(:last-child) {
  padding-bottom: 1rem;
}
.children\:p\:r\:headlinePre\:\!last > *:not(:last-child) {
  padding-right: 1rem;
}
.children\:p\:l\:headlinePre\:\!last > *:not(:last-child) {
  padding-left: 1rem;
}
.children\:p\:headline\:\!last > *:not(:last-child) {
  padding: 1.25rem;
}
.children\:p\:v\:headline\:\!last > *:not(:last-child) {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.children\:p\:h\:headline\:\!last > *:not(:last-child) {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.children\:p\:t\:headline\:\!last > *:not(:last-child) {
  padding-top: 1.25rem;
}
.children\:p\:b\:headline\:\!last > *:not(:last-child) {
  padding-bottom: 1.25rem;
}
.children\:p\:r\:headline\:\!last > *:not(:last-child) {
  padding-right: 1.25rem;
}
.children\:p\:l\:headline\:\!last > *:not(:last-child) {
  padding-left: 1.25rem;
}
.children\:p\:headingPre\:\!last > *:not(:last-child) {
  padding: 0.75rem;
}
.children\:p\:v\:headingPre\:\!last > *:not(:last-child) {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.children\:p\:h\:headingPre\:\!last > *:not(:last-child) {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.children\:p\:t\:headingPre\:\!last > *:not(:last-child) {
  padding-top: 0.75rem;
}
.children\:p\:b\:headingPre\:\!last > *:not(:last-child) {
  padding-bottom: 0.75rem;
}
.children\:p\:r\:headingPre\:\!last > *:not(:last-child) {
  padding-right: 0.75rem;
}
.children\:p\:l\:headingPre\:\!last > *:not(:last-child) {
  padding-left: 0.75rem;
}
.children\:p\:heading\:\!last > *:not(:last-child) {
  padding: 1rem;
}
.children\:p\:v\:heading\:\!last > *:not(:last-child) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.children\:p\:h\:heading\:\!last > *:not(:last-child) {
  padding-right: 1rem;
  padding-left: 1rem;
}
.children\:p\:t\:heading\:\!last > *:not(:last-child) {
  padding-top: 1rem;
}
.children\:p\:b\:heading\:\!last > *:not(:last-child) {
  padding-bottom: 1rem;
}
.children\:p\:r\:heading\:\!last > *:not(:last-child) {
  padding-right: 1rem;
}
.children\:p\:l\:heading\:\!last > *:not(:last-child) {
  padding-left: 1rem;
}

[color=action] {
  color: #4c50ca;
}
[color=primary] {
  color: #2f7aff;
}
[color=secondary] {
  color: #c27334;
}
[color=neutral] {
  color: #0069d7;
}
[color=success] {
  color: #0ea86a;
}
[color=warning] {
  color: #f6bb40;
}
[color=impulse] {
  color: #ff9900;
}
[color=danger] {
  color: #f44335;
}
[color=earthy] {
  color: #c55039;
}
[color=luxury] {
  color: #8738e6;
}
[color=fantasy] {
  color: #e60983;
}
[color=gloomy] {
  color: #8493a7;
}
[color=somber] {
  color: #3f4a58;
}
[color=snowy] {
  color: #e8ecef;
}
[color=black] {
  color: #000;
}
[color=white] {
  color: #fff;
}

div[theme=action]:not([outline]),
span[theme=action]:not([outline]),
button[theme=action]:not([outline]) {
  background-color: #4c50ca;
  color: white;
  fill: white;
}
div[theme=action]:not([outline])[inset],
span[theme=action]:not([outline])[inset],
button[theme=action]:not([outline])[inset] {
  box-shadow: inset 0 -3px #3438af;
}
div[theme=primary]:not([outline]),
span[theme=primary]:not([outline]),
button[theme=primary]:not([outline]) {
  background-color: #2f7aff;
  color: white;
  fill: white;
}
div[theme=primary]:not([outline])[inset],
span[theme=primary]:not([outline])[inset],
button[theme=primary]:not([outline])[inset] {
  box-shadow: inset 0 -3px #005bfb;
}
div[theme=secondary]:not([outline]),
span[theme=secondary]:not([outline]),
button[theme=secondary]:not([outline]) {
  background-color: #c27334;
  color: white;
  fill: white;
}
div[theme=secondary]:not([outline])[inset],
span[theme=secondary]:not([outline])[inset],
button[theme=secondary]:not([outline])[inset] {
  box-shadow: inset 0 -3px #9a5b29;
}
div[theme=neutral]:not([outline]),
span[theme=neutral]:not([outline]),
button[theme=neutral]:not([outline]) {
  background-color: #0069d7;
  color: white;
  fill: white;
}
div[theme=neutral]:not([outline])[inset],
span[theme=neutral]:not([outline])[inset],
button[theme=neutral]:not([outline])[inset] {
  box-shadow: inset 0 -3px #0050a4;
}
div[theme=success]:not([outline]),
span[theme=success]:not([outline]),
button[theme=success]:not([outline]) {
  background-color: #0ea86a;
  color: white;
  fill: white;
}
div[theme=success]:not([outline])[inset],
span[theme=success]:not([outline])[inset],
button[theme=success]:not([outline])[inset] {
  box-shadow: inset 0 -3px #0a794c;
}
div[theme=warning]:not([outline]),
span[theme=warning]:not([outline]),
button[theme=warning]:not([outline]) {
  background-color: #f6bb40;
  color: #3f4a58;
  fill: #3f4a58;
}
div[theme=warning]:not([outline])[inset],
span[theme=warning]:not([outline])[inset],
button[theme=warning]:not([outline])[inset] {
  box-shadow: inset 0 -3px #f4aa0f;
}
div[theme=impulse]:not([outline]),
span[theme=impulse]:not([outline]),
button[theme=impulse]:not([outline]) {
  background-color: #ff9900;
  color: white;
  fill: white;
}
div[theme=impulse]:not([outline])[inset],
span[theme=impulse]:not([outline])[inset],
button[theme=impulse]:not([outline])[inset] {
  box-shadow: inset 0 -3px #cc7a00;
}
div[theme=danger]:not([outline]),
span[theme=danger]:not([outline]),
button[theme=danger]:not([outline]) {
  background-color: #f44335;
  color: white;
  fill: white;
}
div[theme=danger]:not([outline])[inset],
span[theme=danger]:not([outline])[inset],
button[theme=danger]:not([outline])[inset] {
  box-shadow: inset 0 -3px #e91d0d;
}
div[theme=earthy]:not([outline]),
span[theme=earthy]:not([outline]),
button[theme=earthy]:not([outline]) {
  background-color: #c55039;
  color: white;
  fill: white;
}
div[theme=earthy]:not([outline])[inset],
span[theme=earthy]:not([outline])[inset],
button[theme=earthy]:not([outline])[inset] {
  box-shadow: inset 0 -3px #9d402e;
}
div[theme=luxury]:not([outline]),
span[theme=luxury]:not([outline]),
button[theme=luxury]:not([outline]) {
  background-color: #8738e6;
  color: white;
  fill: white;
}
div[theme=luxury]:not([outline])[inset],
span[theme=luxury]:not([outline])[inset],
button[theme=luxury]:not([outline])[inset] {
  box-shadow: inset 0 -3px #6d1ad1;
}
div[theme=fantasy]:not([outline]),
span[theme=fantasy]:not([outline]),
button[theme=fantasy]:not([outline]) {
  background-color: #e60983;
  color: white;
  fill: white;
}
div[theme=fantasy]:not([outline])[inset],
span[theme=fantasy]:not([outline])[inset],
button[theme=fantasy]:not([outline])[inset] {
  box-shadow: inset 0 -3px #b50767;
}
div[theme=gloomy]:not([outline]),
span[theme=gloomy]:not([outline]),
button[theme=gloomy]:not([outline]) {
  background-color: #8493a7;
  color: white;
  fill: white;
}
div[theme=gloomy]:not([outline])[inset],
span[theme=gloomy]:not([outline])[inset],
button[theme=gloomy]:not([outline])[inset] {
  box-shadow: inset 0 -3px #677991;
}
div[theme=somber]:not([outline]),
span[theme=somber]:not([outline]),
button[theme=somber]:not([outline]) {
  background-color: #3f4a58;
  color: white;
  fill: white;
}
div[theme=somber]:not([outline])[inset],
span[theme=somber]:not([outline])[inset],
button[theme=somber]:not([outline])[inset] {
  box-shadow: inset 0 -3px #2a313a;
}
div[theme=snowy]:not([outline]),
span[theme=snowy]:not([outline]),
button[theme=snowy]:not([outline]) {
  background-color: #e8ecef;
  color: #3f4a58;
  fill: #3f4a58;
}
div[theme=snowy]:not([outline])[inset],
span[theme=snowy]:not([outline])[inset],
button[theme=snowy]:not([outline])[inset] {
  box-shadow: inset 0 -3px #cad3da;
}
div[theme=black]:not([outline]),
span[theme=black]:not([outline]),
button[theme=black]:not([outline]) {
  background-color: black;
  color: white;
  fill: white;
}
div[theme=black]:not([outline])[inset],
span[theme=black]:not([outline])[inset],
button[theme=black]:not([outline])[inset] {
  box-shadow: inset 0 -3px black;
}
div[theme=white]:not([outline]),
span[theme=white]:not([outline]),
button[theme=white]:not([outline]) {
  background-color: white;
  color: #3f4a58;
  fill: #3f4a58;
}
div[theme=white]:not([outline])[inset],
span[theme=white]:not([outline])[inset],
button[theme=white]:not([outline])[inset] {
  box-shadow: inset 0 -3px #e6e6e6;
}
div[theme=action][outline],
span[theme=action][outline],
button[theme=action][outline] {
  background-color: transparent;
  color: #4c50ca;
  fill: #4c50ca;
  border: 1px solid #4c50ca;
}
div[theme=action][outline][inset],
span[theme=action][outline][inset],
button[theme=action][outline][inset] {
  box-shadow: inset 0 -3px #3438af;
}
div[theme=primary][outline],
span[theme=primary][outline],
button[theme=primary][outline] {
  background-color: transparent;
  color: #2f7aff;
  fill: #2f7aff;
  border: 1px solid #2f7aff;
}
div[theme=primary][outline][inset],
span[theme=primary][outline][inset],
button[theme=primary][outline][inset] {
  box-shadow: inset 0 -3px #005bfb;
}
div[theme=secondary][outline],
span[theme=secondary][outline],
button[theme=secondary][outline] {
  background-color: transparent;
  color: #c27334;
  fill: #c27334;
  border: 1px solid #c27334;
}
div[theme=secondary][outline][inset],
span[theme=secondary][outline][inset],
button[theme=secondary][outline][inset] {
  box-shadow: inset 0 -3px #9a5b29;
}
div[theme=neutral][outline],
span[theme=neutral][outline],
button[theme=neutral][outline] {
  background-color: transparent;
  color: #0069d7;
  fill: #0069d7;
  border: 1px solid #0069d7;
}
div[theme=neutral][outline][inset],
span[theme=neutral][outline][inset],
button[theme=neutral][outline][inset] {
  box-shadow: inset 0 -3px #0050a4;
}
div[theme=success][outline],
span[theme=success][outline],
button[theme=success][outline] {
  background-color: transparent;
  color: #0ea86a;
  fill: #0ea86a;
  border: 1px solid #0ea86a;
}
div[theme=success][outline][inset],
span[theme=success][outline][inset],
button[theme=success][outline][inset] {
  box-shadow: inset 0 -3px #0a794c;
}
div[theme=warning][outline],
span[theme=warning][outline],
button[theme=warning][outline] {
  background-color: transparent;
  color: #f6bb40;
  fill: #f6bb40;
  border: 1px solid #f6bb40;
}
div[theme=warning][outline][inset],
span[theme=warning][outline][inset],
button[theme=warning][outline][inset] {
  box-shadow: inset 0 -3px #f4aa0f;
}
div[theme=impulse][outline],
span[theme=impulse][outline],
button[theme=impulse][outline] {
  background-color: transparent;
  color: #ff9900;
  fill: #ff9900;
  border: 1px solid #ff9900;
}
div[theme=impulse][outline][inset],
span[theme=impulse][outline][inset],
button[theme=impulse][outline][inset] {
  box-shadow: inset 0 -3px #cc7a00;
}
div[theme=danger][outline],
span[theme=danger][outline],
button[theme=danger][outline] {
  background-color: transparent;
  color: #f44335;
  fill: #f44335;
  border: 1px solid #f44335;
}
div[theme=danger][outline][inset],
span[theme=danger][outline][inset],
button[theme=danger][outline][inset] {
  box-shadow: inset 0 -3px #e91d0d;
}
div[theme=earthy][outline],
span[theme=earthy][outline],
button[theme=earthy][outline] {
  background-color: transparent;
  color: #c55039;
  fill: #c55039;
  border: 1px solid #c55039;
}
div[theme=earthy][outline][inset],
span[theme=earthy][outline][inset],
button[theme=earthy][outline][inset] {
  box-shadow: inset 0 -3px #9d402e;
}
div[theme=luxury][outline],
span[theme=luxury][outline],
button[theme=luxury][outline] {
  background-color: transparent;
  color: #8738e6;
  fill: #8738e6;
  border: 1px solid #8738e6;
}
div[theme=luxury][outline][inset],
span[theme=luxury][outline][inset],
button[theme=luxury][outline][inset] {
  box-shadow: inset 0 -3px #6d1ad1;
}
div[theme=fantasy][outline],
span[theme=fantasy][outline],
button[theme=fantasy][outline] {
  background-color: transparent;
  color: #e60983;
  fill: #e60983;
  border: 1px solid #e60983;
}
div[theme=fantasy][outline][inset],
span[theme=fantasy][outline][inset],
button[theme=fantasy][outline][inset] {
  box-shadow: inset 0 -3px #b50767;
}
div[theme=gloomy][outline],
span[theme=gloomy][outline],
button[theme=gloomy][outline] {
  background-color: transparent;
  color: #8493a7;
  fill: #8493a7;
  border: 1px solid #8493a7;
}
div[theme=gloomy][outline][inset],
span[theme=gloomy][outline][inset],
button[theme=gloomy][outline][inset] {
  box-shadow: inset 0 -3px #677991;
}
div[theme=somber][outline],
span[theme=somber][outline],
button[theme=somber][outline] {
  background-color: transparent;
  color: #3f4a58;
  fill: #3f4a58;
  border: 1px solid #3f4a58;
}
div[theme=somber][outline][inset],
span[theme=somber][outline][inset],
button[theme=somber][outline][inset] {
  box-shadow: inset 0 -3px #2a313a;
}
div[theme=snowy][outline],
span[theme=snowy][outline],
button[theme=snowy][outline] {
  background-color: transparent;
  color: #e8ecef;
  fill: #e8ecef;
  border: 1px solid #e8ecef;
}
div[theme=snowy][outline][inset],
span[theme=snowy][outline][inset],
button[theme=snowy][outline][inset] {
  box-shadow: inset 0 -3px #cad3da;
}
div[theme=black][outline],
span[theme=black][outline],
button[theme=black][outline] {
  background-color: transparent;
  color: black;
  fill: black;
  border: 1px solid black;
}
div[theme=black][outline][inset],
span[theme=black][outline][inset],
button[theme=black][outline][inset] {
  box-shadow: inset 0 -3px black;
}
div[theme=white][outline],
span[theme=white][outline],
button[theme=white][outline] {
  background-color: transparent;
  color: white;
  fill: white;
  border: 1px solid white;
}
div[theme=white][outline][inset],
span[theme=white][outline][inset],
button[theme=white][outline][inset] {
  box-shadow: inset 0 -3px #e6e6e6;
}

image[fit-cover],
video[fit-cover] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
image[fit-fill],
video[fit-fill] {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

button[disabled] {
  position: relative;
  box-shadow: none;
  cursor: not-allowed;
}
button[disabled]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(132, 147, 167, 0.5);
  border-radius: inherit;
}
button[disabled] a {
  pointer-events: none;
}

button {
  border-radius: 5px;
}
button {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
button {
  padding-right: 1rem;
  padding-left: 1rem;
}
button:not([disabled])[animation*=raised] {
  transition: transform 250ms, box-shadow 250ms;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.22), 0 2px 4px -2px rgba(0, 0, 0, 0.2), 0 2px 5px 1px rgba(0, 0, 0, 0.28);
}
button:not([disabled])[animation*=raised]:active {
  transform: translateY(0.1rem);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.22), 0 2px 2px -1px rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.28);
}
button:not([disabled])[animation*=hovered][theme=action]:hover {
  color: white;
  fill: white;
  background: #7376d6;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=primary]:hover {
  color: white;
  fill: white;
  background: #629bff;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=secondary]:hover {
  color: white;
  fill: white;
  background: #d28e57;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=neutral]:hover {
  color: white;
  fill: white;
  background: #0b82ff;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=success]:hover {
  color: white;
  fill: white;
  background: #12d788;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=warning]:hover {
  color: #3f4a58;
  fill: #3f4a58;
  background: #f8cc71;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=impulse]:hover {
  color: white;
  fill: white;
  background: #ffad33;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=danger]:hover {
  color: white;
  fill: white;
  background: #f77065;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=earthy]:hover {
  color: white;
  fill: white;
  background: #d17360;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=luxury]:hover {
  color: white;
  fill: white;
  background: #a265ec;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=fantasy]:hover {
  color: white;
  fill: white;
  background: #f72b9c;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=gloomy]:hover {
  color: white;
  fill: white;
  background: #a2adbc;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=somber]:hover {
  color: white;
  fill: white;
  background: #546376;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=snowy]:hover {
  color: #3f4a58;
  fill: #3f4a58;
  background: white;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=black]:hover {
  color: white;
  fill: white;
  background: #1a1a1a;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=hovered][theme=white]:hover {
  color: #3f4a58;
  fill: #3f4a58;
  background: white;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=action]:active {
  color: white;
  fill: white;
  background: #3438af;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=primary]:active {
  color: white;
  fill: white;
  background: #005bfb;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=secondary]:active {
  color: white;
  fill: white;
  background: #9a5b29;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=neutral]:active {
  color: white;
  fill: white;
  background: #0050a4;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=success]:active {
  color: white;
  fill: white;
  background: #0a794c;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=warning]:active {
  color: #3f4a58;
  fill: #3f4a58;
  background: #f4aa0f;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=impulse]:active {
  color: white;
  fill: white;
  background: #cc7a00;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=danger]:active {
  color: white;
  fill: white;
  background: #e91d0d;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=earthy]:active {
  color: white;
  fill: white;
  background: #9d402e;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=luxury]:active {
  color: white;
  fill: white;
  background: #6d1ad1;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=fantasy]:active {
  color: white;
  fill: white;
  background: #b50767;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=gloomy]:active {
  color: white;
  fill: white;
  background: #677991;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=somber]:active {
  color: white;
  fill: white;
  background: #2a313a;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=snowy]:active {
  color: #3f4a58;
  fill: #3f4a58;
  background: #cad3da;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=black]:active {
  color: white;
  fill: white;
  background: black;
  transition: background-color 250ms;
}
button:not([disabled])[animation*=active][theme=white]:active {
  color: #3f4a58;
  fill: #3f4a58;
  background: #e6e6e6;
  transition: background-color 250ms;
}

a:link[a-underlined], a:visited[a-underlined] {
  position: relative;
}
a:link[a-underlined]::after, a:visited[a-underlined]::after {
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  background: #2f7aff;
  opacity: 0;
  transform: scaleX(0);
  transition: opacity 250ms, transform 250ms;
}
a:link[a-underlined]:hover:not(.active), a:visited[a-underlined]:hover:not(.active) {
  color: #2f7aff;
}
a:link[a-underlined]:hover:not(.active)::after, a:visited[a-underlined]:hover:not(.active)::after {
  opacity: 1;
  transform: scaleX(1);
}
a:link[a-underlined].active, a:visited[a-underlined].active {
  font-weight: 600;
  color: #2f7aff;
  cursor: default;
}

[nav-menu] {
  display: inline-block;
  color: #8493a7;
  fill: #8493a7;
}
[nav-menu] > * {
  position: relative;
}
[nav-menu] > *:not(:last-child) {
  margin-right: 2rem;
  margin-left: 2rem;
}
[nav-menu] > *::after {
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  background: #2f7aff;
  opacity: 0;
  transform: scaleX(0);
  transition: opacity 250ms, transform 250ms;
}
[nav-menu] > *:hover:not(.active) {
  color: #2f7aff;
}
[nav-menu] > *:hover:not(.active)::after {
  opacity: 1;
  transform: scaleX(1);
}
[nav-menu] > *.active {
  font-weight: 600;
  color: #2f7aff;
  cursor: default;
}