// ----- Generics ----- //

/**
 * @param {map} $map – the map from which the selectors will be generated.
 * @param {string} $property – the css property invoked when a generated selector is used
 * @param {boolean} $this – (& symbol) attach generated selectors to teir parent selector?
 * @param {'class'|'attribute'} $type– type of selectors to generate
 * @param {string} $name – name of selectors to generate
 * @param {string} $prefix – prefix to add to the selector
 * @param {string} $suffix – suffix to add to the selector
 * @param {string} $append – subselector to append to the css selector (e.g. '> *' to target children)
 * @param {boolean} $sides – should property sides be added?
 * @param {boolean} $exclLast – should exclude last selector instance?
 * @param {boolean} $children – should value apply to children?
 */
@mixin --selectors-from-map(
	$map,
	$property,
	$this,
	$type,
	$name,
	$prefix,
	$suffix,
	$separator: null,
	$append: null,
	$sides: false,
	$exclLast: false,
	$children: false
) {
	$validator: -in-list-check($type, ("class", "attribute"));
	@if ($children == true) {
		$prefix: "#{$prefix}children#{$separator}";
		$append: "#{$append} > *";
	}
	@if ($exclLast == true) {
		$suffix: -list-join(($suffix, "\\!last"), $separator);
		$append: "#{$append}:not(:last-child)";
	}

	@each $key, $value in $map {
		#{-selector($this, $type, -list-join(($name, if($type == 'class', $key, '"#{$key}"')), $separator), $prefix, $suffix, $append)} {
			#{$property}: $value;
		}

		@if ($sides == true) {
			@include --selector-sides(
				(
					$key: $value
				),
				$property,
				$this,
				$type,
				$name,
				$prefix,
				$suffix,
				$separator,
				$append
			);
		}
	}
}

// {✘} @private
@mixin --selector-sides($map, $property, $this, $type, $name, $prefix, $suffix, $separator, $append) {
	@each $key, $value in $map {
		@each $side in ("vertical", "horizontal", "top", "bottom", "right", "left") {
			#{-selector($this, $type, -list-join(($name, str-slice($side, 1, 1), $key), $separator), $prefix, $suffix, $append)} {
				@if ($side == "vertical") {
					#{$property}-top: $value;
					#{$property}-bottom: $value;
				} @else if ($side == "horizontal") {
					#{$property}-right: $value;
					#{$property}-left: $value;
				} @else {
					#{$property}-#{$side}: $value;
				}
			}
		}
	}
}

@mixin --classes-from-map($map, $property, $this: false, $name: null, $prefix: null, $suffix: null, $separator: "-", $append: null, $sides: false) {
	@include --selectors-from-map($map, $property, $this, "class", $name, $prefix, $suffix, $separator, $append, $sides);
}
@mixin --attributes-from-map($map, $property, $this: false, $name: null, $prefix: null, $suffix: null, $separator: "=", $append: null, $sides: false) {
	@include --selectors-from-map($map, $property, $this, "attribute", $name, $prefix, $suffix, $separator, $append, $sides);
}

// ----- Specifics ----- //

// {*} Coloring

/**
 * @param {boolean} $this – (& symbol) attach generated selectors to teir parent selector?
 * @param {string} $name – name of selectors to generate
 */
@mixin --coloring-attributes($this: false, $name: "color") {
	@each $key, $value in $palette__map {
		$color: -palette($key, "base");

		#{ if($name,
        -selector(true, "attribute", $name, null, '="#{$key}"'),
        -selector(true, "attribute", null, null, #{$key})) } {
			color: $color;
		}
	}
}

// {*} Theming

/**
 * @param {boolean} $this – (& symbol) attach generated selectors to teir parent selector?
 * @param {string} $name – name of selectors to generate
 * @param {string} $append – string to append to the css selector (e.g. '> *' to target children)
 */
@mixin --theming-attributes($this: false, $name: "theme", $append: null, $outline: false) {
	@each $key, $value in $palette__map {
		$theme-color: -palette($key, "base");
		$shadow-color: -palette($key, $shade: "darken");

		#{ if($name,
        -selector(true, "attribute", $name, null, '="#{$key}"', $append),
        -selector(true, "attribute", null, null, #{$key}, $append)) } {
			@include --background-theme($theme-color, $outline);

			&[inset] {
				box-shadow: -shadow(0, "-2z", $color: $shadow-color, $inset: true);
			}
		}
	}
}

// {*} Spacing

/**
 * @param {'margin'|'padding'} $property – the marge property invoked when a generated selector is used
 * @param {boolean} $this – (& symbol) attach generated selectors to their parent selector?
 * @param {'class'|'attribute'} $type – type of selectors to generate
 * @param {string} $name – name of selectors to generate
 * @param {string} $prefix – prefix to add to the selector
 * @param {string} $suffix – suffix to add to the selector
 * @param {string} $sides – should property sides be added?
 */
@mixin --spacing-selectors-from-map($this, $type, $prefix: null, $suffix: null, $separator: "-", $append: null, $sides: true, $children: false) {
	@each $marge in ("margin", "padding") {
		$name: str-slice($marge, 1, 1);

		@each $exclLast in (false, true) {
			@include --selectors-from-map($spacing__map, $marge, $this, $type, $name, $prefix, $suffix, $separator, $append, $sides, $exclLast, $children);
		}
	}
}

/**
 * @param {value|list} $spacing – spacing value or string or a list of two values (horizontal, vertical)
 * @param {boolean} – (& symbol) attach generated selectors to teir parent selector?
 * @param {'class'|'attribute'} $type – type of selectors to generate
 * @param {string} $prefix – prefix to add to the selector
 * @param {string} $suffix – suffix to add to the selector
 */

@mixin --tag-spacing-selectors($spacing, $this, $type, $prefix: null, $suffix: null, $append: null, $sides: true, $children: false) {
	$spacing-h: #{if(length($spacing) >= 2, nth($spacing, 1), $spacing)};
	$spacing-v: #{if(length($spacing) >= 2, nth($spacing, 2), $spacing)};

	@each $marge in ("margin", "padding") {
		@each $exclLast in (false, true) {
			@include --spacing-selectors($spacing-h, $spacing-v, #{$marge}, $this, $type, $prefix, $suffix, $append, $sides, $exclLast, $children);
		}
	}
}

// {✘} @private
@mixin --spacing-selectors(
	$spacing-h,
	$spacing-v,
	$marge,
	$this,
	$type,
	$prefix: null,
	$suffix: null,
	$append: null,
	$sides: false,
	$exclLast: false,
	$children: false
) {
	$validator: -in-list-check($type, ("class", "attribute"));
	@if ($children == true) {
		$prefix: "#{$prefix}children-";
		$append: "#{$append} > *";
	}
	// @if ($exclLast == true) { $suffix: '#{$suffix}#{"\\!"}last'; $append: '#{$append}:not(:last-child)'; }

	// @if($children == true) { @error #{-selector($this, $type, $marge, $prefix, $suffix, $append)} }
	#{-selector($this, $type, $marge, $prefix, $suffix, $append)} {
		@include --spacing($marge, "horizontal", $spacing-h);
		@include --spacing($marge, "vertical", $spacing-v);
	}

	@if ($sides == true) {
		@include --spacing-selector-sides($spacing-h, $spacing-v, $marge, $this, $type, $prefix, $suffix, $append);
	}
}

// {✘} @private
@mixin --spacing-selector-sides($spacing-h, $spacing-v, $marge, $this, $type, $prefix: null, $suffix: null, $append: null) {
	@each $side in ("vertical", "horizontal", "top", "bottom", "right", "left") {
		#{-selector($this, $type, #{marge}-#{str-slice($side, 1, 1)}, $prefix, $suffix, $append)} {
			@if (-in-list($side, ("horizontal", "right", "left"))) {
				@include --spacing($marge, $side, $spacing-h);
			} @else if (-in-list($side, ("vertical", "top", "bottom"))) {
				@include --spacing($marge, $side, $spacing-v);
			}
		}
	}
}
