// ----- Block ----- //

// {*} Raised
@mixin --raised-animation($duration: 250ms) {
	transition: transform $duration, box-shadow $duration;
	box-shadow: -box-shadow("3dp");

	&:active {
		transform: translateY(0.1rem);
		box-shadow: -box-shadow("1dp");
	}
}

// {*} Ripple
@mixin --ripple-animation($color: -palette("white"), $duration: 500ms) {
	position: relative;
	overflow: hidden;

	&::after {
		@include --position("absolute", $top: 0, $left: 0);
		@include --scaling(100%, 100%);
		content: "";
		background: radial-gradient(circle, $color 10%, transparent 10.01%) no-repeat 50%;
		opacity: 0.5;
		transform: scale(0, 0);
	}

	&:focus:not(:active) {
		&::after {
			opacity: 0;
			transform: scale(10, 10);
			transition: opacity $duration, transform $duration;
		}
	}
}

// ----- Theming ----- //

// {*} hover & active
@mixin --themes-animation($name: "theme", $hover: null, $active: null, $duration: 250ms) {
	@each $key in map-keys($palette__map) {
		#{ if($name,
        -selector(true, "attribute", $name, null, '="#{$key}"'),
        -selector(true, "attribute", null, null, #{$key})) } {
			@if ($hover) {
				&:hover {
					color: -color-contrast(-palette($key));
					fill: -color-contrast(-palette($key));
					background: -palette($key, $shade: "lighten");
					transition: background-color $duration;
				}
			}
			@if ($active) {
				&:active {
					color: -color-contrast(-palette($key));
					fill: -color-contrast(-palette($key));
					background: -palette($key, $shade: "darken");
					transition: background-color $duration;
				}
			}
		}
	}
}

// ----- Typography ----- //

// {*} Underlined
@mixin --underlined-animation($color: -palette("primary"), $duration: 250ms) {
	position: relative;

	&::after {
		@include --position("absolute", $left: 0, $bottom: "-x2");
		@include --scaling(100%, -thick("1z"));
		content: "";
		background: $color;
		opacity: 0;
		transform: scaleX(0);
		transition: opacity $duration, transform $duration;
	}

	&:hover:not(.active) {
		color: $color;
		&::after {
			opacity: 1;
			transform: scaleX(1);
		}
	}

	&.active {
		font-weight: -font-weight("bold");
		color: $color;
		cursor: default;
	}
}

// ----- Section ----- //

// {*} Collapse
@mixin --collapse-animation($duration: 250ms) {
	@keyframes collapse-keyframes {
		from {
			max-height: 0;
			opacity: 0;
		}
		to {
			max-height: 1000rem;
			opacity: 1;
		}
	}

	animation: collapse-keyframes $duration ease-in;
}
