// ----- Media Query ----- //
@mixin --media-query($breakpoint, $helper: "only", $breakpoint2: null) {
	@if ($helper == "only") {
		@media (min-width: -breakpoint($breakpoint, "min")) and (max-width: -breakpoint($breakpoint, "max")) {
			@content;
		}
	} @else if ($helper == "up") {
		@media (min-width: -breakpoint($breakpoint, "min")) {
			@content;
		}
	} @else if ($helper == "down") {
		@media (max-width: -breakpoint($breakpoint, "max")) {
			@content;
		}
	} @else if ($helper == "between" and $breakpoint2 != null) {
		$breakpoint-min: if(-breakpoint($breakpoint, "min") < -breakpoint($breakpoint2, "min"), $breakpoint, $breakpoint2);
		$breakpoint-max: if(-breakpoint($breakpoint, "min") > -breakpoint($breakpoint2, "min"), $breakpoint, $breakpoint2);

		@media (min-width: -breakpoint($breakpoint-min, "min")) and (max-width: -breakpoint($breakpoint-max, "max")) {
			@content;
		}
	}
}

@mixin --media-query-size($size) {
	@if ($size == "small") {
		@include --media-query("mobile", "down") {
			@content;
		}
	} @else if ($size == "medium") {
		@include --media-query("tablet-portrait", "between", "tablet-landscape") {
			@content;
		}
	} @else if ($size == "large") {
		@include --media-query("desktop-small", "up") {
			@content;
		}
	}
}

// ----- Positioning ----- //
@mixin --position($position, $top: null, $bottom: null, $left: null, $right: null) {
	$validator: -in-list-check($position, ("relative", "absolute", "fixed"));
	$top: if(-is-string($top), -spacing($top), $top);
	$bottom: if(-is-string($bottom), -spacing($bottom), $bottom);
	$left: if(-is-string($left), -spacing($left), $left);
	$right: if(-is-string($right), -spacing($right), $right);

	position: #{$position};
	@if ($top) {
		top: $top;
	}
	@if ($bottom) {
		bottom: $bottom;
	}
	@if ($left) {
		left: $left;
	}
	@if ($right) {
		right: $right;
	}
}

// ----- Xen { measurement } ----- //
@mixin --sizing($size: "1x") {
	$size: if(-is-string($size), -font-size($size), $size);

	height: $size;
	width: $size;
}

@mixin --scaling($width: auto, $height: auto) {
	$width: if(-is-string($width), -scaling($width), $width);
	$height: if(-is-string($height), -scaling($height), $height);

	width: $width;
	height: $height;
}

@mixin --spacing($marge, $side, $spacing, $last: true) {
	$validator: -in-list-check($marge, ("margin", "padding"));
	$validator: -in-list-check($side, ("top", "bottom", "right", "left", "vertical", "horizontal"));
	$spacing: if(-is-string($spacing), -spacing($spacing), $spacing);

	&#{if($last, null, ':not(:last-child)')} {
		@if ($side == "vertical") {
			#{$marge}-top: $spacing;
			#{$marge}-bottom: $spacing;
		} @else if ($side == "horizontal") {
			#{$marge}-right: $spacing;
			#{$marge}-left: $spacing;
		} @else {
			#{$marge}-#{$side}: $spacing;
		}
	}
}

@mixin --children-spacing($marge, $side, $spacing, $last: true) {
	> * {
		@include --spacing($marge, $side, $spacing, $last);
	}
}

// ----- Theming ----- //
@mixin --background-theme($color, $outline: false, $shade: null) {
	$color: if(-is-string($color), -palette($color, $shade: $shade), $color);

	@if ($outline) {
		background-color: transparent;
		color: $color;
		fill: $color;
		border: -thick("1z") solid $color;
	} @else {
		background-color: $color;
		color: -color-contrast($color);
		fill: -color-contrast($color);
	}
}

// ----- Assets ----- //

// {*} tooltip
// @mixin hui_tooltip($content: attr(data-tooltip), $direction: top) {
//   position: relative;
//   &:before, &:after {
//     display: none;
//     z-index: 98;
//   }
//   &:hover {
//     &:after { // for text bubble
//       content: $content;
//       display: block;
//       position: absolute;
//       height: 12px; // (makes total height including padding 22px)
//       padding: 6px;
//       font-size: 12px;
//       white-space: nowrap;
//       color: #fff;
//       @include text-shadow(1px 1px #000);
//       background-color: #222;
//     }
//     @if ($direction == 'top'){
//       &:before {
//         @include triangle(down, 6px, #222);
//         top: -6px; margin-top: 0;
//         left: 47%;
//       }
//       &:after {
//         top: -28px;
//         left: 47%; margin-left: -20px;
//       }
//     }
//     @else if ($direction == 'bottom'){
//       &:before {
//         @include triangle(up, 6px, #222);
//         top: auto; margin-top: 0;
//         bottom: -6px;
//         left: 47%;
//       }
//       &:after {
//         bottom: -28px;
//         left: 47%; margin-left: -20px;
//       }
//     }
//   }
// }
