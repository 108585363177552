// {*} Hidden
@include --media-query("mobile") {
	[hiddenOnMobile] {
		display: none;
	}
}
@include --media-query("tablet-portrait") {
	[hiddenOnTabletPortrait] {
		display: none;
	}
}
@include --media-query("tablet-landscape") {
	[hiddenOnTabletLandscape] {
		display: none;
	}
}
@include --media-query("desktop-small") {
	[hiddenOnDesktopSmall] {
		display: none;
	}
}
@include --media-query("desktop-large", "up") {
	[hiddenOnDesktopLarge] {
		display: none;
	}
}

@include --media-query-size("small") {
	[hiddenOnSmallScreens] {
		display: none;
	}
}
@include --media-query-size("medium") {
	[hiddenOnMediumScreens] {
		display: none;
	}
}
@include --media-query-size("large") {
	[hiddenOnLargeScreens] {
		display: none;
	}
}
